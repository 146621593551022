<template>
  <q-dialog v-model="show" persistent class="">
    <div>
      <q-card square bordered class="q-pa-md zb-card-size">
        <q-card-section class="">
          <div class="row">
            <div class="col text-h6">TYPE:</div>
            <div class="col text-h6">
              {{ this.printData.type }}
            </div>
          </div>
          <div class="row">
            <div class="col text-h6">Rev:</div>
            <div class="col text-h6">
              {{ this.printData.rev }}
            </div>
          </div>
          <div class="row">
            <div class="col text-h6">Note:</div>
            <div class="col">
              {{ this.printData.note }}
            </div>
          </div>
          <div class="row">
            <div class="col text-h6">QR:</div>
            <div class="col">
              <img v-bind:src="qr_img" width="50%" />
            </div>
          </div>
          <div class="row">
            <div class="col text-h6">Last log:</div>
            <div class="col">
              {{ this.printData.logData }}
            </div>
          </div>
          <div class="row">
            <div class="col text-h6">ID:</div>
            <div class="col text-h6">
              {{ this.printData.id }}
            </div>
          </div>
          <q-form class="q-gutter-sm">
            <div>
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelPrint"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Print"
                    type="Print"
                    color="primary"
                    @click.prevent="sendToZebra"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>

<script>
import { buildBoardLabel3x2 } from '@/lib/zebra-labels';
import store from '@/store';
export default {
  name: 'ZebraPreview',
  props: {
    printData: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      qr_img: null
    };
  },
  mounted() {
    this.createQR();
  },
  methods: {
    cancelPrint() {
      this.$emit('complete');
    },
    async createQR() {
      const QRCode = await import('qrcode');
      this.qr_img = await QRCode.toDataURL(
        `https://${window.location.host}/boards/${this.printData.id}`
      );
    },
    async sendToZebra() {
      const zebraId = this.$store.state.zebraSelection;
      let zpl = buildBoardLabel3x2(
        this.printData.type,
        this.printData.id,
        this.printData.rev,
        this.printData.note,
        this.printData.logData
      );
      store
        .dispatch('printToZebra', {
          zebraId,
          zpl
        })
        .then((results) => {
          if (results.sent === false || results.reasonCode !== 0) {
            this.$q.notify({
              message: `${results.error}`,
              color: 'red-14',
              textColor: this.$q.dark.isActive ? 'black' : 'white'
            });
          } else {
            this.$q.notify({
              message: `sent to ${zebraId}`,
              color: 'primary',
              textColor: this.$q.dark.isActive ? 'black' : 'white'
            });
            this.$emit('complete');
          }
        });
    }
  },
  watch: {
    printData() {
      this.createQR();
    }
  }
};
</script>

<style scoped>
.spaced {
  margin-left: 2px;
  margin-right: 2px;
}

@media only screen and (min-width: 750px) {
  .zb.card-size {
    min-width: 400px;
  }
}

.max-width {
  max-width: 100px;
}
</style>
