import { render, staticRenderFns } from "./Setup.vue?vue&type=template&id=2ffaae67&scoped=true"
import script from "./Setup.vue?vue&type=script&lang=js"
export * from "./Setup.vue?vue&type=script&lang=js"
import style0 from "./Setup.vue?vue&type=style&index=0&id=2ffaae67&prod&scoped=true&lang=scss"
import style1 from "./Setup.vue?vue&type=style&index=1&id=2ffaae67&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ffaae67",
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab,QBtn,QIcon,QTable,QTd,QPageSticky,QDialog,QCard,QCardSection,QForm,QToolbar,QToolbarTitle,QInput,QToggle,QSelect,QSpace,QPopupProxy,QColor,QSeparator,QMarkupTable});qInstall(component, 'directives', {ClosePopup});
