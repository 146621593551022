import { render, staticRenderFns } from "./OfficeComments.vue?vue&type=template&id=4843fdb0&scoped=true"
import script from "./OfficeComments.vue?vue&type=script&lang=js"
export * from "./OfficeComments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4843fdb0",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QBtn,QCard,QIcon,QSeparator,QChatMessage,QSpace});
