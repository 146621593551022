<template>
  <div>
    <div class="q-pa-md row items-start q-gutter-md">
      <q-card
        v-for="(note, index) in Notes"
        :key="index"
        flat
        bordered
        class="my-card"
      >
        <q-card-section class="hdr-offset-tone">
          {{ dateFormat(note.date) }}
          <q-btn
            class="btn-table-negative float-right"
            icon="delete"
            size="md"
            padding="xs"
            @click="deleteNote(note)"
          >
          </q-btn>
          <q-btn
            class="btn-table-primary float-right"
            icon="edit"
            size="md"
            padding="xs"
            @click="editNote(note)"
          >
          </q-btn>
        </q-card-section>
        <q-separator />
        <q-card-section class="q-pt-none" v-html="note.note" />
      </q-card>
    </div>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addNote" />
    </q-page-sticky>
    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="">
      <q-card square bordered class="q-pa-md card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="note" size="sm" class="q-mr-sm" />
                {{ ModalHeader }}
              </q-toolbar-title>
            </q-toolbar>
            <q-editor
              v-model="editNoteCopy.note"
              min-height="5rem"
              :fonts="{
                arial: 'Arial',
                arial_black: 'Arial Black',
                comic_sans: 'Comic Sans MS',
                courier_new: 'Courier New',
                impact: 'Impact',
                lucida_grande: 'Lucida Grande',
                times_new_roman: 'Times New Roman',
                verdana: 'Verdana'
              }"
              :toolbar="[
                ['left', 'center', 'right', 'justify'],
                [
                  'token',
                  'bold',
                  'italic',
                  'strike',
                  'underline',
                  'subscript',
                  'superscript',
                  'link'
                ],
                ['token', 'fullscreen'],
                [
                  {
                    label: $q.lang.editor.formatting,
                    icon: $q.iconSet.editor.formatting,
                    list: 'no-icons',
                    options: ['p', 'h4', 'h5', 'h6', 'quote', 'code']
                  },
                  {
                    label: $q.lang.editor.defaultFont,
                    icon: $q.iconSet.editor.font,
                    fixedIcon: true,
                    list: 'no-icons',
                    options: [
                      'default_font',
                      'arial',
                      'arial_black',
                      'comic_sans',
                      'courier_new',
                      'impact',
                      'lucida_grande',
                      'times_new_roman',
                      'verdana'
                    ]
                  }
                ]
              ]"
            />
            <q-input
              filled
              v-model="noteDate"
              label="Log Date"
              type="date"
              outlined
              square
            />
            <div>
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import { date } from 'quasar';
import store from '@/store';

export default {
  name: 'Notes',
  data() {
    return {
      showEditModal: false,
      editNoteCopy: {}
    };
  },
  mounted() {},
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  methods: {
    dateFormat(date) {
      return parseTimeStamp(date, 'short');
    },
    decoder(val) {
      return decoder(val);
    },
    onSubmit() {
      let dispatch;
      if (this.editNoteCopy.id === 'new') {
        dispatch = 'createNote';
      } else {
        dispatch = 'updateNote';
      }
      store
        .dispatch(dispatch, {
          farmId: this.farmId,
          note: this.editNoteCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit();
            } else {
              this.showEditModal = false;
            }
          });
        });
    },
    deleteNote(note) {
      this.$deleteResult
        .getResultsAsync(
          'history',
          'Note @ ' + parseTimeStamp(note.date, 'short'),
          'Delete note?'
        )
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(note);
          }
        });
    },
    dispatchDelete(note) {
      store
        .dispatch('deleteNote', {
          farmId: this.farmId,
          note
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(note);
            }
          });
        });
    },
    editNote(note) {
      this.editNoteCopy = JSON.parse(JSON.stringify(note));
      this.showEditModal = true;
    },
    userName(id) {
      if (id === null) {
        return '--';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '- - - -';
    },
    addNote() {
      this.editNoteCopy = {
        id: 'new',
        farm_id: parseInt(this.farmId),
        note: '',
        user_id: this.$store.state.user.user_id,
        date: Date.now() / 1000
      };
      this.showEditModal = true;
    },
    cancelEdit() {
      this.showEditModal = false;
    }
  },
  computed: {
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return list;
    },
    Notes() {
      if (this.$store.state.farm.notes.length === 0) {
        return [];
      }
      const filtered = JSON.parse(JSON.stringify(this.$store.state.farm.notes));
      filtered.sort(function (a, b) {
        return b.date - a.date;
      });
      return filtered;
    },
    ModalHeader() {
      return this.editNoteCopy.id === 'new' ? 'New Note' : 'Edit Note';
    },
    noteDate: {
      get() {
        return date.formatDate(this.editNoteCopy.date * 1000, 'YYYY-MM-DD');
      },
      set(value) {
        const d = new Date(value);
        this.editNoteCopy.date =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 750px) {
  .spaced {
    margin-left: 2px;
    margin-right: 2px;
  }

  .card-size {
    min-width: 500px;
  }

  .card-size {
    width: 700px;
  }

  .my-card {
    width: 100%;
    max-width: 700px;
  }
}

@media only screen and (max-width: 750px) {
  .my-card {
    width: 100%;
    max-width: 500px;
  }
}

.card-size {
  width: 100%;
}
</style>
