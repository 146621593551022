<template>
  <q-layout view="hHh LpR fFf" class="">
    <q-resize-observer @resize="onResize" debounce="100" />

    <q-header
      :class="
        $q.dark.isActive ? 'bg-grey-10 text-white' : 'bg-grey-1 text-black'
      "
    >
      <q-separator class="lt-md" color="black" />
      <q-toolbar
        class="q-py-none q-pl-none q-pr-sm"
        style="background-color: #245ad3"
      >
        <div class="row">
          <div
            v-if="$q.screen.gt.sm"
            class="bg-black"
            style="padding: 6px 8px 2px 9px"
          >
            <img
              src="./assets/AerWorx_logo16_reverse.png"
              alt="logo"
              style="height: 36px"
            />
          </div>
        </div>

        <q-btn
          size="md"
          flat
          round
          color="white"
          icon="menu"
          @click="left = !left"
          class="q-ml-sm"
        />

        <div
          v-if="$q.screen.lt.md"
          class="row items-center rounded-borders bg-white q-ml-sm"
          style="padding: 2px 4px 2px 4px"
        >
          <img
            src="./assets/AerWorx_Favicon_20.png"
            alt="logo"
            style="height: 32px"
          />
        </div>
        <q-space />
        <q-toolbar-title>
          <q-breadcrumbs class="text-white" style="font-size: 15px">
            <q-breadcrumbs-el
              :label="$q.screen.gt.sm ? currentBreadCrumb().text : ''"
              :icon="currentBreadCrumb().icon"
            />
          </q-breadcrumbs>
        </q-toolbar-title>
        <div class="q-pl-sm">
          <q-btn
            v-if="newVersion"
            dense
            stack
            label="Get new version!"
            size="sm"
            style="width: 65px"
            color="white"
            text-color="black"
            @click="pwaReloadApp"
          />
          <q-spinner-ios
            v-if="!loaded"
            color="white"
            size="35px"
            class="q-ml-md"
          />
          <q-btn
            dense
            round
            color="white"
            text-color="black"
            icon="add"
            class="q-ml-sm"
            @click="dialogQuickAdd = true"
          />
          <q-btn
            dense
            round
            color="white"
            text-color="black"
            icon="mail"
            class="q-ml-sm"
            @click="goToEmails"
          />
          <q-btn
            v-if="unreadMsgs > 0"
            dense
            round
            color="white"
            text-color="black"
            icon="chat"
            class="q-ml-sm"
            @click="goToChats"
          >
            <q-badge color="accent" text-color="white" floating rounded>{{
              unreadMsgs
            }}</q-badge>
          </q-btn>
          <q-icon
            v-if="!OnLine"
            color="white"
            name="cloud_off"
            size="30px"
            class="q-ml-sm"
          />
        </div>
      </q-toolbar>
      <router-view name="tabs" />
    </q-header>
    <q-drawer
      show-if-above
      v-model="left"
      side="left"
      :content-class="
        $q.dark.isActive
          ? 'bg-grey-10 text-h6 shadow-2'
          : 'bg-grey-1 text-h6 shadow-2'
      "
    >
      <q-list>
        <router-link
          v-for="(i, index) in userPages.length"
          :key="index"
          tag="li"
          :to="{ name: userPages[index].link }"
          style="list-style: none"
        >
          <q-item clickable tag="a">
            <q-item-section avatar>
              <q-icon :name="userPages[index].icon" />
            </q-item-section>
            <q-item-section>
              <div>
                {{ userPages[index].text }}
                <q-avatar
                  v-if="userPages[index].text === 'Chats' && starredMsgs > 0"
                  color="orange"
                  text-color="black"
                  icon="star"
                  font-size="20px"
                  size="32px"
                  class="q-ml-md"
                >
                  <q-badge color="blue-14" floating rounded>{{
                    starredMsgs
                  }}</q-badge>
                </q-avatar>
              </div>
            </q-item-section>
          </q-item>
        </router-link>
        <!--Recent Farms-->
        <template>
          <q-separator inset class="q-my-sm bg-grey-7" />
          <router-link
            v-for="(farm, index) in lastFarmList"
            :key="'_' + index"
            tag="li"
            :to="farm.link"
            style="list-style: none"
          >
            <q-item dense clickable tag="a" @click="goToRecentFarm(farm)">
              <q-item-section avatar>
                <q-icon name="last_page" />
              </q-item-section>
              <q-item-section dense>
                <q-item-label style="font-size: 0.8rem">{{
                  farm.text
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </router-link>
        </template>
      </q-list>
      <!-- Manager only items -->
      <template v-if="!!user && isSuperAdmin()">
        <q-separator inset class="q-my-sm bg-grey-7" />
        <q-item-label header class="text-grey-5">Manager</q-item-label>
        <q-list dense>
          <router-link
            v-for="(i, index) in managerPages.length"
            :key="index"
            tag="li"
            :to="{ name: managerPages[index].link }"
            style="list-style: none"
          >
            <q-item dense clickable tag="a">
              <q-item-section avatar>
                <q-icon :name="managerPages[index].icon" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ managerPages[index].text }}</q-item-label>
              </q-item-section>
            </q-item>
          </router-link>
        </q-list>
      </template>
      <q-separator inset class="q-my-sm bg-grey-7" />
      <!-- user -->
      <div class="text-subtitle2 text-grey-5 q-pl-md">User account</div>
      <q-item class="row items-center text-grey-5">
        <q-item-section avatar>
          <q-icon name="account_circle" />
        </q-item-section>
        <div class="row items-center full-width">
          <div class="text-subtitle2">
            {{ !!user ? user.display_name : '' }}
          </div>
          <q-space />
          <q-btn
            outline
            size="sm"
            label="Manage"
            color="blue-14"
            padding="xs"
            :href="ACCOUNTS_LOGIN"
          />
        </div>
      </q-item>

      <q-separator inset class="q-my-sm bg-grey-7" />
      <q-toggle dense v-model="dark" class="q-ml-md">Dark mode</q-toggle>

      <q-separator inset class="q-my-sm bg-grey-7" />
      <div class="q-px-md">
        <div class="row items-center text-subtitle2 q-pb-sm">
          Version {{ version }}
          <q-space />
          <q-btn
            outline
            label="Reload App"
            size="sm"
            color="blue-14"
            no-caps
            @click="pwaReloadApp"
          />
        </div>
        <div
          v-if="consoleErrorBuff.length > 0"
          class="row items-center text-subtitle2 q-pb-sm"
        >
          <q-space />
          <q-btn
            outline
            label="Debug Data"
            size="sm"
            color="blue-14"
            no-caps
            @click="showConsoleData"
          />
        </div>
      </div>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>

    <finish-result />
    <delete-confirm />

    <q-dialog persistent position="top" full-height v-model="dialogQuickAdd">
      <QuickAdd @farmSelected="dialogQuickAdd = false" />
    </q-dialog>

    <q-dialog persistent v-model="showDebugData">
      <q-card style="width: 600px">
        <q-card-section>
          <div class="text-h6">Debug console</div>
        </q-card-section>
        <q-card-section>
          <q-input
            v-for="(value, i) in consoleErrors"
            :key="i"
            v-model="consoleErrors[i]"
            filled
            autogrow
          >
          </q-input>
        </q-card-section>
        <div class="row items-center q-py-md q-px-lg">
          <q-btn
            label="Close"
            color="primary"
            class="col q-mr-xs"
            @click="hideConsoleData()"
          />
        </div>
      </q-card>
    </q-dialog>

    <ReminderDialog
      v-if="reminderDialog"
      :remindNowObj="remindNowObj"
      @reminderUpdated="reminderDialog = false"
    />

    <ReminderPin
      v-for="(reminder, index) in reminderPins"
      :key="reminder.id"
      :reminder="reminder"
      :index="index"
      @reminderManage="reminderManage"
    />

    <q-dialog v-model="dialogScreenshot" position="top" persistent>
      <q-card style="width: 400px">
        <div class="text-center text-h6 text-primary q-py-sm">Screenshot</div>
        <q-separator />

        <q-form @submit="selectFarm">
          <div class="q-pa-md">
            <!-- prevent form submit if no farm selected -->
            <q-select
              v-model="selectedFarm"
              :options="FarmOptions"
              label="Select Farm"
              :rules="[(val) => !!val || 'Farm is required']"
              hide-bottom-space
              outlined
            />
          </div>
          <q-separator />

          <div class="row justify-end q-pa-md">
            <q-btn
              label="Cancel"
              color="primary"
              class="q-mr-sm"
              @click="resetScreenshot"
            />
            <q-btn label="Select" color="primary" type="submit" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <IOSpwaDialog v-if="dialogIOSpwa" @IOSpwaConfirm="IOSpwaConfirm" />
  </q-layout>
</template>

<script>
import store from '@/store';
import { colors, date } from 'quasar';
import { listReminders, cloneObj } from '@/lib/helpers';
import { addScreenshotListener } from '@/lib/screenshot';
import ReminderDialog from '@/components/ReminderDialog.vue';
import ReminderPin from '@/components/ReminderPin.vue';
import QuickAdd from '@/components/QuickAdd.vue';
import IOSpwaDialog from '@/components/IOSpwaConfirm.vue';
import { colorPallete } from '@/styles/colors.js';

export default {
  components: {
    ReminderDialog,
    ReminderPin,
    QuickAdd,
    IOSpwaDialog
  },
  data() {
    return {
      dialogQuickAdd: false,
      dialogScreenshot: false,
      showDebugData: false,
      consoleErrorBuff: [],
      version: process.env.VUE_APP_VERSION,
      ACCOUNTS_LOGIN: process.env.VUE_APP_ACCOUNTS_LOGIN,
      initialized: false,
      screenshot: { file: null, farmId: null },
      left: false,
      expanded: false,
      managerPages: [
        {
          icon: 'mail',
          text: 'Emails',
          link: 'Emails'
        },
        {
          icon: 'build',
          text: 'Parts',
          link: 'Parts'
        },
        {
          icon: 'query_builder',
          text: 'Recent work',
          link: 'Recent'
        },
        {
          icon: 'handyman',
          text: 'Boards',
          link: 'Boards'
        },
        {
          icon: 'sms',
          text: 'Twilio SMS',
          link: 'SmsRecords'
        },
        {
          icon: 'settings',
          text: 'Setup',
          link: 'Setup'
        },
        {
          icon: 'supervised_user_circle',
          text: 'Users',
          link: 'Users'
        }
      ],
      userPages: [
        {
          icon: 'home_work',
          text: 'Farms',
          link: 'Farms'
        },
        {
          icon: 'assignment',
          text: 'Work Orders',
          link: 'WorkOrders'
        },
        {
          icon: 'task',
          text: 'To-Do',
          link: 'Todos'
        },
        {
          icon: 'map',
          text: 'Map',
          link: 'Map'
        },
        {
          icon: 'chat',
          text: 'Chats',
          link: 'Chats'
        },
        {
          icon: 'sell',
          text: 'Tags',
          link: 'Tags'
        }
      ],
      tabs: this.presentRoute(),
      remindNowObj: {},
      reminderDebounce: null,
      reminderDialog: false,
      reminderTimeout: null,
      reminderPins: [],
      dialogIOSpwa: false,
      deferredInstall: null,
      refreshing: false,
      registration: null,
      selectedFarm: null
    };
  },
  mounted() {
    this.dark = store.state.darkMode;
    this.pwaControl();
    this.reminderSnoozeControl();

    addScreenshotListener(this.screenshot);

    /* Very basic console redirection so we can see errors in iOS and mobile */
    try {
      const oldErr = console.error;
      const that = this;
      console.error = function (error) {
        if (error.stack) {
          that.consoleErrorBuff.push(error.message, error.stack);
        } else {
          that.consoleErrorBuff.push(error);
        }
        oldErr.apply(console, arguments);
      };
    } catch (e) {
      console.log(e);
    }
    /* End console redirection */
  },
  computed: {
    FarmOptions() {
      const farms = [];
      for (const farm of store.state.farmList) {
        farms.push({
          label: farm.farm_name,
          value: farm.id
        });
      }

      return farms;
    },
    loaded() {
      return this.$store.state.workLogs.length > 0;
    },
    dark: {
      get() {
        return this.$q.dark.isActive;
      },
      set(value) {
        this.$q.dark.set(value);

        if (value) {
          colors.setBrand('primary', colorPallete.primaryDark);
          // colors.setBrand('accent', colorPallete.accentDark);
        } else {
          colors.setBrand('primary', colorPallete.primary);
          colors.setBrand('accent', colorPallete.accent);
        }

        store.dispatch('setDarkMode', value);
      }
    },
    user() {
      return this.$store.state.user;
      // return true;
    },
    OnLine() {
      return this.$store.state.OnLine;
    },
    editMode() {
      return false; // this.$store.state.editMode;
    },
    newVersion() {
      return this.$store.getters.newVersionAvailable;
    },
    lastFarmList() {
      const arr = [];
      for (let i = 0; i < this.$store.state.lastFarmList.length; i++) {
        const f = this.$store.state.lastFarmList[i];
        const farm = this.$store.state.farmList.find(
          (element) => element.id === f.id
        );
        if (farm) {
          arr.push({
            text: this.decoder(farm.farm_name),
            link: `/farm/${farm.id}/work/`,
            farmId: farm.id
          });
        }
      }
      arr.sort(function (a, b) {
        return a.text.localeCompare(b.text, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return arr;
    },
    unreadMsgs() {
      return (
        this.$store.state.unreadWorkLogMsgs.numUnread +
        this.$store.state.unreadTodoMsgs.numUnread
      );
    },
    starredMsgs() {
      return (
        this.$store.state.unreadWorkLogMsgs.numStarred +
        this.$store.state.unreadTodoMsgs.numStarred
      );
    },
    isPWA() {
      return (
        window.matchMedia('(display-mode: standalone)').matches ||
        window.navigator.standalone ||
        false
      );
    },
    isIOS() {
      return (
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      );
    },
    consoleErrors() {
      return this.consoleErrorBuff;
    }
  },
  methods: {
    selectFarm() {
      this.screenshot.farmId = this.selectedFarm.value;
      this.dialogScreenshot = false;

      this.$router.push(`/farm/${this.selectedFarm.value}/work/`);
    },
    onResize() {
      const height = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      store.dispatch('setPageHeight', height);
    },
    showConsoleData() {
      this.showDebugData = true;
    },
    hideConsoleData() {
      this.showDebugData = false;
    },
    pwaReloadApp() {
      console.log('%creload app', 'color: orange');
      if (this.registration && this.registration.waiting) {
        // Send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        return;
      }

      location.reload();
    },
    pwaControl() {
      const pwaLastAskTS = localStorage.getItem('pwaLastAskTS');

      const now = +new Date();
      const oneWeekAgo = now - 604800000;

      if (this.isIOS) {
        if (!this.isPWA && (!pwaLastAskTS || pwaLastAskTS < oneWeekAgo)) {
          this.dialogIOSpwa = true;
        }
      }

      if (!this.isIOS) {
        window.addEventListener('beforeinstallprompt', (event) => {
          event.preventDefault();
          this.deferredInstall = event;

          const now = +new Date();
          const onceWeekAgo = now - 604800000;

          if (!this.isPWA && pwaLastAskTS < oneWeekAgo) {
            this.$q
              .dialog({
                title: 'Install App?',
                message: 'App runs smoother if installed!',
                ok: {
                  icon: 'install_desktop',
                  flat: true,
                  color: 'primary',
                  label: 'Begin Install'
                },
                cancel: {
                  flat: true,
                  label: 'Cancel',
                  color: 'primary'
                },
                focus: 'ok',
                persistent: true
              })
              .onOk(() => {
                this.deferredInstall.prompt();
              })
              .onCancel(() => {
                localStorage.setItem('pwaLastAskTS', +new Date());
              });
          }
        });
      }

      window.addEventListener('appinstalled', () => {
        // Set date back so if user deletes app
        // it will immediately give install prompt again
        const now = +new Date();
        const dateTrigger = now - 704800000;
        localStorage.setItem('pwaLastAskTS', dateTrigger);
        this.deferredInstall = null;
      });

      document.addEventListener('swUpdated', this.displayUpdateNotice, {
        once: true
      });

      if (!navigator.serviceWorker) {
        return;
      }

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // We'll also need to add 'refreshing' to our data originally set to false.
        console.log('%ccontroller change', 'color: red');
        if (this.refreshing) return;
        this.refreshing = true;
        // Here the actual reload of the page occurs
        window.location.reload();
      });
    },
    IOSpwaConfirm() {
      localStorage.setItem('pwaLastAskTS', +new Date());
    },
    displayUpdateNotice(event) {
      console.log('%cupdateAvailable', 'color: red');
      this.registration = event.detail;

      if (!(this.registration?.waiting ?? false)) {
        return;
      }

      const pwaLastUpdateTS = localStorage.getItem('pwaLastUpdateTS');

      const twelveHrAgo = +new Date() - 1000 * 60 * 60 * 12;

      // Safari will send false positives
      if (pwaLastUpdateTS && parseInt(pwaLastUpdateTS) > twelveHrAgo) {
        return;
      }

      localStorage.setItem('pwaLastUpdateTS', +new Date());

      this.$q
        .dialog({
          title: 'App Update',
          ok: {
            label: 'Reload',
            color: 'primary'
          },
          cancel: {
            flat: true,
            label: 'Dismiss',
            color: 'primary'
          },
          focus: 'none',
          message: `Updated version available! App needs to reload for update.`,
          persistent: true
        })
        .onOk(async () => {
          if (!this.registration || !this.registration.waiting) return;
          // Send message to SW to skip the waiting and activate the new SW
          this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        });
    },
    reminderCheck() {
      // Make sure user is online before showing modal
      if (!this.OnLine) {
        setTimeout(() => {
          this.reminderCheck();
        }, 500);
        return;
      }

      let storedSnooze = false;
      const hourAgo = +new Date() - 1000 * 60 * 60;
      const snoozedTS = parseInt(
        localStorage.getItem('snoozeAllTS') || hourAgo
      );

      if (snoozedTS > hourAgo) {
        storedSnooze = true;
      }

      // snooze stored in state or localstorage
      const snoozeAll = this.$store.state.snoozeAllReminders || storedSnooze;

      const todos = snoozeAll ? [] : this.$store.state.todos;
      const workLogs = snoozeAll ? [] : this.$store.state.workLogs;

      if (this.$store.state.snoozeAllReminders) {
        this.reminderDialog = false;
        return;
      }

      const reminders = listReminders(todos, workLogs, this.user.user_id);
      this.reminderPins.splice(0);
      this.reminderPins = reminders.pinned;

      if (reminders.upcoming.length) {
        const soonest = Math.min(...reminders.upcomingTimestamps);
        const diff = soonest - +new Date();

        if (this.reminderTimeout) {
          clearTimeout(this.reminderTimeout);
        }

        this.reminderTimeout = setTimeout(() => {
          this.reminderCheck();
        }, diff);
      }

      if (reminders.remindNow.length) {
        this.remindNowObj = reminders.remindNow[0];
        this.reminderDialog = true;
      }
    },
    reminderCheckDebounce() {
      if (this.reminderDebounce) {
        clearTimeout(this.reminderDebounce);
      }

      this.reminderDebounce = setTimeout(() => {
        if (this.dialogQuickAdd || !store.state.quickAddComplete) {
          this.reminderCheckDebounce();
          return;
        }

        this.reminderCheck();
      }, 1500);
    },
    reminderManage(reminder) {
      this.remindNowObj = reminder;
      this.reminderDialog = true;
    },
    reminderSnoozeControl() {
      document.addEventListener('visibilitychange', () => {
        if (
          document.visibilityState === 'visible' &&
          store.state.snoozeAllReminders
        ) {
          const snoozedTS = parseInt(
            localStorage.getItem('snoozeAllTS') || +new Date()
          );

          const hourAgo = +new Date() - 1000 * 60 * 60;

          if (snoozedTS < hourAgo) {
            store.dispatch('snoozeAllReminders', false);

            this.reminderCheckDebounce();
          }
        }
      });
    },
    goToChats() {
      if (this.$router.currentRoute.path.substr(0, 6) !== '/chats') {
        this.$router.push('/chats/');
      }
    },
    goToRecentFarm(farm) {
      const currFarmId = this.$store.state.farm.id;

      if (currFarmId > 0) {
        store.dispatch('unSubscribeFarm', currFarmId).then((results) => {
          if (results === false) {
            this.timer = setTimeout(this.subscribe, 250);
          } else {
            this.timer = null;
          }
        });
      }

      store.dispatch('subscribeFarm', farm.farmId).then((results) => {
        if (results === false) {
          this.timer = setTimeout(this.subscribe, 250);
        } else {
          this.timer = null;
        }
      });
    },
    goToEmails() {
      if (this.$router.currentRoute.path.substr(0, 7) !== '/emails') {
        this.$router.push('/emails/');
      }
    },
    isSuperAdmin() {
      return (
        !!this.$store.state.user &&
        this.$store.state.user.aerworx_level === 'super-admin'
      );
    },
    decoder(str) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = str;
      return textArea.value;
    },
    logout() {
      this.$store.dispatch('logout').then();
    },
    currentBreadCrumb() {
      for (let i = 0; i < this.userPages.length; i++) {
        if (this.userPages[i].link === this.$router.currentRoute.name) {
          return {
            icon: this.userPages[i].icon,
            text: this.userPages[i].text
          };
        }
      }
      for (let i = 0; i < this.managerPages.length; i++) {
        if (this.managerPages[i].link === this.$router.currentRoute.name) {
          return {
            icon: this.managerPages[i].icon,
            text: this.managerPages[i].text
          };
        }
      }
      if (this.$router.currentRoute.path.substring(0, 6) === '/farm/') {
        const spl = this.$router.currentRoute.path.split('/');
        const farm = this.$store.state.farmList.find(
          (farm) => farm.id === parseInt(spl[2])
        );
        return farm
          ? {
              icon: 'home_work',
              text: this.decoder(farm.farm_name)
            }
          : '';
      }
      if (this.$router.currentRoute.path.substring(0, 7) === '/users/') {
        const spl = this.$router.currentRoute.path.split('/');
        const user = this.$store.state.userList.find(
          (user) => user.user_id === parseInt(spl[2])
        );
        return user
          ? {
              icon: 'supervised_user_circle',
              text: this.decoder(user.display_name) + ' farm list'
            }
          : '';
      }
      if (this.$router.currentRoute.path.substring(0, 8) === '/boards/') {
        const spl = this.$router.currentRoute.path.split('/');
        const board = this.$store.state.boards.find(
          (board) => board.id === parseInt(spl[2])
        );
        return board
          ? {
              icon: 'handyman',
              text: `${this.decoder(board.type)} - ${board.id} ${
                board.note
              } (Rev ${board.rev})`
            }
          : '';
      }
      return {
        icon: 'error',
        text: this.$router.currentRoute.name
      };
    },
    presentRoute() {
      if (this.$router.currentRoute.meta.tabs) {
        return this.$router.currentRoute.meta.tabs;
      } else {
        return [];
      }
    },
    resetScreenshot() {
      this.screenshot.file = null;
      this.screenshot.farmId = null;
      this.selectedFarm = null;
      this.dialogScreenshot = false;

      addScreenshotListener(this.screenshot);
    }
  },
  watch: {
    '$store.state.OnLine'() {
      if (this.$store.state.OnLine && !this.initialized) {
        this.$store
          .dispatch('subscribeInitial', this.$store.state.user.aerworx_level)
          .then((valid) => {
            this.initialized = valid;
          });
      }
    },
    '$store.state.todos': {
      deep: true,
      handler(newTodos) {
        this.reminderCheckDebounce();
      }
    },
    '$store.state.workLogs': {
      deep: true,
      handler(newLogs) {
        this.reminderCheckDebounce();
      }
    },
    '$store.state.snoozeAllReminders'() {
      this.reminderCheckDebounce();
    },
    '$store.state.screenshot'(newVal, oldVal) {
      if (!newVal) {
        this.resetScreenshot();
      }
    },
    screenshot: {
      async handler() {
        if (this.screenshot.file && !this.screenshot.farmId) {
          store.dispatch('setScreenshot', this.screenshot);

          const currentUrl = this.$router.currentRoute.path;
          const isFarmWork =
            currentUrl.includes('/farm/') && currentUrl.includes('/work/');
          const screenshotWorkLogId = this.$store.state.screenshotWorkLogId;

          if (!isFarmWork && !screenshotWorkLogId) {
            this.dialogScreenshot = true;
          }
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    if (this.reminderTimeout) {
      clearTimeout(this.reminderTimeout);
    }
  }
};
</script>

<style scoped>
/* li.router-link-exact-active { */
/*   background-color: #9e9e9e; */
/* } */
.body--dark li.router-link-exact-active {
  background-color: #424242;
}

.body--light li.router-link-exact-active {
  background-color: #bdbdbd;
}
</style>
