<template>
  <div>
    <div v-if="parentObj && !tooltip">
      <q-input
        outlined
        autogrow
        v-model="newComment"
        label="Office Comment"
        @input="setEmptyErr"
      >
        <template v-slot:append>
          <q-btn @click="addComment" round flat icon="send" />
        </template>
      </q-input>
      <div v-if="emptyCommentErr" class="text-caption text-negative q-pl-sm">
        Comment is empty
      </div>
    </div>

    <div class="q-pt-sm">
      <template v-if="CommentsBuilder.length > 0">
        <q-card flat bordered class="q-py-sm q-px-md q-my-md">
          <div class="col text-center text-primary text-subtitle1 q-mb-xs">
            <q-icon
              name="speaker_notes"
              color="primary"
              size="25px"
              class="q-mr-sm"
            />
            Office Comments
          </div>
          <q-separator class="q-mb-md" />
          <q-chat-message
            v-for="(msgObj, j) in CommentsBuilder"
            :key="j"
            :name="userName(msgObj.sentBy)"
            :sent="msgObj.isSelf"
            :bg-color="msgObj.isSelf ? 'deep-purple-1' : 'grey-3'"
          >
            <div class="row">
              <div class="row col-12 q-pb-xs">
                <div
                  v-html="createHyperLink(msgObj.msg)"
                  style="width: calc(100% - 20px); white-space: pre-wrap"
                ></div>
                <div style="width: 20px">
                  <div class="row no-wrap">
                    <q-space />
                    <q-btn
                      v-if="msgObj.isSelf || isSuperAdmin"
                      flat
                      icon="clear"
                      color="negative"
                      size="10px"
                      style="float: right"
                      padding="0px"
                      @click="deleteComment(msgObj)"
                    />
                  </div>
                </div>
              </div>
              <div class="row col-12 text-caption text-grey-8">
                {{ parseCommentTime(msgObj.time) }}
              </div>
            </div>
          </q-chat-message>
        </q-card>
      </template>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { v4 } from 'uuid';
import { cloneObj, createHyperLink } from '@/lib/helpers';

export default {
  name: 'OfficeComments',
  props: {
    parentObj: {
      type: Object,
      required: true
    },
    tooltip: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      createHyperLink,
      emptyCommentErr: false,
      newComment: ''
    };
  },
  methods: {
    addComment() {
      if (!this.newComment.length) {
        this.emptyCommentErr = true;
        return;
      }
      this.emptyCommentErr = false;

      const parentObj = JSON.parse(JSON.stringify(this.parentObj));
      parentObj.newComment = {
        id: v4(),
        user: this.$store.state.user.user_id,
        comment: this.newComment,
        time: Date.now()
      };

      this.commentDispatch(parentObj);

      this.newComment = '';
    },
    commentDispatch(parentObj) {
      store
        .dispatch('officeCommentWorkLog', {
          farmId: parentObj.farm_id,
          log: parentObj
        })
        .then((results) => {
          results.disableCheck = true;
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.commentDispatch(parentObj);
            }
          });
        });
    },
    deleteComment(msgObj) {
      let message = 'Delete Comment?';
      if (!msgObj.isSelf) {
        message = 'Delete Comment that was sent by another user?';
      }

      this.$q
        .dialog({
          title: 'Confirm',
          message,
          cancel: {
            flat: true,
            color: 'primary',
            label: 'Cancel'
          },
          ok: {
            color: 'accent',
            icon: 'delete',
            label: 'Delete'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteCommentConfirmed(msgObj.index);
        });
    },
    deleteCommentConfirmed(index) {
      const parentObj = cloneObj(this.parentObj);
      parentObj.deleteComment = {
        id: parentObj.office_comments[index].id
      };
      this.commentDispatch(parentObj);

      this.$q.notify({
        message: 'Comment Deleted',
        color: 'primary'
      });
    },
    parseCommentTime(value) {
      if (value && !isNaN(value)) {
        return new Date(value).toLocaleString('en-US', {
          timeStyle: 'short',
          dateStyle: 'short'
        });
      } else {
        return '--';
      }
    },
    setEmptyErr() {
      this.emptyCommentErr = !this.newComment.length;
    },
    userName(id) {
      if (id === null) {
        return '--';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '- - - -';
    }
  },
  computed: {
    CommentsBuilder() {
      const comments = [];

      for (let i = 0; i < this.parentObj.office_comments.length; i++) {
        const comment = this.parentObj.office_comments[i];

        comments.push({
          sentBy: comment.user,
          msg: comment.comment,
          time: comment.time,
          id: comment.id,
          isSelf: comment.user === this.$store.state.user.user_id,
          index: i
        });
      }
      return comments;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    }
  },
  watch: {}
};
</script>

<style scoped></style>
