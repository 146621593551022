<template>
  <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
    <q-table
      title="Ponds"
      :data="Ponds"
      :columns="columns"
      :pagination.sync="pagination"
      row-key="name"
    >
      <template v-slot:body-cell-Gps="props">
        <q-td :props="props">
          <q-icon
            v-if="validateGps(props.row.gps)"
            class="check-color"
            name="done"
            size="sm"
          ></q-icon>
        </q-td>
      </template>
      <template v-slot:body-cell-HB20="props">
        <q-td :props="props">
          <q-icon
            v-if="props.row.hb20"
            class="check-color"
            name="done"
            size="sm"
          >
          </q-icon>
        </q-td>
      </template>
      <template v-slot:body-cell-Moved="props">
        <q-td :props="props">
          <q-icon v-if="props.row.moved" color="red-14" name="done" size="sm">
            <q-tooltip>
              Moved From: {{ movedInfo(props.row.moved).farmName }}
              <br />
              Moved On: {{ movedInfo(props.row.moved).date }}
            </q-tooltip>
          </q-icon>
        </q-td>
      </template>
      <template v-slot:body-cell-Edit="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            icon="edit"
            size="md"
            padding="xs"
            @click="editPond(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-Delete="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-negative"
            outline
            icon="delete"
            size="md"
            padding="xs"
            @click="deletePond(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addPond" />
    </q-page-sticky>
    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="">
      <q-card square bordered class="q-pa-lg card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="waves" size="sm" class="q-mr-sm" />
                {{ ModalHeader }}
              </q-toolbar-title>
            </q-toolbar>
            <q-input
              filled
              v-model="editPondCopy.name"
              label="Name"
              maxlength="64"
              outlined
              square
            />
            <q-input
              filled
              v-model="installDate"
              type="date"
              label="Install date"
              maxlength="64"
              outlined
              square
            />
            <q-input
              filled
              v-model="mfgDate"
              type="date"
              label="Manufacture date"
              maxlength="64"
              outlined
              square
            />
            <q-input
              filled
              v-model="editPondCopy.gps"
              label="Location"
              maxlength="64"
              outlined
              square
            >
              <q-btn v-if="gpsAvailable" color="primary" @click="fillGps"
                >Use position</q-btn
              >
              <q-btn v-if="!gpsAvailable" disabled color="negative"
                >Not available</q-btn
              >
            </q-input>
            <q-toggle
              color="primary"
              v-model="editPondCopy.hb20"
              label="Harvest buoy"
            ></q-toggle>
            <q-input
              filled
              v-model="editPondCopy.radio_channel"
              label="Hopping channel"
              type="number"
              min="0"
              max="9"
              outlined
              square
            />
            <q-input
              filled
              v-model="editPondCopy.radio_id"
              label="Radio ID"
              maxlength="3"
              outlined
              square
              mask="XXX"
            />
            <div>
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
  </div>
</template>

<script>
import { parseTimeStamp } from '@/lib/date-utils';
import { decoder, SplitGps } from '@/lib/helpers';
import { date } from 'quasar';
import store from '@/store';

export default {
  name: 'Ponds',
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      gpsAvailable: !!navigator.geolocation,
      showEditModal: false,
      editPondCopy: {},
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          sortable: true,
          name: 'Name',
          label: 'Name',
          align: 'left',
          classes: 'table-highlight-column',
          field: (row) => decoder(row.name)
        },
        {
          sortable: true,
          name: 'Installed',
          label: 'Installed',
          align: 'left',
          field: (row) => parseTimeStamp(row.install_date, 'short')
        },
        {
          sortable: true,
          name: 'radio_channel',
          label: 'XT CH',
          align: 'center',
          field: (row) => row.radio_channel
        },
        {
          sortable: true,
          name: 'radio_id',
          label: 'XT ID',
          align: 'center',
          field: (row) => row.radio_id
        },
        {
          sortable: true,
          name: 'Gps',
          label: 'Gps',
          align: 'center'
        },
        {
          sortable: true,
          name: 'HB20',
          label: 'HB20',
          align: 'center'
        },
        {
          sortable: true,
          name: 'Moved',
          label: 'Moved',
          align: 'center',
          field: (row) => row.moved_from,
          sort: (a, b, rowA, rowB) => (a === null) - (b === null)
        },
        {
          name: 'Edit',
          label: '',
          align: 'center',
          classes: 'max-width'
        },
        {
          name: 'Delete',
          label: '',
          align: 'center',
          classes: 'max-width'
        }
      ]
    };
  },
  methods: {
    onSubmit() {
      let dispatch;
      if (this.editPondCopy.id === 'new') {
        dispatch = 'createPond';
      } else {
        dispatch = 'updatePond';
      }

      this.editPondCopy.name = this.editPondCopy.name.trim();

      store
        .dispatch(dispatch, {
          farmId: this.farmId,
          pond: this.editPondCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit();
            } else {
              this.showEditModal = false;
            }
          });
        });
    },
    farmName(id) {
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(id)
      );
      return farm ? farm.farm_name : '';
    },
    movedInfo(moveObj) {
      return {
        farmName: this.farmName(moveObj.farmFrom),
        date: new Date(moveObj.date).toLocaleDateString()
      };
    },
    fillGps() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.editPondCopy.gps =
              position.coords.latitude + ',' + position.coords.longitude;
          },
          () => {
            this.gpsAvailable = false;
          }
        );
      } else {
        this.gpsAvailable = false;
      }
    },
    editPond(pond) {
      this.editPondCopy = JSON.parse(JSON.stringify(pond));
      this.showEditModal = true;
    },
    deletePond(pond) {
      this.$deleteResult
        .getResultsAsync('waves', pond.name, 'Delete pond?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(pond);
          }
        });
    },
    dispatchDelete(pond) {
      store
        .dispatch('deletePond', {
          farmId: this.farmId,
          pond
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(pond);
            }
          });
        });
    },
    addPond(pond) {
      this.editPondCopy = {
        farm_id: this.farmId,
        gps: '',
        id: 'new',
        install_date: Date.now() / 1000,
        mfg_date: Date.now() / 1000,
        name: '',
        hb20: false,
        radio_channel: 0,
        radio_id: ''
      };
      this.showEditModal = true;
    },
    cancelEdit() {
      this.showEditModal = false;
    },
    validateGps(value) {
      return SplitGps(value) !== null;
    }
  },
  computed: {
    Ponds() {
      const ponds = this.$store.state.farm.ponds.slice();
      ponds.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        const aIsHB = aName.includes('hb') || aName.includes('harvest');
        const bIsHB = bName.includes('hb') || bName.includes('harvest');

        if (aIsHB && !bIsHB) {
          return 1;
        }

        if (!aIsHB && bIsHB) {
          return -1;
        }

        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      return ponds;
    },
    ModalHeader() {
      return this.editPondCopy.id === 'new' ? 'New Pond' : 'Edit Pond';
    },
    installDate: {
      get() {
        return date.formatDate(
          new Date(this.editPondCopy.install_date * 1000),
          'YYYY-MM-DD'
        );
      },
      set(value) {
        const d = new Date(value);
        this.editPondCopy.install_date =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    },
    mfgDate: {
      get() {
        return date.formatDate(this.editPondCopy.mfg_date * 1000, 'YYYY-MM-DD');
      },
      set(value) {
        const d = new Date(value);
        this.editPondCopy.mfg_date =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 750px) {
  .spaced {
    margin-left: 2px;
    margin-right: 2px;
  }

  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }
}

.max-width {
  max-width: 100px;
}
</style>
