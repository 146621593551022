<template>
  <div>
    <div v-if="selectedLog">
      <div class="text-center q-py-sm">
        <q-btn
          rounded
          label="New Chat"
          icon="add"
          size="md"
          color="white"
          text-color="primary"
          padding="3px 50px"
          @click="createNewChat"
        />
      </div>
    </div>

    <div>
      <template v-if="chats.length > 0">
        <div
          v-for="(chat, i) in chats"
          :key="i + chat.chatIdsStr"
          class="q-py-md"
        >
          <q-card
            v-if="chat.isPartOfChat || isSuperAdmin"
            class="q-py-sm q-px-md"
          >
            <div v-if="!chat.messages.length">
              <q-btn
                flat
                icon="clear"
                size="xs"
                padding="none"
                @click="deleteEmptyChat(chat)"
              />
            </div>
            <div
              class="row justify-center text-subtitle1"
              :class="$q.dark.isActive ? 'text-white' : 'text-black'"
            >
              {{ chat.groupStr }}
            </div>
            <q-chat-message
              v-for="(msgObj, j) in chat.messages"
              :key="j + i"
              :name="userName(msgObj.sentBy)"
              :sent="msgObj.isSelf"
              :bg-color="
                msgObj.starred
                  ? 'yellow-4'
                  : msgObj.isSelf
                  ? 'deep-purple-1'
                  : 'grey-3'
              "
            >
              <div class="row">
                <div class="row col-12 q-pb-xs">
                  <div
                    v-html="createHyperLink(msgObj.msg)"
                    style="width: calc(100% - 40px); white-space: pre-wrap"
                  ></div>
                  <div style="width: 40px">
                    <q-space />
                    <div class="row no-wrap">
                      <q-space />
                      <q-btn
                        v-if="
                          !msgObj.isSelf && msgObj.starred && chat.isPartOfChat
                        "
                        round
                        flat
                        icon="star"
                        color="accent"
                        size="12px"
                        style="float: right"
                        padding="0px"
                        @click="starMsg(false, msgObj.originalIndex)"
                      />
                      <q-btn
                        v-if="
                          !msgObj.isSelf && !msgObj.starred && chat.isPartOfChat
                        "
                        round
                        flat
                        icon="star"
                        color="grey-5"
                        size="12px"
                        style="float: right"
                        padding="0px"
                        @click="starMsg(true, msgObj.originalIndex)"
                      />
                      <q-btn
                        v-if="msgObj.isSelf"
                        unelevated
                        color="deep-purple-2"
                        text-color="deep-purple"
                        icon="expand_more"
                        padding="0px"
                      >
                        <q-menu anchor="top left" self="top right">
                          <q-list style="min-width: 100px">
                            <q-item
                              clickable
                              v-close-popup
                              @click="editMsg(msgObj.originalIndex)"
                            >
                              <div class="row items-center">
                                <q-icon
                                  name="edit"
                                  size="20px"
                                  color="deep-purple"
                                />
                                <div class="q-pl-sm">Edit</div>
                              </div>
                            </q-item>
                            <q-separator />
                            <q-item
                              clickable
                              v-close-popup
                              @click="deleteMsg(msgObj.originalIndex)"
                            >
                              <div class="row items-center">
                                <q-icon
                                  name="delete"
                                  size="20px"
                                  color="negative"
                                />
                                <div class="q-pl-sm">Delete</div>
                              </div>
                            </q-item>
                          </q-list>
                        </q-menu>
                      </q-btn>
                    </div>
                  </div>
                </div>
                <div class="row col-12 text-caption text-grey-8">
                  {{ parseMsgTime(msgObj.time) }}
                  <q-space />
                  <q-btn
                    v-if="msgObj.isSelf && msgObj.starred && chat.isPartOfChat"
                    round
                    flat
                    icon="star"
                    color="accent"
                    size="12px"
                    style="float: right"
                    padding="0px"
                    @click="starMsg(false, msgObj.originalIndex)"
                  />
                  <q-btn
                    v-if="msgObj.isSelf && !msgObj.starred && chat.isPartOfChat"
                    round
                    flat
                    icon="star"
                    color="grey-6"
                    size="12px"
                    style="float: right"
                    padding="0px"
                    @click="starMsg(true, msgObj.originalIndex)"
                  />
                  <q-icon
                    v-if="unreadIds.includes(msgObj.id)"
                    name="mark_chat_unread"
                    color="accent"
                    class="q-pl-xs"
                    style="float: right"
                    size="20px"
                  />
                  <q-icon
                    dense
                    name="done_all"
                    size="18px"
                    :color="msgObj.readByAll ? 'blue-9' : 'grey-6'"
                    class="q-pl-sm"
                  />
                </div>
              </div>
            </q-chat-message>

            <div :id="chat.chatIdsStr" class="q-py-sm">
              <q-input
                outlined
                autogrow
                v-model="chat.inputMsg"
                label="Message"
                @input="$emit('typedMsg', chat.inputMsg)"
              >
                <template v-slot:append>
                  <q-btn
                    @click="addMsg(chat)"
                    round
                    flat
                    :loading="chat.sending"
                    icon="send"
                  />
                </template>
              </q-input>
            </div>
          </q-card>
        </div>
      </template>
    </div>

    <q-dialog v-model="dialogEditMessage" persistent>
      <q-card style="width: 280px">
        <div class="text-primary text-subtitle1 text-center q-pt-sm">
          Edit Message
        </div>
        <div class="q-pa-md">
          <q-input outlined autogrow v-model="editedMsg" label="Message" />

          <div class="row justify-end q-pt-md">
            <q-btn
              label="Cancel"
              color="grey-2"
              text-color="primary"
              class="q-mr-sm"
              @click="dialogEditMessage = false"
            />
            <q-btn label="Save" color="primary" @click="editMsgConfirmed" />
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import store from '@/store';
import { parseTimeStamp } from '@/lib/date-utils';
import { countUnread, cloneObj, createHyperLink } from '@/lib/helpers';
import { v4 } from 'uuid';
import { colors } from 'quasar';

export default {
  name: 'WorkLogChats',
  props: {
    selectedLog: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chats: [],
      createHyperLink,
      dialogEditMessage: false,
      editedMsg: '',
      editedMsgIndex: -1,
      fullUserList: false,
      messageSent: '',
      noGroupErr: false,
      scrollTo: {
        scroll: false,
        id: ''
      },
      sendingMsg: false,
      showNote: false,
      unreadIds: []
    };
  },
  mounted() {
    this.markMsgRead();
    this.buildChats();

    setTimeout(() => {
      this.setUnreadArr();
    }, 2 * 60 * 1001);
  },
  methods: {
    addMsg(chat) {
      chat.sending = true;

      if (!chat.inputMsg.length) {
        this.$q.notify({
          icon: 'error',
          message: 'Cannot send empty message!',
          color: 'accent'
        });
        return;
      }

      // If user is not currently part of chat
      const isPartOfChat = chat.chatIds.some((x) => x === this.currUserId);
      if (!isPartOfChat) {
        this.$q.dialog({
          title: 'New Chat',
          message: 'A new chat was started!'
        });

        chat.chatIds.push(this.currUserId);
      }

      const chatGroup = [];
      for (const id of chat.chatIds) {
        chatGroup.push({
          userId: id,
          viewed: id === this.currUserId ? 1 : 0
        });
      }

      const workLog = JSON.parse(JSON.stringify(this.selectedLog));
      const sentTo = [];
      workLog.deleteMsg = false;
      workLog.starMsg = false;
      workLog.editMsg = false;
      workLog.markRead = false;
      workLog.newMsg = {
        id: v4(),
        sentBy: this.$store.state.user.user_id,
        chatGroup,
        comment: chat.inputMsg,
        time: Date.now(),
        sentTo
      };

      const users = [];
      let adminOnly = true;
      if (this.isSuperAdmin) {
        for (let i = 0; i < chatGroup.length; i++) {
          const id = chatGroup[i].userId;
          const user = this.userList.find((x) => x.value === id);
          if (user.userRole === 'installer') {
            users.push(user.label);
            adminOnly = false;
          }
        }
      }

      this.messageSent = chat.inputMsg;
      chat.inputMsg = '';

      if (!adminOnly) {
        this.$q
          .dialog({
            title: 'Confirm',
            message: `Are you sure you want to send to ${users.join(' - ')}?`,
            cancel: true,
            persistent: true
          })
          .onOk(() => {
            this.msgDispatch(workLog);

            this.$q.notify({
              message: 'Message Sent',
              color: 'primary'
            });
          })
          .onCancel(() => {
            this.$q.notify({
              message: 'Message Not Sent',
              color: 'red-14'
            });
          });
      } else {
        this.msgDispatch(workLog);

        this.$q.notify({
          message: 'Message Sent',
          color: 'primary'
        });

        this.scrollTo = {
          scroll: true,
          id: chat.chatIdsStr
        };
      }
    },
    addToUnreadList(workLog) {
      const unreadMsgs = cloneObj(this.$store.state.unreadWorkLogMsgs);
      const addToUnread = unreadMsgs.unreadArr.filter((x) => {
        return workLog.chats.some((y) => y.id === x);
      });

      const now = +new Date();
      const unreadArr = JSON.parse(localStorage.getItem('unreadChats')) || [];
      addToUnread.forEach((x) => {
        unreadArr.push({ id: x, ts: now });
      });

      localStorage.setItem('unreadChats', JSON.stringify(unreadArr));

      this.setUnreadArr();
    },
    buildBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    buildChats() {
      if (!this.selectedLog) return [];
      const chats = [];
      // Look for new chats and add to prevent text evaporation
      for (const chat of this.chats) {
        if (chat.messages.length < 1) {
          chats.push(chat);
        }
      }

      const chatIdsSortedArr = [];
      for (let i = 0; i < this.selectedLog.chats.length; i++) {
        const chat = JSON.parse(JSON.stringify(this.selectedLog.chats[i]));

        const chatIds = chat.chatGroup
          .map((x) => x.userId)
          .sort((a, b) => a - b);

        const isPartOfChat = chatIds.includes(this.currUserId);

        if (!isPartOfChat && !this.isSuperAdmin) {
          continue;
        }

        let starred = false;
        if (isPartOfChat) {
          const userIndex = chat.chatGroup.findIndex(
            (x) => x.userId === this.currUserId
          );
          if (chat.chatGroup[userIndex].viewed === 2) {
            starred = true;
          }
        }

        const chatIdsStr = JSON.stringify(chatIds);
        const groupStr = chat.chatGroup
          .map(
            (x) =>
              this.userList.find((u) => x.userId === u.value)?.label ?? '~~~'
          )
          .join(' - ');

        const readByAll = !chat.chatGroup.some((x) => !x.viewed);

        const chatIndex = chats.findIndex((x) => x.chatIdsStr === chatIdsStr);

        if (chatIndex === -1) {
          // Prevent text from evaporting if a workLog is edited by another user
          const oldChat = this.chats.find((x) => x.chatIdsStr === chatIdsStr);
          const inputMsg =
            oldChat && oldChat.inputMsg !== this.messageSent
              ? oldChat.inputMsg
              : '';
          this.messageSent = '';

          chatIdsSortedArr.push(chatIdsStr);
          chats.push({
            inputMsg,
            chatIds,
            chatIdsStr,
            isPartOfChat,
            groupStr,
            sending: false,
            messages: [
              {
                id: chat.id,
                originalIndex: i,
                sentBy: chat.sentBy,
                readByAll,
                msg: chat.comment,
                time: chat.time,
                isSelf: chat.sentBy === this.currUserId,
                starred
              }
            ]
          });
        } else {
          chats[chatIndex].messages.push({
            id: chat.id,
            originalIndex: i,
            sentBy: chat.sentBy,
            readByAll,
            msg: chat.comment,
            time: chat.time,
            isSelf: chat.sentBy === this.currUserId,
            starred
          });
        }
      }

      for (let i = 0; i < chats.length; i++) {
        // New chats will have no messages
        if (chats[i].messages.length > 0) {
          chats[i].lastChatTs =
            chats[i].messages[chats[i].messages.length - 1].time;
        }
      }

      this.chats = chats;

      if (this.scrollTo.scroll) {
        this.$nextTick(() => {
          document.getElementById(this.scrollTo.id).scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          });
          this.scrollTo.scroll = false;
        });
      }
    },
    createNewChat() {
      const userList = cloneObj(this.userList);
      const users = userList.filter(
        (x) => x.value !== this.currUserId && x.chatAuth
      );

      this.$q
        .dialog({
          position: 'top',
          title: 'Select Chat Recipients:',
          options: {
            type: 'checkbox',
            model: [],
            items: users
          },
          ok: {
            label: 'Create Chat'
          },
          cancel: {
            flat: true
          },
          focus: 'none',
          persistent: true
        })
        .onOk((data) => {
          if (!data.length) {
            return;
          }

          data.push(this.currUserId);
          data.sort((a, b) => a - b);

          const chat = {
            chatIds: data,
            chatIdsStr: JSON.stringify(data),
            groupStr: data
              .map(
                (x) => this.userList.find((u) => x === u.value)?.label ?? '~~~'
              )
              .join(' - '),
            inputMsg: '',
            isPartOfChat: true,
            lastChatTs: +new Date(),
            messages: []
          };

          const chatExists = this.chats.some(
            (x) => x.chatIdsStr === chat.chatIdsStr
          );

          if (!chatExists) {
            this.chats.push(chat);
          }

          this.$nextTick(() => {
            document.getElementById(chat.chatIdsStr).scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center'
            });
            this.scrollTo.scroll = false;
          });
        });
    },
    deleteEmptyChat(chat) {
      const index = this.chats.findIndex(
        (x) => x.chatIdsStr === chat.chatIdsStr
      );
      if (index !== -1) {
        this.chats.splice(index, 1);
      }
    },
    deleteMsg(index) {
      this.$q
        .dialog({
          title: 'Delete Message?',
          ok: {
            label: 'Delete',
            color: 'accent',
            icon: 'delete'
          },
          cancel: {
            flat: true,
            color: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          const workLog = JSON.parse(JSON.stringify(this.selectedLog));
          workLog.addMsg = false;
          workLog.starMsg = false;
          workLog.editMsg = false;
          workLog.markRead = false;
          workLog.deleteMsg = {
            id: workLog.chats[index].id
          };
          this.msgDispatch(workLog);

          this.$q.notify({
            message: 'Message Deleted',
            color: 'primary'
          });
        });
    },
    editMsg(index) {
      const workLog = JSON.parse(JSON.stringify(this.selectedLog));

      this.editedMsg = workLog.chats[index].comment;
      this.editedMsgIndex = index;
      this.dialogEditMessage = true;
    },
    editMsgConfirmed() {
      const workLog = JSON.parse(JSON.stringify(this.selectedLog));

      workLog.addMsg = false;
      workLog.deleteMsg = false;
      workLog.starMsg = false;
      workLog.markRead = false;
      workLog.editMsg = {
        id: workLog.chats[this.editedMsgIndex].id,
        comment: this.editedMsg
      };

      this.msgDispatch(workLog);
      this.dialogEditMessage = false;

      this.$q.notify({
        message: 'Message Edited',
        color: 'primary'
      });
    },
    fieldDecoder(fieldDef, fields) {
      const item = fields[fieldDef.id];
      if (item) {
        switch (fieldDef.type) {
          case 'checkbox':
            return item || false;
          case 'date':
            return this.dateHelper(item);
          case 'number':
          case 'text':
          case 'fields':
            return item;
          default:
            return '!!';
        }
      } else {
        switch (fieldDef.type) {
          case 'checkbox':
            return false;
          default:
            return '--';
        }
      }
    },
    fieldList(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.fields : [];
    },
    markMsgRead() {
      if (!this.selectedLog) return;
      const workLog = JSON.parse(JSON.stringify(this.selectedLog));

      this.addToUnreadList(workLog);

      const unread = countUnread(workLog.chats, this.currUserId);
      if (!unread) {
        return;
      }

      workLog.deleteMsg = false;
      workLog.starMsg = false;
      workLog.editMsg = false;
      workLog.addMsg = false;
      workLog.markRead = {
        chats: workLog.chats,
        currentUser: this.currUserId
      };
      this.msgDispatch(workLog);

      const msg = unread > 1 ? ' Messages' : ' Message';

      this.$q.notify({
        message: unread + msg + ' Marked Read',
        color: 'primary'
      });
    },
    msgDispatch(workLog) {
      this.$emit('typedMsg', '');

      store
        .dispatch('chatWorkLog', { farmId: workLog.farm_id, log: workLog })
        .then((results) => {
          // COMMENT: following line necessary to disable green checkbox for chats.
          results.disableCheck = true;
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.msgDispatch({ farmId: workLog.farm_id, log: workLog });
            }
          });
        });
    },
    parseMsgTime(value) {
      if (value && !isNaN(value)) {
        return new Date(value).toLocaleString('en-US', {
          timeStyle: 'short',
          dateStyle: 'short'
        });
      } else {
        return '--';
      }
    },
    parseTimeStamp(value) {
      return parseTimeStamp(value, 'short');
    },
    setUnreadArr() {
      const unreadArr = JSON.parse(localStorage.getItem('unreadChats')) || [];
      const twoMinAgo = +new Date() - 2 * 60 * 1000;
      const updatedArr = unreadArr.filter((x) => x.ts > twoMinAgo);
      localStorage.setItem('unreadChats', JSON.stringify(updatedArr));
      this.unreadIds = updatedArr.map((x) => x.id);
    },
    starMsg(star, chatIndex) {
      const workLog = JSON.parse(JSON.stringify(this.selectedLog));

      workLog.addMsg = false;
      workLog.markRead = false;
      workLog.deleteMsg = false;
      workLog.editMsg = false;
      workLog.starMsg = {
        id: workLog.chats[chatIndex].id,
        currentUser: this.currUserId,
        star
      };
      this.msgDispatch(workLog);

      if (star) {
        this.$q.notify({
          message: 'Message Starred',
          icon: 'star',
          color: 'yellow',
          textColor: 'black'
        });
      } else {
        this.$q.notify({
          message: 'Star Removed',
          icon: 'star',
          color: 'primary'
        });
      }
    },
    userName(id) {
      if (id === null) {
        return '--';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '- - - -';
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.user_id;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id,
          chatAuth: this.$store.state.userList[i].chatAuth,
          userRole: this.$store.state.userList[i].user_role
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return list;
    }
  },
  watch: {
    '$store.state.workLogs'() {
      this.buildChats();
    },
    selectedLog() {
      this.buildChats();
      this.markMsgRead();
    }
  }
};
</script>

<style scoped></style>
