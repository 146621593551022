import { render, staticRenderFns } from "./farm-computers.vue?vue&type=template&id=db9660c8&scoped=true"
import script from "./farm-computers.vue?vue&type=script&lang=js"
export * from "./farm-computers.vue?vue&type=script&lang=js"
import style0 from "./farm-computers.vue?vue&type=style&index=0&id=db9660c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db9660c8",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTd,QTooltip,QIcon,QBtn,QPageSticky,QDialog,QCard,QCardSection,QForm,QToolbar,QToolbarTitle,QInput,QSelect,QSeparator,QToggle});
