<template>
  <div>
    <q-table
      title="To-Do"
      dense
      :data="Todos"
      :columns="columns"
      :visible-columns="visibleColumns"
      :pagination.sync="pagination"
      class="sticky-header-table"
      :style="
        farmOnly ? 'height: calc(100vh - 95px)' : 'height: calc(100vh - 52px)'
      "
      no-data-label="No logs"
      row-key="name"
    >
      <template v-slot:top>
        <!-- Filtering -->
        <div class="row q-py-sm">
          <div
            class="rounded-borders q-pa-sm q-mr-md"
            style="border: 1px solid #1976d2"
          >
            <q-toggle
              v-model="filter.selfAssigned"
              dense
              label="My assignments"
            />
          </div>
          <q-btn
            outline
            @click="downloadXLSX"
            color="secondary"
            padding="xs 10px"
            icon="fas fa-file-excel"
          />
        </div>
      </template>

      <template v-slot:header-cell="props">
        <q-th :props="props" style="padding: 0; text-align: center">
          <div>
            {{ props.col.label }}
          </div>
          <div>
            {{ props.col.label2 }}
          </div>
        </q-th>
      </template>

      <template v-slot:body-cell-Date="props">
        <q-td
          :style="
            props.row.status === 'Complete'
              ? 'background-color:rgb(0,255,0,0.3)'
              : ''
          "
          :class="props.row.id === scrollToId ? 'bg-red-14' : ''"
        >
          <template>
            <div
              :id="props.row.id"
              style="position: absolute; top: -36px; left: 0"
            ></div>
            {{ parseTimeStamp(props.row.date, 'short') }}
          </template>
        </q-td>
      </template>

      <template v-slot:body-cell-Chat="props">
        <q-td :props="props">
          <q-btn
            v-if="props.row.numUnread > 0"
            outline
            color="red-14"
            size="sm"
            padding="sm 10px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="chat_bubble" color="red-14" />
          </q-btn>
          <q-btn
            v-if="props.row.numStarred > 0 && props.row.numUnread === 0"
            outline
            color="red-14"
            size="sm"
            padding="sm 10px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="star" color="red-14" />
          </q-btn>
          <q-btn
            v-if="
              props.row.hasChat &&
              (isAuthForChat || isSuperAdmin) &&
              props.row.numImportant === 0
            "
            outline
            color="primary"
            size="sm"
            padding="sm 10px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="chat_bubble" color="primary" />
          </q-btn>
          <q-btn
            v-if="!props.row.hasChat && (isAuthForChat || isSuperAdmin)"
            outline
            color="grey-6"
            size="sm"
            padding="sm 10px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="chat_bubble" color="grey-6" />
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Reminders="props">
        <q-td :props="props" style="padding: 4px 0px 4px 0px; width: 60px">
          <q-btn
            v-if="props.row.reminderStatus.remindNow"
            round
            outline
            color="red-14"
            size="sm"
            icon="alarm"
            padding="sm"
            @click="remindersDisplay(props.row)"
          />
          <q-btn
            v-if="
              props.row.reminderStatus.hasReminder &&
              !props.row.reminderStatus.remindNow
            "
            round
            :outline="!props.row.reminderStatus.remindNow"
            color="deep-purple-14"
            size="sm"
            padding="sm"
            @click="remindersDisplay(props.row)"
          >
            <q-icon
              name="alarm"
              :color="
                props.row.reminderStatus.userReminder
                  ? 'red-14'
                  : 'deep-purple-14'
              "
            />
          </q-btn>
          <q-btn
            v-if="!props.row.reminderStatus.hasReminder"
            round
            outline
            color="grey-6"
            size="sm"
            icon="alarm"
            padding="sm"
            @click="remindersDisplay(props.row)"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-Note="props">
        <q-td :props="props">
          <q-item
            class="ellipsis-2-lines"
            style="max-height: 48px; white-space: pre-line !important"
            clickable
            @click="showNoteClick(props.row)"
          >
            <q-icon
              v-if="props.row.comments.length > 0"
              right
              size="20px"
              color="primary"
              name="chat_bubble_outline"
              class="q-mr-xs"
            />
            {{ decoder(props.row.note) }}
          </q-item>
        </q-td>
      </template>

      <template v-slot:body-cell-Farm="props">
        <q-td :props="props" class="ellipsis">
          {{ farmName(props.row.farm_id) }}
        </q-td>
      </template>

      <template v-slot:body-cell-Status="props">
        <q-td :props="props">
          <q-btn
            :class="mapStatusColor(props.row.status)"
            :label="props.row.status"
            padding="xs"
            style="min-width: 110px"
          >
            <q-menu>
              <q-list style="min-width: 110px">
                <q-item
                  v-for="(opt, index) in statusOptions"
                  :key="index"
                  clickable
                  @click="changeStatus(props.row, opt.label)"
                  v-close-popup
                >
                  <q-item-section
                    :class="`content-center bg-${opt.color} ${opt.text}`"
                    >{{ opt.label }}</q-item-section
                  >
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Priority="props">
        <q-td :props="props">
          <q-btn
            :class="mapPriorityColor(props.row.priority)"
            :label="props.row.priority"
            padding="sm xs"
            style="min-width: 110px"
          >
            <q-menu>
              <q-list style="min-width: 110px">
                <q-item
                  v-for="(opt, index) in priorityOptions"
                  :key="index"
                  clickable
                  @click="changePriority(props.row, opt.label)"
                  v-close-popup
                >
                  <q-item-section
                    :class="`content-center bg-${opt.color} ${opt.text}`"
                    >{{ opt.label }}</q-item-section
                  >
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Assigned="props">
        <q-td :props="props" class="ellipsis">
          {{ userName(props.row.assigned_user) }}
        </q-td>
      </template>

      <template v-slot:body-cell-CreatedBy="props">
        <q-td :props="props" class="ellipsis">
          {{ userName(props.row.user_id) }}
        </q-td>
      </template>

      <template v-slot:body-cell-Edit="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            icon="edit"
            size="md"
            padding="xs"
            @click="editTodo(props.row)"
          >
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Delete="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-negative"
            outline
            icon="delete"
            size="md"
            padding="xs"
            @click="deleteTodo(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
    </q-table>

    <!-- Add sticky -->
    <q-page-sticky position="bottom-right" :offset="[48, 48]">
      <q-btn fab icon="add" color="accent" @click="addTodo" />
    </q-page-sticky>

    <!-- chat display-->
    <q-dialog persistent v-model="showChat">
      <q-card
        v-if="selectedTodo"
        style="width: 700px; max-width: 80vw"
        :style="`max-height: ${chatHeight()}px`"
        class="scroll"
      >
        <div align="right" class="q-pa-sm">
          <q-btn flat label="Done" color="primary" @click="closeChat" />
        </div>
        <q-separator />
        <q-card-section>
          <TodoChats ref="TodoChats" :selectedTodo="selectedTodo" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- note display-->
    <q-dialog v-model="showNote">
      <q-card v-if="selectedTodo" style="width: 700px; max-width: 80vw">
        <q-card-section>
          <div class="text-h6">{{ parseTimeStamp(selectedTodo.date) }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div>{{ decoder(selectedTodo.note) }}</div>
        </q-card-section>
        <q-separator />

        <q-card-section class="q-pt-lg">
          <Comments :parentObj="selectedTodo" :commentType="'todo'" />
        </q-card-section>
        <q-separator />

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent>
      <q-card
        square
        bordered
        style="width: 500px"
        :class="$q.screen.xs ? 'q-pa-xs' : 'q-pa-lg'"
      >
        <q-card-section>
          <q-form @submit="onSubmit">
            <div
              class="row justify-center full-width text-h6 text-black q-py-sm q-mb-md"
              style="
                background: radial-gradient(circle, #64b5f6 0%, #0d47a1 100%);
              "
            >
              {{ ModalHeader }}
            </div>

            <div class="q-my-sm">
              <q-input
                filled
                v-model="editTodoCopy.note"
                label="Note"
                maxlength="1024"
                outlined
                square
                autogrow
              />
            </div>
            <div class="row q-mb-sm" v-if="!farmOnly">
              <div class="col-8 q-pr-sm">
                <q-select
                  v-model="editTodoCopy.farm_id"
                  square
                  dense
                  filled
                  :options="FarmOptions"
                  emit-value
                  map-options
                  label="Farm"
                />
              </div>
              <div class="col">
                <q-input dense v-model="farmSearch" filled type="search">
                  <template v-slot:append>
                    <q-icon name="search" />
                  </template>
                </q-input>
              </div>
            </div>

            <div class="q-mb-sm">
              <q-select
                v-model="editTodoCopy.status"
                square
                filled
                :options="statusOptions"
                emit-value
                map-options
                label="Status"
              >
                <template v-slot:selected>
                  <div>
                    <q-btn
                      size="xs"
                      :class="mapStatusColor(editTodoCopy.status)"
                    ></q-btn>
                    {{ editTodoCopy.status }}
                  </div>
                </template>
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                    <q-item-section style="max-width: 50px">
                      <q-btn size="xs" :class="'bg-' + scope.opt.color"></q-btn>
                    </q-item-section>
                    <q-item-section>
                      {{ scope.opt.label }}
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>

            <div class="q-mb-sm">
              <q-select
                v-model="editTodoCopy.priority"
                square
                filled
                :options="priorityOptions"
                emit-value
                map-options
                label="Priority"
              >
                <template v-slot:selected>
                  <div>
                    <q-btn
                      size="xs"
                      :class="mapPriorityColor(editTodoCopy.priority)"
                    ></q-btn>
                    {{ editTodoCopy.priority }}
                  </div>
                </template>
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                    <q-item-section style="max-width: 50px">
                      <q-btn size="xs" :class="'bg-' + scope.opt.color"></q-btn>
                    </q-item-section>
                    <q-item-section>
                      {{ scope.opt.label }}
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>

            <div class="q-mb-sm">
              <q-select
                v-model="editTodoCopy.assigned_user"
                square
                dense
                filled
                :options="userListAssigned"
                emit-value
                map-options
                label="Assigned"
              />
            </div>

            <div class="q-mb-md">
              <q-input
                filled
                dense
                v-model="itemDate"
                label="Log Date"
                type="date"
                outlined
                square
              />
            </div>
            <div>
              <div class="row full-width">
                <q-space />
                <q-btn
                  flat
                  label="Cancel"
                  color="primary"
                  class="q-mr-sm"
                  @click="cancelEdit"
                />
                <q-btn label="Submit" type="submit" color="primary" />
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>

    <!--Reminder dialog-->
    <q-dialog position="top" v-model="reminderDialog" persistent>
      <q-card
        square
        style="width: 450px"
        :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'"
      >
        <div>
          <div v-if="reminderMode === 'add' && !isSuperAdmin">
            {{ userName(reminderRecipient) }}
            <q-separator />
          </div>
          <div v-if="reminderMode === 'add' && isSuperAdmin" class="q-mb-md">
            <q-select
              v-model="reminderRecipient"
              square
              filled
              color="red-14"
              :options="reminderUserList"
              emit-value
              map-options
              label="Select Recipient"
            />
            <div v-if="recipErr" class="text-caption text-red-14">
              Please Select Recipient
            </div>
          </div>
          <div v-if="reminderMode === 'edit'" class="text-subtitle2">
            {{ userName(reminderObj.reminderRecipient) }}
            <q-separator />
          </div>

          <div class="row items-center q-py-sm">
            <div class="q-mr-md">
              <q-btn
                icon="event"
                color="deep-purple-14"
                class="q-mr-xs"
                size="sm"
                padding="xs sm"
              >
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-date
                    v-model="reminderDate"
                    no-unset
                    mask="YYYY-MM-DD h:mm A"
                    color="deep-purple-14"
                  >
                    <div class="row items-center justify-end q-gutter-sm">
                      <q-btn
                        label="Cancel"
                        color="deep-purple-14"
                        flat
                        v-close-popup
                      />
                      <q-btn
                        label="OK"
                        color="deep-purple-14"
                        flat
                        v-close-popup
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-btn>
              <q-btn
                icon="access_time"
                color="deep-purple-14"
                class="q-mx-xs"
                size="sm"
                padding="xs sm"
              >
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-time
                    v-model="reminderDate"
                    mask="YYYY-MM-DD h:mm A"
                    color="deep-purple-14"
                  >
                    <div class="row items-center justify-end q-gutter-sm">
                      <q-btn
                        label="Cancel"
                        color="deep-purple-14"
                        flat
                        v-close-popup
                      />
                      <q-btn
                        label="OK"
                        color="deep-purple-14"
                        flat
                        v-close-popup
                      />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-btn>
              <q-btn
                v-if="reminderMode === 'edit'"
                icon="delete"
                color="red-14"
                size="sm"
                padding="xs sm"
                class="q-ml-xs"
                @click="reminderDelete"
              />
            </div>
            <q-space />
            <span class="q-mr-md">
              {{ reminderDateF(reminderDate) }}
            </span>
            <span>
              {{ reminderTime(reminderDate) }}
            </span>
          </div>
          <div v-if="backDateErr" class="text-caption text-red-14">
            Reminder Date/Time Has Already Passed
          </div>
          <q-separator />

          <div class="text-center text-green-14 q-mt-sm">Remind In:</div>
          <div class="row q-py-sm">
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Hr"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddTime(3600000)"
              />
            </div>
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Day"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddTime(86400000)"
              />
            </div>
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Wk"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddTime(604800000)"
              />
            </div>
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Mo"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddMonth()"
              />
            </div>
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Yr"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddYear()"
              />
            </div>
          </div>
          <q-separator />

          <div class="row full-width q-mt-md">
            <q-space />
            <q-btn
              flat
              label="Cancel"
              color="red-14"
              class="q-mr-sm"
              size="sm"
              @click="reminderDialog = false"
            />
            <q-btn
              label="Set Reminder"
              color="red-14"
              size="sm"
              @click="reminderSet"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!--Reminder Info dialog-->
    <q-dialog v-model="reminderInfoDialog" persistent>
      <q-card
        style="width: 450px"
        :class="$q.screen.xs ? 'q-pa-sm' : 'q-pa-md'"
      >
        <div class="row items-center q-mb-sm">
          {{ farmNameOnly(editTodoCopy.farm_id) }}
          <q-space />
          <q-btn
            icon="clear"
            color="deep-purple-14"
            size="sm"
            padding="2px sm"
            v-close-popup
          />
        </div>
        <div
          class="row items-center ful-width q-pa-sm"
          style="border: 1px solid #6200ea; border-radius: 4px"
        >
          <div class="text-deep-purple-14 text-subtitle2">
            <q-icon name="alarm" size="28px" class="q-mr-sm" />
            Reminders
          </div>
          <q-space />
          <div class="q-mr-sm">
            <q-btn
              v-if="
                (isSuperAdmin && reminderUserList.length) ||
                !remindersFiltered.length
              "
              round
              icon="add"
              size="sm"
              color="red-14"
              @click="reminderAdd"
            />
          </div>
          <q-separator
            v-if="remindersFiltered.length"
            class="full-width q-mt-sm"
          />

          <div v-if="remindersFiltered.length" class="row full-width">
            <div class="text-subtitle1 text-red-14 q-py-xs">Recipients</div>
            <div
              v-for="(reminder, i) in remindersFiltered"
              :key="reminder.id"
              class="row items-center full-width q-py-xs"
            >
              <div v-if="!$q.screen.xs">
                {{ userName(reminder.reminderRecipient) }}
              </div>
              <q-space v-if="!$q.screen.xs" />
              <div v-if="!$q.screen.xs" class="q-mr-md">
                {{ reminderDateF(reminder.reminderDate) }}
              </div>
              <div v-if="!$q.screen.xs" class="q-mr-md">
                {{ reminderTime(reminder.reminderDate) }}
              </div>

              <div v-if="$q.screen.xs">
                <div>
                  {{ userName(reminder.reminderRecipient) }}
                </div>
                <div>
                  <span class="q-mr-md">
                    {{ reminderDateF(reminder.reminderDate) }}
                  </span>
                  <span>
                    {{ reminderTime(reminder.reminderDate) }}
                  </span>
                </div>
              </div>
              <q-space v-if="$q.screen.xs" />

              <div>
                <q-btn
                  outline
                  icon="edit"
                  color="deep-purple-14"
                  size="sm"
                  padding="3px xs"
                  class="q-mr-sm"
                  @click="reminderEdit(reminder)"
                />
              </div>
              <q-separator
                v-if="i !== remindersFiltered.length - 1"
                class="full-width q-mt-sm"
              />
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, chatInfo, trimName } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import { date, exportFile } from 'quasar';
import { v4 } from 'uuid';
import store from '@/store';
import xl from 'excel4node';
import image from '@/assets/pdf/AerWorx_logo_pdf.png';
import Comments from '@/components/workLogs/Comments';
import TodoChats from '@/components/TodoChats';

export default {
  name: 'TodoPlugin',
  components: {
    Comments,
    TodoChats
  },
  props: {
    farmId: {
      type: String,
      required: true
    },
    farmOnly: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      filter: {
        selfAssigned: false
      },
      farmSearch: '',
      statusOptions: [
        {
          label: 'None',
          color: 'blue-grey',
          text: 'text-white',
          value: 'None',
          reportColor: '#EEEEEE'
        },
        {
          label: 'Not started',
          color: 'deep-orange',
          text: '',
          value: 'Not started',
          reportColor: '#ff6b4f'
        },
        {
          label: 'Working',
          color: 'blue',
          text: '',
          value: 'Working',
          reportColor: '#8888FF'
        },
        {
          label: 'Complete',
          color: 'green',
          text: '',
          value: 'Complete',
          reportColor: '#88FF88'
        }
      ],
      priorityOptions: [
        {
          label: 'None',
          color: 'blue-grey',
          text: 'text-white',
          value: 'None',
          reportColor: '#EEEEEE'
        },
        {
          label: 'Low',
          color: 'blue',
          text: '',
          value: 'Low',
          reportColor: '#8888FF'
        },
        {
          label: 'Medium',
          color: 'yellow-14',
          text: 'text-black',
          value: 'Medium',
          reportColor: '#ffff88'
        },
        {
          label: 'High',
          color: 'orange-10',
          text: 'text-white',
          value: 'High',
          reportColor: '#ff6b4f'
        },
        {
          label: 'Critical',
          color: 'red-10',
          text: 'text-white',
          value: 'Critical',
          reportColor: '#ff2222'
        }
      ],
      showNote: false,
      showChat: false,
      selectedTodo: null,
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 50
      },
      visibleColumns: this.farmOnly ? [] : ['Farm'],
      columns: [
        {
          required: true,
          sortable: true,
          name: 'Date',
          label: 'Date',
          align: 'center',
          style: 'max-width: 75px',
          field: (row) => parseTimeStamp(row.date, 'short')
        },
        {
          required: true,
          sortable: true,
          name: 'Chat',
          label: 'Chat',
          align: 'center',
          field: (row) => row.numImportant,
          sort: (a, b, rowA, rowB) => {
            if (rowA.numImportant === 0 && rowB.numImportant === 0) {
              return rowA.hasChat === rowB.hasChat ? 0 : rowA.hasChat ? -1 : 1;
            }
            return rowB.numImportant - rowA.numImportant;
          }
        },
        {
          required: true,
          sortable: true,
          name: 'Reminders',
          label: 'Reminders',
          align: 'center',
          field: (row) => row.reminders,
          sort: (a, b, rowA, rowB) => {
            // Trick to make math.min work correctly
            const rowATSArr = [999999999999999];
            let rowARem = false;
            let rowATS = 0;
            rowA.reminders.forEach((x) => {
              if (x.reminderRecipient === this.currUserId) {
                rowARem = true;
                rowATS = x.reminderDate;
              }
              rowATSArr.push(x.reminderDate);
            });

            const rowBTSArr = [999999999999999];
            let rowBRem = false;
            let rowBTS = 0;
            rowB.reminders.forEach((x) => {
              if (x.reminderRecipient === this.currUserId) {
                rowBRem = true;
                rowBTS = x.reminderDate;
              }
              rowBTSArr.push(x.reminderDate);
            });

            if (rowARem && rowBRem) {
              return rowATS - rowBTS;
            }

            if (rowARem && !rowBRem) {
              return -1;
            }

            if (!rowARem && rowBRem) {
              return 1;
            }

            return Math.min(...rowATSArr) - Math.min(...rowBTSArr);
          }
        },
        {
          sortable: true,
          name: 'Chat',
          label: 'Chat',
          align: 'center',
          field: (row) => row.chats,
          sort: (a, b, rowA, rowB) => {
            if (rowA.numImportant === 0 && rowB.numImportant === 0) {
              return rowA.hasChat === rowB.hasChat ? 0 : rowA.hasChat ? -1 : 1;
            }
            return rowB.numImportant - rowA.numImportant;
          }
        },
        {
          required: true,
          sortable: true,
          name: 'Note',
          label: 'Note',
          align: 'center',
          style: 'max-width: 240px; ellipsis',
          field: (row) => row.status,
          sort: (a, b, rowA, rowB) => {
            if (rowA.comments.length === 0 && rowB.comments.length === 0) {
              return 0;
            }
            if (rowA.comments.length > 0 && rowB.comments.length === 0) {
              return -1;
            }
            if (rowA.comments.length === 0 && rowB.comments.length > 0) {
              return 1;
            }
            return rowB.lastCommentTime - rowA.lastCommentTime;
          }
        },
        {
          required: false,
          sortable: true,
          name: 'Farm',
          label: 'Farm',
          align: 'center',
          style: 'max-width: 110px',
          field: (row) => this.farmName(row.farm_id)
        },
        {
          required: true,
          sortable: true,
          name: 'Status',
          label: 'Status',
          align: 'center',
          style: 'max-width: 120px',
          field: (row) => row.status,
          sort: (a, b, rowA, rowB) => {
            return (
              this.statusOptions.findIndex((e) => e.value === rowA.status) -
              this.statusOptions.findIndex((e) => e.value === rowB.status)
            );
          }
        },
        {
          required: true,
          sortable: true,
          name: 'Priority',
          label: 'Priority',
          align: 'center',
          style: 'max-width: 130px',
          field: (row) => row.priority,
          sort: (a, b, rowA, rowB) => {
            return (
              this.priorityOptions.findIndex((e) => e.value === rowA.priority) -
              this.priorityOptions.findIndex((e) => e.value === rowB.priority)
            );
          }
        },
        {
          required: true,
          sortable: true,
          name: 'Assigned',
          label: 'Assigned',
          align: 'center',
          style: 'max-width: 100px',
          field: (row) => this.userName(row.assigned_user)
        },
        {
          required: true,
          sortable: true,
          name: 'CreatedBy',
          label: 'Created By',
          align: 'center',
          style: 'max-width: 100px',
          field: (row) => this.userName(row.user_id)
        },
        {
          required: true,
          name: 'Edit',
          label: '',
          align: 'center',
          style: 'max-width: 50px'
          //classes: 'max-width'
        },
        {
          required: true,
          name: 'Delete',
          label: '',
          align: 'center',
          style: 'max-width: 50px'
        }
      ],
      editTodoCopy: {
        id: 'new',
        farm_id: null,
        user_id: -1,
        assigned_user: null,
        note: '',
        comments: [],
        priority: '',
        status: '',
        date: 0,
        reminders: []
      },
      reminderDialog: false,
      reminderDate: date.formatDate(new Date(), 'YYYY-MM-DD h:mm A'),
      reminderMode: 'add',
      reminderObj: null,
      reminderInfoDialog: false,
      reminderRecipient: null,
      reminderRecipConfirm: false,
      recipErr: false,
      backDateErr: false,
      showEditModal: false,
      scrollToIndex: 0,
      scrollToId: ''
    };
  },
  mounted() {
    this.scrollTo();
  },
  methods: {
    scrollTo() {
      // const scrollObj = this.$store.state.generalSearchScrollTo;
      // if (scrollObj.goNow) {
      //   const todoIndex = this.Todos.findIndex((x) => x.id === scrollObj.id);
      //   this.pagination.page =
      //     Math.floor(todoIndex / this.pagination.rowsPerPage) + 1;
      //   this.scrollToId = scrollObj.id;
      //
      //   this.$nextTick(() => {
      //     const el = document.getElementById(scrollObj.id);
      //     if (!el) {
      //       if (this.scrollToIndex > 10) {
      //         return;
      //       }
      //       this.scrollToIndex++;
      //
      //       setTimeout(() => {
      //         this.scrollTo();
      //       }, 200);
      //       return;
      //     }
      //
      //     el.scrollIntoView({
      //       behavior: 'smooth'
      //     });
      //
      //     this.$store.dispatch('setGeneralSearchScrollTo', {
      //       id: '',
      //       goNow: false
      //     });
      //     setTimeout(() => {
      //       this.scrollToId = '';
      //     }, 3500);
      //   });
      // }
    },
    // Important - cannot set reminders more than 2 years into future
    reminderAdd() {
      // set date to tommorrow
      this.reminderDate = date.formatDate(
        +new Date() + 86400000,
        'YYYY-MM-DD h:mm A'
      );
      this.reminderRecipient = null;
      this.recipErr = false;
      this.backDateErr = false;
      this.reminderRecipConfirm = false;
      this.reminderMode = 'add';

      const hasReminder = this.editTodoCopy.reminders.find(
        (x) => x.reminderRecipient === this.currUserId
      );

      this.reminderRecipient = hasReminder
        ? this.reminderUserList[0].value
        : this.currUserId;
      this.reminderDialog = true;
    },
    reminderAddTime(time) {
      let reminderTS = +new Date();
      reminderTS += time;
      this.reminderDate = date.formatDate(reminderTS, 'YYYY-MM-DD h:mm A');
    },
    reminderAddMonth() {
      const plusMo = date.addToDate(new Date(), { months: 1 });
      this.reminderDate = date.formatDate(plusMo, 'YYYY-MM-DD h:mm A');
    },
    reminderAddYear() {
      const plusYr = date.addToDate(new Date(), { years: 1 });
      this.reminderDate = date.formatDate(plusYr, 'YYYY-MM-DD h:mm A');
    },
    reminderDateF(TS) {
      return date.formatDate(TS, 'ddd, MMM D YYYY');
    },
    reminderTime(TS) {
      return date.formatDate(TS, 'h:mm A');
    },
    reminderDelete() {
      const index = this.editTodoCopy.reminders.findIndex(
        (x) => x.id === this.reminderObj.id
      );

      this.$q
        .dialog({
          ok: {
            flat: true,
            label: 'Delete Reminder',
            color: 'red-14'
          },
          cancel: {
            flat: true,
            color: 'deep-purple-10'
          },
          title: 'Confirm',
          message: `Delete Reminder?`,
          persistent: true
        })
        .onOk(() => {
          this.editTodoCopy.reminders.splice(index, 1);

          this.$q.notify({
            message: 'Reminder Deleted',
            icon: 'alarm',
            color: 'red-14'
          });
          this.reminderSetDispatch();
          this.reminderDialog = false;
        });
    },
    reminderEdit(reminder) {
      this.recipErr = false;
      this.backDateErr = false;
      this.reminderRecipConfirm = false;
      this.reminderObj = reminder;
      this.reminderMode = 'edit';
      this.reminderDate = date.formatDate(
        reminder.reminderDate,
        'YYYY-MM-DD h:mm A'
      );
      this.reminderRecipient = reminder.reminderRecipient;
      this.reminderDialog = true;
    },
    reminderFutureCheck(TS) {
      const twoYrFuture = +date.addToDate(this.editTodoCopy.date * 1000, {
        years: 2
      });
      return TS < twoYrFuture;
    },
    reminderFutureErr() {
      this.$q.dialog({
        title: 'Alert',
        ok: {
          flat: true,
          color: 'deep-purple-14'
        },
        message: 'Cannot set Reminder Date more than two years after ToDo date!'
      });
    },
    remindersDisplay(reminderTodo) {
      this.editTodoCopy = reminderTodo;
      this.reminderInfoDialog = true;
    },
    reminderSet() {
      if (!this.reminderRecipient) {
        this.recipErr = true;
        return;
      }

      const now = +new Date();
      const reminderTS = +new Date(
        date.extractDate(this.reminderDate, 'YYYY-MM-DD h:mm A')
      );
      if (now > reminderTS) {
        this.backDateErr = true;
        return;
      }

      // Reminder cannot be set more than two years into future
      if (!this.reminderFutureCheck(reminderTS)) {
        this.reminderFutureErr();
        return;
      }

      // If reminder goes to user other than creator we need to confirm
      if (
        this.reminderRecipient !== this.currUserId &&
        !this.reminderRecipConfirm
      ) {
        let recip = this.userName(this.reminderRecipient);

        this.$q
          .dialog({
            ok: {
              flat: true,
              label: 'Set Reminder',
              color: 'red-14'
            },
            cancel: {
              flat: true,
              color: 'deep-purple-10'
            },
            title: 'Confirm Recipient',
            message: `Set reminder for ${recip}?`,
            persistent: true
          })
          .onOk(() => {
            this.reminderRecipConfirm = true;
            this.reminderSet();
          });

        return;
      }

      if (this.reminderMode === 'add') {
        this.editTodoCopy.reminders.push({
          id: v4(),
          creator: this.currUserId,
          reminderDate: reminderTS,
          reminderRecipient: this.reminderRecipient,
          pinned: false
        });

        this.$q.notify({
          message: 'Reminder Added',
          icon: 'alarm',
          color: 'deep-purple-14'
        });
      }

      if (this.reminderMode === 'edit') {
        const reminder = this.editTodoCopy.reminders.find(
          (x) => x.id === this.reminderObj.id
        );
        reminder.reminderDate = reminderTS;
        reminder.pinned = false;

        this.$q.notify({
          message: 'Reminder Updated',
          icon: 'alarm',
          color: 'deep-purple-14'
        });
      }

      this.reminderSetDispatch();
      this.reminderDialog = false;
    },
    reminderSetDispatch() {
      store
        .dispatch('updateTodo', {
          todo: this.editTodoCopy
        })
        .then((results) => {
          results.disableCheck = true;
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.reminderSetDispatch();
            }
          });
        });
    },
    reminderStatus(reminders) {
      let hasReminder = false;
      let userReminder = null;
      let remindNow = false;
      if (reminders.length) {
        if (this.isSuperAdmin) {
          hasReminder = true;
        }

        userReminder = reminders.find(
          (x) => x.reminderRecipient === this.currUserId
        );

        const now = +new Date();
        remindNow = userReminder ? userReminder.reminderDate < now : false;
        userReminder = userReminder ? true : false;

        if (!this.isSuperAdmin) {
          hasReminder = userReminder;
        }
      }

      return { hasReminder, userReminder, remindNow };
    },
    closeChat() {
      const msg = this.$refs.TodoChats.newMessage;

      if (msg !== '') {
        this.$q
          .dialog({
            title: 'Unsaved Text',
            message:
              'Are you sure you want to exit chat? You have unsaved text that will be lost.',
            cancel: true,
            persistent: true
          })
          .onOk(() => {
            this.showChat = false;
            return;
          });
      } else {
        this.showChat = false;
      }
    },
    chatHeight() {
      return window.innerHeight - 180;
    },
    downloadXLSX() {
      const cols = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        F: 6,
        G: 7,
        H: 8,
        I: 9,
        J: 10
      };
      const report = this.Todos;
      const wb = new xl.Workbook({
        author: 'AerWorx ' + process.env.VUE_APP_VERSION
      });
      const mainColumns = [
        {
          name: 'Date',
          width: 10.75
        },
        {
          name: 'Notes',
          width: 45,
          style: wb.createStyle({
            alignment: {
              wrapText: true
            }
          })
        },
        {
          name: 'Farm',
          width: 16.25
        },
        {
          name: 'Status',
          width: 11.25
        },
        {
          name: 'Priority',
          width: 11.25
        },
        {
          name: 'Assigned',
          width: 16.25
        },
        {
          name: 'Created',
          width: 16.25
        }
      ];
      const ws = wb.addWorksheet('Todos', {});
      const pic = ws.addImage({
        image: Buffer.from(image.substr(22), 'base64'),
        type: 'picture',
        position: {
          type: 'absoluteAnchor',
          x: '0.15in',
          y: '0.3in'
        }
      });
      pic._pxWidth /= 5;
      pic._pxHeight /= 5;
      // Title
      const titleStyle = wb.createStyle({
        font: {
          name: 'Calibri',
          size: 28
        }
      });
      const columnStyle = wb.createStyle({
        font: {
          name: 'Calibri',
          size: 12,
          bold: true
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: 'D9D9D9'
        }
      });
      const altRowStyle = wb.createStyle({
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: 'd9d9d9'
        }
      });

      if (this.farmOnly) {
        ws.cell(2, 5)
          .string(this.farmName(parseInt(this.farmId)))
          .style(titleStyle);
      } else {
        ws.cell(2, 5).string('Global todos').style(titleStyle);
      }

      for (let i = 0; i < mainColumns.length; i++) {
        ws.cell(5, i + 1)
          .string(mainColumns[i].name)
          .style(columnStyle);
        ws.column(i + 1).setWidth(mainColumns[i].width);
      }
      // eslint-disable-next-line no-unreachable
      let c = 6;
      for (let y = 0; y < report.length; y++) {
        const item = report[y];
        const priority = this.priorityOptions.find(
          (e) => e.value === item.priority
        );
        const status = this.statusOptions.find((e) => e.value === item.status);
        if (y % 2 === 1) {
          ws.cell(c, 1, c, 9).style(altRowStyle);
        }
        ws.cell(c, 1).string(parseTimeStamp(item.date, 'shortExcel'));
        ws.cell(c, 2)
          .string(item.note)
          .style({
            alignment: {
              wrapText: true
            }
          });
        ws.cell(c, 3).string(this.farmName(parseInt(item.farm_id)));
        ws.cell(c, 4)
          .string(status.label)
          .style({
            alignment: {
              horizontal: 'center'
            },
            fill: {
              type: 'pattern',
              patternType: 'solid',
              fgColor: status.reportColor.substr(1)
            }
          });
        ws.cell(c, 5)
          .string(priority.label)
          .style({
            alignment: {
              horizontal: 'center'
            },
            fill: {
              type: 'pattern',
              patternType: 'solid',
              fgColor: priority.reportColor.substr(1)
            }
          });
        if (y % 2 === 1) {
          ws.cell(c, 6)
            .string(this.userName(parseInt(item.assigned_user)))
            .style({
              fill: {
                type: 'pattern',
                patternType: 'solid',
                fgColor: 'D9D9D9'
              }
            });
        } else {
          ws.cell(c, 6)
            .string(this.userName(parseInt(item.assigned_user)))
            .style({
              fill: {
                type: 'pattern',
                patternType: 'solid',
                fgColor: 'FFFFFF'
              }
            });
        }
        ws.cell(c, 7).string(this.userName(parseInt(item.user_id)));
        c++;
      }

      c += 2;
      // Print generation report
      ws.cell(c, cols.B).string('Report info');
      ws.cell(c, cols.C).string(
        parseTimeStamp(Date.now() / 1000, 'shortExcel')
      );
      ws.cell(c, cols.D).string('Aerworx ' + process.env.VUE_APP_VERSION);
      // build report pages

      wb.writeToBuffer().then((buffer) => {
        exportFile(`ToDo-${Math.floor(Date.now() / 1000)}.xlsx`, buffer);
      });
    },
    mapStatusColor(status, opt) {
      const result = this.statusOptions.find((e) => e.label === status);
      if (opt && opt.text) {
        return result ? ` ${result.text}` : 'grey';
      } else if (opt && opt.icon) {
        return result ? `text-${result.color}` : 'grey';
      } else {
        return result ? `bg-${result.color} ${result.text}` : 'grey';
      }
    },
    mapPriorityColor(priority, opt) {
      const result = this.priorityOptions.find((e) => e.label === priority);
      if (opt && opt.text) {
        return result ? ` ${result.text}` : 'grey';
      } else if (opt && opt.icon) {
        return result ? `text-${result.color}` : 'grey';
      } else {
        return result ? `bg-${result.color} ${result.text}` : 'grey';
      }
    },
    farmName(id) {
      if (id === null) {
        return '--';
      }
      const farm = this.$store.state.farmList.find(
        (element) => element.id === id
      );
      return farm ? decoder(farm.farm_name) : '---';
    },
    farmNameOnly(id) {
      const name = this.farmName(id);
      return name.startsWith('--') ? '' : name;
    },
    userName(id) {
      if (id === null) {
        return '--';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '- - - -';
    },
    parseTimeStamp(value) {
      return parseTimeStamp(value, 'short');
    },
    parseCommentTime(value) {
      if (value && !isNaN(value)) {
        return new Date(value).toLocaleString('en-US', {
          timeStyle: 'short',
          dateStyle: 'short'
        });
      } else {
        return '--';
      }
    },
    decoder(val) {
      return decoder(val);
    },
    showChatClick(row) {
      this.selectedTodo = row;
      this.showChat = true;
    },
    showNoteClick(todo) {
      this.selectedTodo = todo;
      this.showNote = true;
    },
    cancelEdit() {
      this.showEditModal = false;
    },
    onSubmit() {
      let dispatch;
      if (this.editTodoCopy.note === '') {
        this.$q.notify({
          message: 'Notes cannot be blank',
          color: 'negative',
          timeout: 500
        });
        return;
      }

      if (this.editTodoCopy.id === 'new') {
        dispatch = 'createTodo';
      } else {
        dispatch = 'updateTodo';
      }

      store
        .dispatch(dispatch, {
          todo: this.editTodoCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit(dispatch);
            }
          });
        });

      this.showEditModal = false;
    },
    addTodo() {
      this.editTodoCopy = this.NewTodo();
      this.showEditModal = true;
    },
    editTodo(todo) {
      this.farmSearch = '';
      this.editTodoCopy = JSON.parse(JSON.stringify(todo));
      this.recipErr = false;
      this.backDateErr = false;
      this.showEditModal = true;
    },
    changeOptDispatch(todo) {
      store
        .dispatch('updateTodo', {
          todo
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.changeOptDispatch(todo);
            }
          });
        });
    },
    changeStatus(todo, value) {
      const updatedTodo = JSON.parse(JSON.stringify(todo));
      updatedTodo.status = value;
      this.changeOptDispatch(updatedTodo);
    },
    changePriority(todo, value) {
      const updatedTodo = JSON.parse(JSON.stringify(todo));
      updatedTodo.priority = value;
      this.changeOptDispatch(updatedTodo);
    },
    deleteTodo(todo) {
      this.$deleteResult
        .getResultsAsync(
          'history',
          'Todo @ ' + parseTimeStamp(todo.date, 'short'),
          'Delete todo?'
        )
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(todo);
          }
        });
    },
    dispatchDelete(todo) {
      store
        .dispatch('deleteTodo', {
          todo
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(todo);
            }
          });
        });
    },
    subjectLine(todo) {
      const maxLength = 128;
      if (todo.length <= maxLength) {
        return decoder(todo);
      }
      let split = todo.split(/[,:\r\n]/);
      todo = split[0];
      if (todo.length <= maxLength) {
        return decoder(todo) + ' ...';
      }
      split = todo.split(' ');
      todo = '';
      for (let i = 0; i < split.length; i++) {
        if (todo.length + split[i].length + 1 > maxLength) {
          break;
        }
        if (i > 0) {
          todo += ' ';
        }
        todo += split[i];
      }
      if (todo.length <= maxLength) {
        return decoder(todo) + ' ...';
      }
      return decoder(todo.substring(0, maxLength) + ' ...');
    },
    NewTodo() {
      this.farmSearch = '';
      return {
        id: 'new',
        farm_id: this.farmOnly ? parseInt(this.farmId) : null,
        user_id: this.$store.state.user.user_id,
        assigned_user: null,
        note: '',
        chats: [],
        comments: [],
        priority: this.priorityOptions[0].label,
        status: this.statusOptions[0].label,
        date: Date.now() / 1000,
        reminders: []
      };
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.user_id;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    chatGroupAll() {
      const userList = JSON.parse(JSON.stringify(this.userList));
      const all = userList
        .filter((x) => x.chatAuth)
        .map((x) => {
          x.label = trimName(x.label);
          return x;
        });
      return all;
    },
    isAuthForChat() {
      return this.chatGroupAll.some((x) => x.value === this.currUserId);
    },
    FarmOptions() {
      const ret = [];
      ret.push({
        label: '---',
        value: null,
        name: '---'
      });
      let farmList;
      if (this.$store.getters.isSuperAdmin) {
        farmList = this.$store.state.farmList;
      } else {
        farmList = this.$store.state.farmList.filter((e) => {
          return this.$store.state.user.farms.find((f) => f === e.id);
        });
      }
      for (let i = 0; i < farmList.length; i++) {
        ret.push({
          label: decoder(farmList[i].farm_name),
          value: farmList[i].id,
          name: farmList[i].farm_name
        });
      }
      if (this.farmSearch !== '') {
        const search = this.farmSearch.toLowerCase();
        return ret.filter((e) => {
          return e.name.toLowerCase().includes(search) || e.value === null;
        });
      }
      return ret;
    },
    Todos() {
      const Completed = [];
      const NotCompleted = [];
      let searchList;
      if (this.farmOnly) {
        searchList = this.$store.state.todos.filter(
          (e) => e.farm_id === parseInt(this.farmId)
        );
      } else if (this.$store.getters.isSuperAdmin) {
        searchList = this.$store.state.todos;
      } else {
        searchList = this.$store.state.todos.filter((e) => {
          return (
            e.assigned_user === this.$store.state.user.user_id ||
            e.user_id === this.$store.state.user.user_id ||
            this.$store.state.user.farms.find((f) => f === e.farm_id)
          );
        });
      }
      if (this.filter.selfAssigned) {
        searchList = searchList.filter(
          (e) => e.assigned_user === this.$store.state.user.user_id
        );
      }

      searchList.forEach((todo) => {
        let lastCommentTime = 0;
        if (todo.comments.length) {
          const lastComment = todo.comments[todo.comments.length - 1];
          if (Object.prototype.hasOwnProperty.call(lastComment, 'time')) {
            lastCommentTime = lastComment.time;
          }
        }
        todo.lastCommentTime = lastCommentTime;

        const chatInfoObj = chatInfo(todo.chats, this.currUserId);

        todo.latestMsgTs = chatInfoObj.latestMsgTs;
        todo.numUnread = chatInfoObj.numUnread;
        todo.numStarred = chatInfoObj.numStarred;
        todo.numImportant = chatInfoObj.numUnread + chatInfoObj.numStarred;
        todo.hasChat =
          chatInfoObj.isPartOfChat ||
          (todo.chats.length > 0 && this.isSuperAdmin);
        todo.isUser = todo.user_id === this.currUserId;
        todo.reminderStatus = this.reminderStatus(todo.reminders);

        if (todo.status === 'Complete') {
          Completed.push(JSON.parse(JSON.stringify(todo)));
        } else {
          NotCompleted.push(JSON.parse(JSON.stringify(todo)));
        }
      });

      Completed.sort((a, b) => a.date - b.date);
      NotCompleted.sort((a, b) => {
        if (a.priority !== b.priority) {
          return (
            this.priorityOptions.findIndex((e) => e.value === b.priority) -
            this.priorityOptions.findIndex((e) => e.value === a.priority)
          );
        } else if (a.status !== b.status) {
          return (
            this.statusOptions.findIndex((e) => e.value === a.status) -
            this.statusOptions.findIndex((e) => e.value === b.status)
          );
        } else {
          return b.date - a.date;
        }
      });

      return NotCompleted.concat(Completed);
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id,
          chatAuth: this.$store.state.userList[i].chatAuth,
          userRole: this.$store.state.userList[i].user_role
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return list;
    },
    userListAssigned() {
      const userList = this.userList.slice();

      userList.push({
        label: '',
        value: null
      });

      return userList;
    },
    reminderUserList() {
      const reminderRecipients = this.editTodoCopy.reminders.map(
        (x) => x.reminderRecipient
      );
      return this.userList.filter((x) => !reminderRecipients.includes(x.value));
    },
    remindersFiltered() {
      return this.editTodoCopy.reminders.filter(
        (x) => x.reminderRecipient === this.currUserId || this.isSuperAdmin
      );
    },
    ModalHeader() {
      return this.editTodoCopy.id === 'new' ? 'New ToDo' : 'Edit ToDo';
    },
    itemDate: {
      get() {
        return date.formatDate(this.editTodoCopy.date * 1000, 'YYYY-MM-DD');
      },
      set(value) {
        const d = new Date(value);
        this.editTodoCopy.date =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    }
  },
  watch: {
    '$store.state.todos'() {
      if (this.selectedTodo) {
        const selectedTodo = this.$store.state.todos.find(
          (e) => e.id === this.selectedTodo.id
        );

        const chatInfoObj = chatInfo(selectedTodo.chats, this.currUserId);

        selectedTodo.numUnread = chatInfoObj.numUnread;
        selectedTodo.numStarred = chatInfoObj.numStarred;
        selectedTodo.numImportant =
          chatInfoObj.numUnread + chatInfoObj.numStarred;
        selectedTodo.latestMsgTs = chatInfoObj.latestMsgTs;
        selectedTodo.hasChat =
          chatInfoObj.hasChat ||
          (selectedTodo.chats.length && this.isSuperAdmin);
        selectedTodo.isUser = selectedTodo.user_id === this.currUserId;

        this.selectedTodo = JSON.parse(JSON.stringify(selectedTodo));
      }
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 750px) {
  .spaced {
    margin-left: 2px;
    margin-right: 2px;
  }

  table {
    width: 80%;
  }
}

.max-width {
  max-width: 100px;
}

.time-font {
  font-size: 10px;
}
</style>
