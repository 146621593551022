<template>
  <div>
    <q-card flat unelevated>
      <div class="row justify-end q-pa-sm">
        <q-btn
          :outline="sortType !== 'pond'"
          icon="swap_vert"
          label="Pond"
          color="primary"
          size="sm"
          class="q-mr-sm"
          @click="(sortType = 'pond'), setEmails()"
        />
        <q-btn
          :outline="sortType !== 'date'"
          icon="swap_vert"
          label="Date"
          color="primary"
          size="sm"
          @click="(sortType = 'date'), setEmails()"
        />
      </div>
      <q-separator />

      <div class="scroll" :style="`height: ${store.state.pageHeight - 137}px`">
        <!-- Larger screens -->
        <div v-if="$q.screen.gt.md" class="q-px-md q-pt-xs q-pb-lg">
          <div
            v-if="emails.length === 0"
            class="row flex-center text-h4 text-blue-grey-6 q-mt-xl"
          >
            <div class="col-12 text-center q-mb-lg">No Emails</div>
            <q-icon name="mark_email_read" color="blue-grey-6" size="70px" />
          </div>

          <div
            v-for="(email, i) in emails"
            :key="email.email_id + 'emailKey' + i"
          >
            <div class="row full-width q-py-sm">
              <div class="col-2 row items-start">
                <div class="row items-center">
                  <div class="col q-pl-xs cursor-pointer">
                    <span>
                      {{ farmName(email.farm_id) }}
                    </span>
                  </div>
                </div>
              </div>

              <span v-if="email.locationCount > 1" class="q-pl-sm text-blue-14">
                ( {{ email.webfolder }} )
              </span>

              <div class="col items-center q-pl-sm">
                <div class="col items-center q-pl-sm">
                  <div
                    v-for="(alert, i) in email.emailBody"
                    :key="alert.msg + email.email_id + i"
                  >
                    {{ alert.pond }} -
                    {{ alert.msg }}
                  </div>
                </div>
              </div>

              <div>
                <div class="col-auto row justify-end items-center q-pl-md">
                  <div class="q-pr-sm">
                    <q-btn
                      v-if="email.numTwoDayDuplicates > 1"
                      unelevated
                      :label="email.numTwoDayDuplicates"
                      color="pink-5"
                      text-color="black"
                      padding="0px sm"
                      style="width: 32px"
                      size="md"
                      class="q-mx-xs"
                      @click="showDuplicates(email)"
                    />
                  </div>

                  <div
                    class="q-pr-xs text-caption text-cyan-6"
                    style="min-width: 164px"
                  >
                    {{ email.formattedDate }}
                  </div>

                  <div class="col-auto" style="width: 48px">
                    <q-btn
                      v-if="email.multiDays"
                      unelevated
                      :label="email.multiDaysEmails.length"
                      color="amber-14"
                      text-color="black"
                      padding="1px 6px"
                      style="width: 32px"
                      size="md"
                      class="q-mx-sm"
                      @click="showMultiDays(email)"
                    />
                  </div>

                  <div>
                    <q-btn
                      v-if="email.hasWorkLog"
                      unelevated
                      label="Work Log"
                      icon="assignment"
                      color="cyan-6"
                      text-color="black"
                      padding="xs sm"
                      size="sm"
                      class="q-mr-sm"
                    >
                      <q-badge
                        v-if="email.numWorkLogs > 1"
                        rounded
                        color="pink-6"
                        floating
                        >{{ email.numWorkLogs }}</q-badge
                      >
                      <q-menu>
                        <q-list style="min-width: 100px">
                          <q-item
                            clickable
                            v-close-popup
                            @click="addLog(email)"
                          >
                            <div class="row items-center">
                              <div class="q-pr-sm">
                                <q-icon name="add" color="black" size="18px" />
                              </div>
                              <div>Add Worklog</div>
                            </div>
                          </q-item>
                          <q-separator />
                          <q-item
                            clickable
                            v-close-popup
                            @click="workLogAction(email, 'screenshot')"
                          >
                            <div class="row items-center">
                              <div class="q-pr-sm">
                                <q-icon
                                  name="add_a_photo"
                                  color="black"
                                  size="18px"
                                />
                              </div>
                              <div>Add Screenshot</div>
                            </div>
                          </q-item>
                          <q-separator />
                          <q-item
                            clickable
                            v-close-popup
                            @click="workLogAction(email, 'view')"
                          >
                            <div class="row items-center">
                              <div class="q-pr-sm">
                                <q-icon
                                  name="visibility"
                                  color="black"
                                  size="18px"
                                />
                              </div>
                              <div>View Worklog</div>
                            </div>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                    <q-btn
                      v-if="!email.hasWorkLog"
                      outline
                      label="Work Log"
                      icon="content_paste"
                      color="cyan-6"
                      text-color="cyan-6"
                      padding="xs sm"
                      size="sm"
                      class="q-mr-sm"
                      @click="addLog(email)"
                    />
                    <q-btn
                      outline
                      label="Home"
                      color="purple"
                      padding="xs sm"
                      size="sm"
                      class="q-mr-sm"
                      @click="openInNewTab(email.homeUrl)"
                    />
                    <q-btn
                      outline
                      label="Buoy"
                      color="purple"
                      padding="xs sm"
                      size="sm"
                      class="q-mr-sm"
                      @click="openInNewTab(email.buoyUrl)"
                    />
                    <q-btn
                      outline
                      label="Config"
                      color="purple"
                      padding="xs sm"
                      size="sm"
                      @click="openInNewTab(email.configUrl)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <q-separator />
          </div>
        </div>

        <!-- Smaller screens -->
        <div v-if="$q.screen.lt.lg" class="q-px-md q-pt-xs q-pb-lg">
          <div
            v-if="emails.length === 0"
            class="row flex-center text-h4 text-blue-grey-6 q-mt-xl"
          >
            <div class="col-12 text-center q-mb-lg">No Emails</div>
            <q-icon name="mark_email_read" color="blue-grey-6" size="70px" />
          </div>
          <div v-for="email in emails" :key="email.email_id">
            <div class="row full-width q-pt-sm">
              <div class="col-12 row">
                <div class="col row items-center">
                  <div
                    class="col cursor-pointer"
                    @click="goToFarm(email, 'emails')"
                  >
                    <span
                      :style="
                        'border-bottom: solid 1px ' +
                        ($q.dark.isActive ? 'white' : 'black')
                      "
                    >
                      {{ farmName(email.farm_id) }}
                    </span>
                  </div>
                </div>

                <div class="col-auto row justify-end q-pl-xs">
                  <div>
                    <span v-if="email.hasWorkLog"> </span>

                    <q-btn
                      v-if="email.numTwoDayDuplicates > 1"
                      unelevated
                      :label="email.numTwoDayDuplicates"
                      color="pink-5"
                      text-color="black"
                      padding="0px sm"
                      style="width: 26px"
                      size="md"
                      class="q-mx-xs"
                      @click="showDuplicates(email)"
                    />
                    <q-btn
                      v-if="email.multiDays"
                      unelevated
                      :label="email.multiDaysEmails.length"
                      color="amber-14"
                      text-color="black"
                      padding="0px sm"
                      style="width: 26px"
                      size="md"
                      class="q-mx-xs"
                      @click="showMultiDays(email)"
                    />

                    <q-btn
                      v-if="email.hasWorkLog"
                      unelevated
                      icon="assignment"
                      color="cyan-6"
                      text-color="black"
                      padding="4px 5px"
                      style="width: 26px"
                      size="sm"
                      class="q-mx-xs"
                    >
                      <q-badge
                        v-if="email.numWorkLogs > 1"
                        rounded
                        color="pink-6"
                        floating
                        >{{ email.numWorkLogs }}</q-badge
                      >
                      <q-menu>
                        <q-list style="min-width: 100px">
                          <q-item
                            clickable
                            v-close-popup
                            @click="addLog(email)"
                          >
                            <div class="row items-center">
                              <div class="q-pr-sm">
                                <q-icon name="add" color="black" size="18px" />
                              </div>
                              <div>Add Worklog</div>
                            </div>
                          </q-item>
                          <q-separator />
                          <q-item
                            clickable
                            v-close-popup
                            @click="workLogAction(email, 'screenshot')"
                          >
                            <div class="row items-center">
                              <div class="q-pr-sm">
                                <q-icon
                                  name="add_a_photo"
                                  color="black"
                                  size="18px"
                                />
                              </div>
                              <div>Add Screenshot</div>
                            </div>
                          </q-item>
                          <q-separator />
                          <q-item
                            clickable
                            v-close-popup
                            @click="workLogAction(email, 'view')"
                          >
                            <div class="row items-center">
                              <div class="q-pr-sm">
                                <q-icon
                                  name="visibility"
                                  color="black"
                                  size="18px"
                                />
                              </div>
                              <div>View Worklog</div>
                            </div>
                          </q-item>
                        </q-list>
                      </q-menu>
                    </q-btn>
                    <q-btn
                      v-if="!email.hasWorkLog"
                      outline
                      icon="content_paste"
                      color="cyan-6"
                      text-color="cyan-6"
                      padding="3px 5px"
                      style="width: 26px"
                      size="sm"
                      class="q-mx-xs"
                      @click="addLog(email)"
                    >
                      <div class="full-width" style="padding-top: 1px"></div>
                    </q-btn>
                  </div>
                </div>
              </div>

              <div
                v-if="email.locationCount > 1"
                class="col-12 text-blue-14 q-pt-xs"
              >
                ( {{ email.webfolder }} )
              </div>

              <div
                class="col-12 q-pt-sm"
                :class="$q.screen.lt.lg ? 'q-pb-sm' : ''"
              >
                <div
                  v-for="(alert, i) in email.emailBody"
                  :key="alert.msg + email.email_id + i"
                >
                  {{ alert.pond }} -
                  {{ alert.msg }}
                </div>
              </div>

              <div class="col-12 row items-end text-caption text-cyan-6">
                <div class="q-pb-sm">
                  <q-btn
                    outline
                    label="Home"
                    color="purple"
                    size="sm"
                    padding="3px 5px"
                    class="q-mr-sm"
                    @click="openInNewTab(email.homeUrl)"
                  />
                  <q-btn
                    outline
                    label="Buoy"
                    color="purple"
                    padding="3px 5px"
                    size="sm"
                    class="q-mr-sm"
                    @click="openInNewTab(email.buoyUrl)"
                  />
                  <q-btn
                    outline
                    label="Config"
                    color="purple"
                    padding="3px 5px"
                    size="sm"
                    @click="openInNewTab(email.configUrl)"
                  />
                </div>

                <q-space />
                <div style="padding-bottom: 3px">
                  {{ email.formattedDate }}
                </div>
              </div>
            </div>
            <q-separator />
          </div>
        </div>
      </div>
    </q-card>

    <!-- Display Duplicates Dialog -->
    <q-dialog persistent position="top" v-model="duplicatesDialog">
      <EmailDuplicates
        :duplicateEmails="duplicateEmails"
        :twoDayDuplicates="twoDayDuplicates"
        :duplicateEmailsType="duplicateEmailsType"
      />
    </q-dialog>

    <!-- edit modal -->
    <q-dialog position="top" v-model="dialogEditWorklog" persistent>
      <WorkLogEdit
        :farmId="selectedWorkLog.farm_id"
        :workLog="selectedWorkLog"
        @cancel="dialogEditWorklog = false"
      />
    </q-dialog>
  </div>
</template>

<script>
import EmailDuplicates from '@/components/emails/EmailDuplicates.vue';
import WorkLogEdit from '@/components/workLogs/WorkLogEdit';

import { date } from 'quasar';
import store from '@/store';
import { cloneObj, decoder } from '@/lib/helpers.js';
import {
  emailMsgExtractor,
  emailKeyBuilder,
  emailKeyBuilderUnique,
  splitIntoDays,
  getTwoDayDuplicates,
  getTimeDiff,
  checkForWorkLog
} from '@/lib/emailHelpers.js';
import { todoOptions } from '@/lib/static-data';

export default {
  name: 'FarmEmails',
  components: {
    EmailDuplicates,
    WorkLogEdit
  },
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      assignedTo: null,
      duplicatesDialog: false,
      duplicateEmails: [],
      duplicateEmailsType: 'all',
      dialogEditWorklog: false,
      emailMsg: '',
      emails: [],
      emailListComplete: [],
      keyword: '',
      notes: '',
      officeNotes: '',
      officeNotesComplete: '',
      selectedEmail: null,
      selectedWorkLog: {},
      sortType: 'date',
      store,
      twoDayDuplicates: [],
      todoFlag: 8
    };
  },
  mounted() {
    this.setEmails();
  },
  methods: {
    addLog(email) {
      this.todoFlag = 0;
      this.keyword = '';

      const emailMsg =
        email.emailBody.map((x) => `${x.pond} - ${x.msg}`).join(' --- ') +
        ` (${email.formattedDate})`;
      const officeNotes = `[ Email: ${emailMsg} ]`;

      this.selectedWorkLog = {
        id: 'new',
        todo_flag: 8,
        todo_complete: null,
        todo_complete_by: null,
        attachments: [],
        comments: [],
        office_comments: '',
        chats: [],
        last_chat_date: null,
        reminders: [],
        farm_id: email.farm_id,
        location_id: email.location_id,
        work_item: null,
        work_cat: 3,
        pond_id: null,
        computer_id: null,
        notes: '',
        office_notes: officeNotes,
        time: 0,
        assigned_to: null,
        date: date.formatDate(date.startOfDate(new Date(), 'day'), 'X'),
        field_values: {},
        billed: 0,
        billable: false,
        billed_changed_by: null,
        billed_changed_on: null
      };

      this.dialogEditWorklog = true;
    },
    buildToDoColor(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.color ?? '';
    },
    decodeMessage(msg) {
      return JSON.parse(msg);
    },
    farmName(farmId) {
      if (farmId === 0) {
        return 'SPAM EMAIL !!!';
      }
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(farmId)
      );
      return farm ? decoder(farm.farm_name) : '';
    },
    goToFarm(email) {
      this.$store.dispatch('setWorkLogScrollTo', email.workLogId);
      this.$router.push(`/farm/${email.farm_id}/work/`);
    },
    officeNoteInput() {
      this.officeNotesComplete = `${this.officeNotes.trim()} [ Email: ${
        this.emailMsg
      } ]`;
    },
    officeNoteKeywordInput() {
      this.keyword = this.keyword.toUpperCase();

      const notesSplitArr = this.officeNotes.split(' - ');
      const hasKeyword = notesSplitArr[0].toUpperCase() === notesSplitArr[0];
      if (hasKeyword) {
        notesSplitArr.shift();
      }

      const keywordStr = this.keyword.length ? this.keyword + ' - ' : '';
      this.officeNotes = keywordStr + notesSplitArr.join(' - ');

      this.officeNotesComplete = `${this.officeNotes.trim()} [ Email: ${
        this.emailMsg
      } ]`;
    },
    openInNewTab(url) {
      window.open(url);
    },
    setEmails() {
      const farmId = parseInt(this.farmId);
      const emailsMap = new Map();
      this.emailListComplete.splice(0);
      this.emails.splice(0);

      const dayAgo = Math.floor(
        +date.subtractFromDate(new Date(), { days: 1 }) / 1000
      );

      const emails = this.$store.state.emails.filter(
        (x) => x.farm_id === farmId
      );

      for (const email of emails) {
        const emailCopy = cloneObj(email);
        emailCopy.homeUrl = `https://farms.aercon.net/${email.webfolder}/`;
        emailCopy.buoyUrl = `https://farms.aercon.net/${email.webfolder}/BuoyInfo.html`;
        emailCopy.configUrl = `https://farms.aercon.net/${email.webfolder}/Configs.html`;

        emailCopy.formattedDate = date.formatDate(
          new Date(email.date * 1000),
          'ddd MMM DD YYYY - h:mm A'
        );

        emailCopy.msg = this.decodeMessage(email.message);
        emailCopy.emailBody = emailMsgExtractor(emailCopy.msg);

        const workLogInfo = checkForWorkLog(emailCopy, this.recentLogs);
        emailCopy.hasWorkLog = workLogInfo.numWorkLogs > 0;
        emailCopy.numWorkLogs = workLogInfo.numWorkLogs;
        emailCopy.workLogIds = workLogInfo.workLogIds;

        emailCopy.inbox = email.inbox === 1;
        emailCopy.checked = false;
        emailCopy.multiDays = false;
        emailCopy.multiDaysEmails = [];
        emailCopy.startDay = Math.floor(
          +date.startOfDate(emailCopy.date * 1000, 'day') / 1000
        );

        const emailKey = emailKeyBuilder(emailCopy);
        emailCopy.emailKey = emailKey;
        emailCopy.emailKeyUnique = emailKeyBuilderUnique(emailCopy);

        if (emailsMap.has(emailKey)) {
          emailsMap.get(emailKey).numDuplicates++;
          emailsMap.get(emailKey).duplicatesArr.push(cloneObj(emailCopy));
        } else {
          emailCopy.numDuplicates = 1;
          emailCopy.duplicatesArr = [cloneObj(emailCopy)];
          emailsMap.set(emailKey, emailCopy);
        }
        this.emailListComplete.push(emailCopy);
      }

      for (const email of emailsMap.values()) {
        if (email.numDuplicates > 1) {
          const days = splitIntoDays(email.duplicatesArr);

          if (days.length > 1) {
            // Mutates days
            getTimeDiff(days);
          }

          const twoDayDuplicates = getTwoDayDuplicates(email.duplicatesArr);
          days[0].twoDayDuplicates = twoDayDuplicates;
          days[0].numTwoDayDuplicates = twoDayDuplicates.length;

          this.emails.push(days[0]);
        } else {
          this.emails.push(email);
        }
      }

      if (this.sortType === 'date') {
        this.emails.sort((a, b) => b.date - a.date);
      } else {
        this.emails.sort((a, b) => {
          const aPonds = a.emailBody.map((x) => x.pond).join('');
          const bPonds = b.emailBody.map((x) => x.pond).join('');

          return aPonds.localeCompare(bPonds, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
      }
    },
    showDuplicates(email) {
      this.twoDayDuplicates = email.twoDayDuplicates;

      this.duplicateEmails.splice(0);

      for (const emailAll of this.emailListComplete) {
        if (emailAll.emailKey === email.emailKey) {
          const isInTwoDays = this.twoDayDuplicates.some(
            (x) => x.emailKeyUnique === emailAll.emailKeyUnique
          );

          if (!isInTwoDays) {
            this.duplicateEmails.push(emailAll);
          }
        }
      }

      this.duplicateEmailsType = 'all';
      this.duplicatesDialog = true;
    },
    showMultiDays(email) {
      this.twoDayDuplicates.splice(0);
      this.duplicateEmails = email.multiDaysEmails.slice();

      this.duplicateEmailsType = 'multiDays';
      this.duplicatesDialog = true;
    },
    takeScreenshot() {
      // emulate key press to trigger screenshot
      const event = new KeyboardEvent('keydown', {
        key: '4',
        keyCode: 52,
        ctrlKey: true,
        altKey: false,
        shiftKey: false,
        metaKey: false
      });

      document.dispatchEvent(event);
    },
    workLogAction(email, action) {
      if (email.workLogIds.length === 1) {
        email.workLogId = email.workLogIds[0];
        if (action === 'view') {
          this.goToFarm(email, 'work');
          return;
        }

        this.$store.dispatch('setScreenshotWorkLogId', email.workLogId);
        this.selectedEmail = cloneObj(email);
        this.takeScreenshot();
        return;
      }

      const items = [];
      for (const workLogId of email.workLogIds) {
        const workLog = this.recentLogs.find((x) => x.id === workLogId);
        items.push({
          label: date.formatDate(
            new Date(workLog.date * 1000),
            'ddd, MMM DD - YYYY'
          ),
          value: workLog.id
        });
      }

      this.$q
        .dialog({
          title: 'Select Worklog',
          options: {
            type: 'radio',
            model: email.workLogIds[0],
            items
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-2',
            textColor: 'primary'
          },
          ok: {
            label: 'Select',
            color: 'primary'
          },
          persistent: true
        })
        .onOk((data) => {
          email.workLogId = data;
          if (action === 'view') {
            this.goToFarm(email, 'work');
            return;
          }

          this.$store.dispatch('setScreenshotWorkLogId', email.workLogId);
          this.selectedEmail = cloneObj(email);
          this.takeScreenshot();
        });
    }
  },
  computed: {
    recentLogs() {
      return this.$store.state.workLogs;
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      list.push({
        label: 'Not Assigned',
        value: null
      });
      return list;
    }
  },
  watch: {
    '$store.state.emailWatcher'() {
      this.setEmails();
    },
    '$store.state.workLogs'() {
      this.setEmails();
    },
    '$store.state.screenshot'(newVal, oldVal) {
      if (newVal && this.selectedEmail) {
        this.goToFarm(cloneObj(this.selectedEmail), 'work');
        this.selectedEmail = null;
      }
    }
  }
};
</script>

<style scoped></style>
