<template>
  <div class="fit">
    <div class="row q-col-gutter-sm q-pa-md">
      <div class="col-xs-12 col-sm-6 col-md-4">
        <q-card>
          <div class="text-h6 q-pt-sm q-pl-sm">
            {{ html_decoder(farm.farm_name) }}
          </div>
          <div class="q-pl-sm q-pb-sm">
            <q-toggle
              :disabled="!isSuperAdmin"
              size="md"
              :value="priorityService"
              color="primary"
              dense
              keep-color
              label="Priority service"
              @input="checkChangePriority"
            />
          </div>
          <q-separator />
          <q-card-actions vertical>
            <div class="text-subtitle2">
              Contact: {{ html_decoder(farm.contact_name) }}
            </div>
            <div class="text-subtitle2">
              Phone: {{ html_decoder(farm.phone) }}
            </div>
            <div class="text-subtitle2">
              Email: {{ html_decoder(farm.email) }}
            </div>
          </q-card-actions>
        </q-card>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4">
        <q-card>
          <div class="row justify-center" style="padding: 17px 0 17px 0">
            <q-btn
              label="View In Main Map"
              color="green-14"
              text-color="black"
              size="md"
              @click="goToMainMap"
            />
          </div>
          <q-separator />
          <div class="row justify-center" style="padding: 12px">
            <div style="padding: 1px 11px 0 0">
              <q-fab color="primary" icon="campaign" direction="down">
                <q-fab-action
                  color="primary"
                  label="Add Repeater Line"
                  icon="add"
                  @click="drawLineInit"
                />
                <q-fab-action
                  color="accent"
                  label="Remove Repeater Lines"
                  icon="clear"
                  @click="drawLineRemove"
                />
              </q-fab>
            </div>
            <div style="padding: 1px 11px 0 0">
              <q-fab color="primary" icon="label" direction="down">
                <q-fab-action
                  color="primary"
                  label="Add Label"
                  icon="add"
                  @click="labelAddInit"
                />
                <q-fab-action
                  color="accent"
                  label="Remove Label"
                  icon="clear"
                  @click="removeLabelMode = true"
                />
                <q-fab-action
                  color="primary"
                  label="Set Label Color"
                  icon="palette"
                  @click="dialogSetColor = true"
                />
              </q-fab>
            </div>
            <div style="padding: 1px 11px 0 0">
              <q-btn
                v-if="showDrawManager"
                round
                icon="close"
                color="accent"
                size="19px"
                @click="saveMap"
              />
              <q-btn
                v-if="!showDrawManager"
                round
                icon="edit"
                color="primary"
                size="19px"
                @click="editMap"
              />
            </div>
            <div style="padding: 1px 0 0 0">
              <q-btn
                v-if="!lockedLocation"
                round
                icon="lock_open"
                color="accent"
                size="19px"
                @click="lockedLocation = true"
              />
              <q-btn
                v-if="lockedLocation"
                round
                icon="lock"
                color="primary"
                size="19px"
                @click="lockedLocation = false"
              />
            </div>
          </div>
        </q-card>
      </div>
    </div>

    <div class="q-px-md">
      <div id="mapCanvas" ref="mapCanvas"></div>
      <div
        v-if="labelAddInstructions || removeLabelMode || drawLine.drawMode"
        id="map-instructions"
        class="bg-white rounded-borders text-subtitle1 text-center"
      >
        <div v-if="labelAddInstructions" class="q-pt-xs text-green">
          Select Label Position On Map
          <div align="right" class="q-pt-sm">
            <q-btn
              unelevated
              label="cancel"
              color="grey-3"
              text-color="primary"
              padding="xs sm"
              class="q-mb-sm q-mr-sm"
              @click="labelAddEnd"
            />
          </div>
        </div>
        <div v-if="removeLabelMode" class="q-pt-xs text-red-14">
          Select Label To Remove
          <div align="right" class="q-pt-sm">
            <q-btn
              unelevated
              label="cancel"
              color="grey-3"
              text-color="primary"
              padding="xs sm"
              class="q-mb-sm q-mr-sm"
              @click="removeLabelMode = false"
            />
          </div>
        </div>
        <div v-if="drawLine.drawMode" class="q-pt-xs text-primary">
          Draw Repeater Line
          <div align="right" class="q-pt-sm">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-3"
              text-color="primary"
              padding="xs sm"
              class="q-mb-sm q-mr-sm"
              @click="drawLineCancel"
            />
          </div>
        </div>
      </div>
    </div>

    <!--Color Dialog-->
    <q-dialog persistent position="top" v-model="dialogSetColor">
      <q-card class="q-pa-md" style="width: 400px">
        <div class="text-center text-h6 text-primary">Set Color</div>
        <q-separator />
        <div class="row justify-center q-py-xs">
          <q-btn
            v-for="color in qColors"
            :key="color"
            label="Select"
            :style="`background-color: ${color}`"
            class="q-ma-xs"
            @click="setLabelColor(color)"
          />
        </div>
        <q-separator />
        <div align="right" class="q-pt-md">
          <q-btn label="Done" color="primary" @click="dialogSetColor = false" />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import store from '@/store';
import { decoder, SplitGps } from '@/lib/helpers';
import { qColors } from '@/lib/static-data';
import { parseTimeStamp } from '@/lib/date-utils';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';

// noinspection JSCheckFunctionSignatures
export default {
  name: 'Setup',
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialogSetColor: false,
      farm: {
        farm_name: '',
        contact_name: '',
        phone: '',
        email: '',
        priority_service: false,
        markup: { baseMarkup: {}, labelPositions: [], repeaterLines: [] }
      },
      farmColor: '#000000',
      farmMarkers: [],
      google: {
        advancedMarkerEl: null,
        infoWindowEl: null,
        pinEl: null
      },
      map: null,
      initialZoom: false,
      drawingManager: null,
      lockedLocation: true,
      showDrawManager: false,
      markers: [],
      markersText: [],
      throttled: false,
      removeLabelMode: false,
      repeaterLines: [],
      labelAddInstructions: false,
      mapListener: null,
      drawLine: {
        drawMode: false,
        drawingManager: null,
        drawCompleteListener: null,
        overlayComplete: null,
        drawings: []
      },
      qColors
    };
  },
  mounted() {
    this.updateFarm();
    this.loadMap();
  },
  beforeRouteLeave(to, from, next) {
    if (this.showDrawManager) {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'You have unsaved changes. Are you sure you want to leave?',
          ok: {
            label: 'Yes',
            color: 'primary'
          },
          cancel: {
            label: 'No',
            color: 'negative'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          next();
        })
        .onCancel(() => {
          next(false);
        });

      return;
    }

    next();
  },
  methods: {
    buildFarmMarker(lat, lng, isCenter) {
      const markerEl = document.createElement('div');
      const centeredInfo = isCenter
        ? '<br><span style="font-size: 10px">(centered)</span>'
        : '';
      markerEl.innerHTML = `<div class="marker-el text-black cursor-pointer rounded-borders"
        style="width: 100%; padding: 5px; background-color: ${this.farmColor}">
        <div class="bg-white rounded-borders" style="padding: 2px">${this.farm.farm_name}
        ${centeredInfo}</div></div>`;

      const marker = new this.google.advancedMarkerEl({
        map: this.map,
        position: { lat, lng },
        content: markerEl
      });

      const markerObj = {};
      markerObj.clickListener = marker.addListener('click', (event) => {
        this.labelRemove(lat, lng);
      });

      this.farmMarkers.push({ marker, markerObj });
    },
    calculateFarmCenter(farmGps) {
      const gpsLocations = [];
      for (let i = 0; i < farmGps.ponds.length; i++) {
        const loc = SplitGps(farmGps.ponds[i].gps);
        if (loc !== null) {
          gpsLocations.push(loc);
        }
      }
      for (let i = 0; i < farmGps.computers.length; i++) {
        const loc = SplitGps(farmGps.computers[i].gps);
        if (loc !== null) {
          gpsLocations.push(loc);
        }
      }
      for (let i = 0; i < farmGps.repeaters.length; i++) {
        const loc = SplitGps(farmGps.repeaters[i].gps);
        if (loc !== null) {
          gpsLocations.push(loc);
        }
      }
      if (gpsLocations.length === 0) {
        return null;
      }
      let lng = 0;
      let lat = 0;
      for (let i = 0; i < gpsLocations.length; i++) {
        lng += gpsLocations[i].lng;
        lat += gpsLocations[i].lat;
      }
      lng /= gpsLocations.length;
      lat /= gpsLocations.length;
      return {
        lat,
        lng
      };
    },
    changePosition(location, newPosition) {
      const coords = `${newPosition.lat()},${newPosition.lng()}`;
      if (location.type === 'pond') {
        const pond = JSON.parse(
          JSON.stringify(
            this.$store.state.farm.ponds.find(
              (element) => element.id === location.id
            )
          )
        );
        if (pond) {
          pond.gpsFlag = true;
          pond.gps = coords;
          store
            .dispatch('updatePond', {
              farmId: this.farmId,
              pond
            })
            .then((results) => {
              this.$finishResult
                .handleResultsAsync(results)
                .then((response) => {
                  if (response === 'retry') {
                    this.changePosition(location, newPosition);
                  } else {
                    this.throttle(this.loadMapMarkers);
                  }
                });
            });
        }
      } else if (location.type === 'computer') {
        const computer = JSON.parse(
          JSON.stringify(
            this.$store.state.farm.computers.find(
              (element) => element.id === location.id
            )
          )
        );
        if (computer) {
          computer.gpsFlag = true;
          computer.gps = coords;
          store
            .dispatch('updateComputer', {
              farmId: this.farmId,
              computer
            })
            .then((results) => {
              this.$finishResult
                .handleResultsAsync(results)
                .then((response) => {
                  if (response === 'retry') {
                    this.changePosition(location, newPosition);
                  } else {
                    this.throttle(this.loadMapMarkers);
                  }
                });
            });
        }
      } else if (location.type === 'repeater') {
        const repeater = JSON.parse(
          JSON.stringify(
            this.$store.state.farm.repeaters.find(
              (element) => element.id === location.id
            )
          )
        );
        if (repeater) {
          repeater.gpsFlag = true;
          repeater.gps = coords;
          store
            .dispatch('updateRepeater', {
              farmId: this.farmId,
              repeater
            })
            .then((results) => {
              this.$finishResult
                .handleResultsAsync(results)
                .then((response) => {
                  if (response === 'retry') {
                    this.changePosition(location, newPosition);
                  } else {
                    this.throttle(this.loadMapMarkers);
                  }
                });
            });
        }
      }
    },
    checkChangePriority(value) {
      if (!this.isSuperAdmin) {
        return;
      }
      this.$q
        .dialog({
          title: 'Priority service',
          message: value
            ? 'Enable priority service ?'
            : 'Disable priority service?',
          ok: {
            label: value ? 'Enable' : 'Disable',
            color: 'primary'
          },
          cancel: true,
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.dispatchPriority(value);
        });
    },
    deleteFeature(event) {
      if (this.showDrawManager) {
        this.$q
          .dialog({
            title: 'Confirm delete',
            message: 'Delete feature?',
            ok: {
              icon: 'delete',
              label: 'Delete',
              color: 'negative'
            },
            cancel: true,
            focus: 'none',
            persistent: true
          })
          .onOk(() => {
            this.map.data.remove(event.feature);
          });
      }
    },
    dispatchPriority(value) {
      store
        .dispatch('setFarmPriority', {
          value,
          farmId: this.farmId
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchPriority(value);
            }
          });
        });
    },
    drawLineCancel() {
      this.drawLine.drawMode = false;
      if (this.drawLine.drawingManager) {
        this.drawLine.drawingManager.setDrawingMode(null);
        window.google.maps.event.removeListener(
          this.drawLine.drawCompleteListener
        );
        this.drawLine.drawingManager.setMap(null);
      }
    },
    drawLineInit() {
      this.drawLine.drawMode = true;

      this.drawLine.drawingManager =
        new window.google.maps.drawing.DrawingManager({
          drawingMode: 'polyline',
          drawingControlOptions: {
            drawingModes: ['polyline']
          },
          map: this.map,
          polylineOptions: {
            strokeWeight: 10,
            strokeColor: this.farmColor
          }
        });

      this.drawLine.drawingManager.setMap(this.map);

      this.drawLine.overlayComplete = window.google.maps.event.addListener(
        this.drawLine.drawingManager,
        'overlaycomplete',
        (line) => {
          this.drawLine.drawings.push(line.overlay);
        }
      );

      this.drawLine.drawCompleteListener = window.google.maps.event.addListener(
        this.drawLine.drawingManager,
        'polylinecomplete',
        (line) => {
          this.drawLineSave(line.getPath().getArray());

          window.google.maps.event.removeListener(
            this.drawLine.drawCompleteListener
          );
        }
      );
    },
    drawLineRemove() {
      this.repeaterLinesRemove();
      this.drawLineSave([]);
    },
    drawLineSave(lineArr) {
      this.drawLineCancel();
      let lines = [];
      if (lineArr.length > 0) {
        const line = {
          start: { lat: lineArr[0].lat(), lng: lineArr[0].lng() },
          end: { lat: lineArr[1].lat(), lng: lineArr[1].lng() }
        };

        lines = this.farm.markup.repeaterLines;
        lines.push(line);
      }

      store
        .dispatch('updateRepeaterLines', {
          farmId: this.farmId,
          lines
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.drawLineSave(lineArr);
            } else {
              this.throttle(this.loadMapMarkers);
            }
          });
        });
    },
    editMap() {
      this.showDrawManager = true;
      this.map.data.setControls(['Point', 'Polygon', 'LineString']);
      this.map.data.setStyle({
        editable: true,
        draggable: true,
        fillColor: 'blue',
        strokeWeight: 2,
        strokeColor: '#EEE'
      });
    },
    goToMainMap() {
      this.labelsHide();
      this.$store.dispatch('goToFarmOnMap', this.farmId);
      this.$router.push(`/map/`);
    },
    html_decoder(value) {
      return decoder(value);
    },
    labelAdd(latLng) {
      this.labelAddInstructions = false;
      const loc = { lat: latLng.lat(), lng: latLng.lng(), add: true };

      store
        .dispatch('updateFarmLabel', {
          farmId: this.farmId,
          loc
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.labelAdd(latLng);
            } else {
              this.throttle(this.loadMapMarkers);
            }
          });
        });
    },
    labelAddInit() {
      this.mapListener = this.map.addListener('click', (event) => {
        window.google.maps.event.removeListener(this.mapListener);
        this.labelAdd(event.latLng);
      });
      this.labelAddInstructions = true;
    },
    labelAddEnd() {
      window.google.maps.event.removeListener(this.mapListener);
      this.labelAddInstructions = false;
    },
    labelRemove(lat, lng) {
      if (!this.removeLabelMode) {
        return;
      }

      const loc = {
        lat,
        lng,
        add: false
      };

      store
        .dispatch('updateFarmLabel', {
          farmId: this.farmId,
          loc
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.labelRemove(lat, lng);
            } else {
              this.removeLabelMode = false;
              this.throttle(this.loadMapMarkers);
            }
          });
        });
    },
    labelsDisplay() {
      if (!this.farm.markup.labelPositions) {
        return;
      }

      const labelPositions = this.farm.markup.labelPositions;
      if (labelPositions.length === 0) {
        const center = this.calculateFarmCenter(this.farm.gps);
        if (!center) {
          return;
        }
        this.buildFarmMarker(center.lat, center.lng, true);
      }

      for (let i = 0; i < labelPositions.length; i++) {
        const lat = labelPositions[i].lat;
        const lng = labelPositions[i].lng;

        this.buildFarmMarker(lat, lng, false);
      }
    },
    labelsHide() {
      for (let i = 0; i < this.farmMarkers.length; i++) {
        window.google.maps.event.removeListener(
          this.farmMarkers[i].markerObj.clickListener
        );
        this.farmMarkers[i].marker.map = null;
      }
      this.farmMarkers.splice(0);
    },
    async loadMap() {
      const { Map, InfoWindow } = await window.google.maps.importLibrary(
        'maps'
      );
      const { AdvancedMarkerElement, PinElement } =
        await window.google.maps.importLibrary('marker');
      this.google.advancedMarkerEl = AdvancedMarkerElement;
      this.google.infoWindowEl = InfoWindow;
      this.google.pinEl = PinElement;

      if (window.google && window.google.maps) {
        const center = SplitGps('32.583486, -87.511146');
        const mapOptions = {
          center: new window.google.maps.LatLng(center.lat, center.lng),
          zoom: 8,
          mapTypeId: window.google.maps.MapTypeId.SATELLITE,
          gestureHandling: 'greedy',
          mapId: 'AERWORX_MAP_ID'
        };
        this.map = new window.google.maps.Map(this.$refs.mapCanvas, mapOptions);
        this.map.data.addListener('click', this.deleteFeature);
        this.map.data.setStyle({
          fillColor: 'blue',
          strokeWeight: 2,
          strokeColor: '#EEE'
        });

        this.throttle(this.loadMapMarkers);
      } else {
        setTimeout(this.loadMap, 250);
      }
    },
    loadMapMarkers() {
      if (!this.farm.gps) {
        this.throttle(this.loadMapMarkers);
        return;
      }

      this.removeMapMarkers();
      this.labelsHide();
      this.labelsDisplay();
      this.repeaterLinesDisplay();

      const Locations = [];
      const bound = new window.google.maps.LatLngBounds();
      for (let a = 0; a < this.$store.state.farm.ponds.length; a++) {
        const pond = this.$store.state.farm.ponds[a];
        const gps = SplitGps(pond.gps);
        if (gps !== null) {
          Locations.push({
            center: new window.google.maps.LatLng(gps.lat, gps.lng),
            name: pond.name,
            icon: pond.hb20 ? 'hb' : 'monitor',
            type: 'pond',
            id: pond.id
          });
          bound.extend(new window.google.maps.LatLng(gps.lat, gps.lng));
        } else {
          Locations.push({
            center: null,
            name: pond.name,
            icon: pond.hb20 ? 'hb' : 'monitor',
            type: 'pond',
            id: pond.id
          });
        }
      }
      for (let i = 0; i < this.$store.state.farm.computers.length; i++) {
        const computer = this.$store.state.farm.computers[i];
        const gps = SplitGps(computer.gps);
        if (gps !== null) {
          Locations.push({
            center: new window.google.maps.LatLng(gps.lat, gps.lng),
            name: computer.name,
            icon: 'computer',
            type: 'computer',
            id: computer.id,
            radioChannel: computer.radio_channel,
            vid: computer.vid
          });
          bound.extend(new window.google.maps.LatLng(gps.lat, gps.lng));
        } else {
          Locations.push({
            center: null,
            name: computer.name,
            icon: 'computer',
            type: 'computer',
            id: computer.id,
            radioChannel: computer.radio_channel,
            vid: computer.vid
          });
        }
      }
      for (let i = 0; i < this.$store.state.farm.repeaters.length; i++) {
        const repeater = this.$store.state.farm.repeaters[i];
        const gps = SplitGps(repeater.gps);
        if (gps !== null) {
          Locations.push({
            center: new window.google.maps.LatLng(gps.lat, gps.lng),
            name: repeater.name,
            icon: 'repeater',
            type: 'repeater',
            id: repeater.id,
            radioChannel: repeater.radio_channel,
            vid: repeater.vid
          });
          bound.extend(new window.google.maps.LatLng(gps.lat, gps.lng));
        } else {
          Locations.push({
            center: null,
            name: repeater.name,
            icon: 'repeater',
            type: 'repeater',
            id: repeater.id,
            radioChannel: repeater.radio_channel,
            vid: repeater.vid
          });
        }
      }
      let center_ = SplitGps(bound.getCenter().toString());
      if (center_ === null) {
        center_ = SplitGps('32.583486, -87.511146');
        const left = SplitGps('31.583486, -87.511146');
        const right = SplitGps('33.583486, -87.511146');
        bound.extend(new window.google.maps.LatLng(center_.lat, center_.lng));
        bound.extend(new window.google.maps.LatLng(left.lat, left.lng));
        bound.extend(new window.google.maps.LatLng(right.lat, right.lng));
        Locations.unshift({
          center: new window.google.maps.LatLng(center_.lat, center_.lng),
          name: 'Aercon Shop'
        });
        this.map.fitBounds(bound);
      } else if (!this.initialZoom) {
        this.initialZoom = true;
        this.map.fitBounds(bound);
      }

      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
      let newPosition = 1;
      for (let i = 0; i < Locations.length; i++) {
        if (Locations[i].center === null) {
          const ctr = bound.getCenter();
          Locations[i].center = new window.google.maps.LatLng(
            ctr.lat(),
            ctr.lng() + 0.001 * newPosition++
          );
          Locations[i].name = '*' + Locations[i].name;
        }

        const icon = document.createElement('img');
        icon.src = Locations[i].icon
          ? window.location.origin +
            '/images/' +
            Locations[i].icon +
            `.png?${process.env.VUE_APP_VERSION}`
          : undefined;

        const marker = new this.google.advancedMarkerEl({
          map: this.map,
          position: Locations[i].center,
          gmpDraggable: !this.lockedLocation,
          content: icon
        });

        marker.clickListener = null;
        if (
          Locations[i].type === 'computer' ||
          Locations[i].type === 'repeater'
        ) {
          const infoWindow = new this.google.infoWindowEl({
            content: `
            <div style="color: #000">
              <div> Radio Channel: ${Locations[i].radioChannel ?? ''}</div>
              <div> VID: ${Locations[i].vid ?? ''}</div>
            </div>
          `
          });

          marker.clickListener = marker.addListener('click', () => {
            infoWindow.open({
              anchor: marker,
              map: this.map,
              shouldFocus: false
            });
          });
        }
        const label = document.createElement('div');
        label.innerHTML = Locations[i].name;
        label.style.transform = 'translate(0px, 12px)';
        label.style.color = '#FFFFFF';
        label.style.fontSize = '14px';

        const textMarker = new this.google.advancedMarkerEl({
          position: {
            lat: Locations[i].center.lat(),
            lng: Locations[i].center.lng()
          },
          map: this.map,
          content: label
        });

        marker.dragendListener = null;
        if (!this.lockedLocation) {
          marker.dragendListener = marker.addListener('dragend', (mEvent) => {
            this.changePosition(Locations[i], mEvent.latLng);
          });
        }

        this.markers.push(marker);
        this.markersText.push(textMarker);
      }

      // Add user drawn
      if (this.farm.markup.baseMarkup && this.farm.markup.baseMarkup.features) {
        this.map.data.forEach((f) => {
          this.map.data.remove(f);
        });
        this.map.data.addGeoJson(this.farm.markup.baseMarkup);
      }
    },
    removeMapMarkers() {
      this.markers.forEach((m) => {
        window.google.maps.event.removeListener(m.clickListener);
        window.google.maps.event.removeListener(m.dragendListener);
        m.setMap(null);
      });
      this.markersText.forEach((m) => {
        m.setMap(null);
      });
    },
    repeaterLinesDisplay() {
      this.repeaterLinesRemove();

      for (const line of this.farm.markup.repeaterLines) {
        const repeaterLine = new window.google.maps.Polyline({
          path: [line.start, line.end],
          geodesic: true,
          strokeColor: this.farmColor,
          strokeWeight: 10
        });

        repeaterLine.setMap(this.map);
        this.repeaterLines.push(repeaterLine);
      }
    },
    repeaterLinesRemove() {
      for (const line of this.repeaterLines) {
        line.setMap(null);
      }

      for (const line of this.drawLine.drawings) {
        line.setMap(null);
      }
    },
    saveMap() {
      this.showDrawManager = false;
      this.map.data.setDrawingMode(null);
      this.map.data.setControls(null);
      this.map.data.setStyle({
        editable: false,
        draggable: false,
        fillColor: 'blue',
        strokeWeight: 2,
        strokeColor: '#EEE'
      });
      this.map.data.toGeoJson((json) => this.saveMapDrawing(json));
    },
    saveMapDrawing(geoJson) {
      store
        .dispatch('updateFarmMarkup', {
          farmId: this.farmId,
          value: geoJson
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.saveMapDrawing(geoJson);
            } else {
              this.throttle(this.loadMapMarkers);
            }
          });
        });
    },
    setLabelColor(color) {
      store
        .dispatch('updateFarmLabelColor', {
          farmId: this.farmId,
          color
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.setLabelColor(color);
            } else {
              this.throttle(this.loadMapMarkers);
            }
          });
        });
    },
    throttle(callback) {
      if (this.throttled) {
        return;
      }

      this.throttled = true;

      setTimeout(() => {
        callback();
        this.throttled = false;
      }, 300);
    },
    updateFarm() {
      const farmIndex = this.$store.state.farmList.findIndex(
        (farm) => farm.id === parseInt(this.farmId)
      );
      const farm = this.$store.state.farmList[farmIndex];

      if (farm) {
        let refresh = false;
        if (
          JSON.stringify(farm.markup.baseMarkup) !==
          JSON.stringify(this.farm.markup.baseMarkup)
        ) {
          refresh = true;
        }
        this.farm = farm;

        let color = farm.markup.labelColor;
        if (!color) {
          let colorIndex = farmIndex % qColors.length;
          colorIndex++;
          color = qColors[colorIndex];
        }
        this.farmColor = color;

        if (refresh && this.map !== null) {
          this.throttle(this.loadMapMarkers);
        }
      }
    }
  },
  computed: {
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    priorityService: {
      get() {
        return this.farm.priority_service;
      },
      set(value) {
        this.dispatchPriority(value);
      }
    }
  },
  watch: {
    '$store.state.farmList'() {
      this.updateFarm();
    },
    '$store.state.farm.ponds'() {
      if (window.google && window.google.maps) {
        this.throttle(this.loadMapMarkers);
      }
    },
    '$store.state.farm.computers'() {
      if (window.google && window.google.maps) {
        this.throttle(this.loadMapMarkers);
      }
    },
    '$store.state.farm.repeaters'() {
      if (window.google && window.google.maps) {
        this.throttle(this.loadMapMarkers);
      }
    },
    lockedLocation() {
      this.throttle(this.loadMapMarkers);
    }
  },
  beforeDestroy() {
    this.removeMapMarkers();
  }
};
</script>

<style scoped>
#mapCanvas {
  text-align: center;
  height: calc(100vh - 300px);
  width: 100%;
  margin: auto;
}

#map-instructions {
  position: absolute;
  bottom: calc(100vh - 367px);
  left: 0;
  right: 80px;
  margin-left: auto;
  width: 250px;
}
</style>
