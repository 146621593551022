<template>
  <div>
    <div>
      <!-- QR Scanner Section -->
      <q-dialog v-model="scannerOpen" maximized>
        <div class="bg-white">
          <div
            class="row justify-center"
            :style="`height: ${$store.state.pageHeight - 308}px`"
          >
            <video
              :style="$q.screen.lt.sm ? 'width: 300px;' : 'width: 500px;'"
              id="readerVideo"
            ></video>
          </div>
          <div class="row justify-center">
            <q-btn
              label="Cancel QR"
              color="secondary"
              :style="$q.screen.lt.sm ? 'width: 300px;' : 'width: 500px;'"
              @click="cancelQR"
            />
          </div>
        </div>
      </q-dialog>
      <!-- Board Types Tabs -->
      <q-tabs
        v-model="typeId"
        align="justify"
        dense
        no-caps
        mobile-arrows
        active-color="primary"
        indicator-color="primary"
        class="text-grey-8 shadow-2"
        :inline-label="$q.screen.gt.xs"
      >
        <q-tab
          v-for="(type, index) in boardTypes"
          :key="index"
          class="board-type-tab"
          :name="type"
          :label="type"
        />
      </q-tabs>

      <!-- Action Bar -->
      <div class="q-py-md">
        <div class="row items-center justify-between">
          <!-- Zebra Print Options -->
          <div
            v-if="showPrintOpt"
            class="col-12 row items-center justify-center"
          >
            <div class="q-mr-sm">
              <q-select
                v-model="labelSelection"
                dense
                filled
                :options="['Avery 27950', 'Avery 6427']"
                style="min-width: 140px"
                label="Label Type"
              />
            </div>
            <q-btn
              outline
              :disable="!selectedZebraOnline"
              color="primary"
              icon="img:/img/ZBRA.png"
              round
              size="sm"
              class="q-mr-sm"
              @click="printSelectedZebra()"
            >
              <q-tooltip>Print to Zebra</q-tooltip>
            </q-btn>

            <q-btn
              outline
              color="primary"
              icon="print"
              round
              size="sm"
              @click="printSelected()"
            >
              <q-tooltip>Print PDF</q-tooltip>
            </q-btn>
          </div>

          <!-- Quick Jump, QR Scanner, and Add Button -->
          <div
            v-if="!showPrintOpt"
            class="col-12 row items-center justify-center"
          >
            <div class="q-mr-sm">
              <q-input
                v-model="quickJump"
                filled
                dense
                type="tel"
                placeholder="Board ID"
                @keydown.enter.prevent="jumpToBoard"
              >
                <template v-slot:append>
                  <q-btn
                    flat
                    round
                    dense
                    icon="search"
                    color="primary"
                    @click="jumpToBoard"
                  />
                </template>
              </q-input>
            </div>

            <q-btn
              color="primary"
              icon="qr_code_scanner"
              round
              size="sm"
              class="q-mr-sm"
              @click="scanQR"
            >
              <q-tooltip>Scan QR Code</q-tooltip>
            </q-btn>

            <q-btn
              color="accent"
              icon="add"
              round
              size="sm"
              @click="addBoard()"
            >
              <q-tooltip>Add New Board</q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Scrollable Content Area -->
    <div
      class="scroll overflow-auto shadow-2"
      :style="`height: calc(${$store.state.pageHeight}px - 159px)`"
      ref="boardsTableContainer"
    >
      <!-- Boards Table -->
      <q-table
        ref="boardsTable"
        square
        title="Boards"
        :data="Boards"
        :columns="$q.screen.lt.sm ? mobileColumns : columns"
        :pagination="pagination"
        row-key="id"
        :loading="$store.state.loading"
        separator="cell"
        no-data-label="No Boards"
        :dense="$q.screen.lt.md"
        :virtual-scroll="!scrollToId"
      >
        <template v-if="$q.screen.lt.sm" v-slot:body="props">
          <q-tr
            :props="props"
            :id="props.row.id"
            :class="getRowClass(props.row)"
          >
            <q-td key="ID">
              <div class="text-weight-medium">{{ props.row.id }}</div>
            </q-td>
            <q-td
              key="Note"
              class="ellipsis cursor-pointer"
              style="
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              @click="showFullNote(props.row)"
            >
              {{ decoder(props.row.note) }}
            </q-td>
            <q-td key="Rev">
              {{ decoder(props.row.rev) }}
            </q-td>
            <q-td
              key="Location"
              class="ellipsis cursor-pointer"
              style="
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              @click="showLocation(props.row.id)"
            >
              {{ boardLocation(props.row.id) }}
            </q-td>
            <q-td key="PrintOpt">
              <q-checkbox
                dense
                :value="isPrinting(props.row.id)"
                @input="setPrinting(props.row.id)"
              />
            </q-td>
            <q-td key="Actions">
              <div class="row no-wrap justify-center">
                <q-btn
                  round
                  flat
                  size="sm"
                  color="primary"
                  icon="format_list_bulleted"
                  class="q-mx-sm"
                  @click="navigateTo(props.row)"
                />
                <q-btn
                  round
                  flat
                  size="sm"
                  color="primary"
                  icon="edit"
                  class="q-mr-sm"
                  @click="editBoard(props.row)"
                />
                <q-btn
                  round
                  flat
                  size="sm"
                  color="negative"
                  icon="delete"
                  @click="deleteBoard(props.row)"
                />
              </div>
            </q-td>
          </q-tr>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:header-cell-PrintOpt="props">
          <q-th :props="props" class="text-center">
            <q-icon name="print" size="sm">
              <q-tooltip>Select for printing</q-tooltip>
            </q-icon>
          </q-th>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-PrintOpt="props">
          <q-td :props="props" :class="getRowClass(props.row)">
            <q-checkbox
              for="printing"
              dense
              :value="isPrinting(props.row.id)"
              @input="setPrinting(props.row.id)"
            />
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-ID="props">
          <q-td :props="props" :class="getRowClass(props.row)">
            <div class="text-weight-medium">{{ props.row.id }}</div>
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-Type="props">
          <q-td
            :props="props"
            :class="getRowClass(props.row)"
            class="text-center"
          >
            <q-chip
              dense
              text-color="white"
              :color="getBoardTypeColor(props.row.type)"
            >
              {{ props.row.type }}
            </q-chip>
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-Note="props">
          <q-td
            :props="props"
            :class="getRowClass(props.row)"
            class="cursor-pointer"
            @click="showFullNote(props.row)"
          >
            <div
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ decoder(props.row.note) }}
            </div>
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-Rev="props">
          <q-td :props="props" :class="getRowClass(props.row)">
            {{ decoder(props.row.rev) }}
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-Location="props">
          <q-td
            :props="props"
            :class="getRowClass(props.row)"
            class="cursor-pointer"
            @click="showLocation(props.row.id)"
          >
            <div
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ boardLocation(props.row.id) }}
            </div>
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-Actions="props">
          <q-td :props="props" :class="getRowClass(props.row)">
            <div class="row no-wrap justify-center">
              <q-btn
                flat
                round
                dense
                color="primary"
                icon="format_list_bulleted"
                @click="navigateTo(props.row)"
              >
                <q-tooltip>View Logs</q-tooltip>
              </q-btn>

              <q-btn
                flat
                round
                dense
                color="primary"
                icon="edit"
                @click="editBoard(props.row)"
              >
                <q-tooltip>Edit Board</q-tooltip>
              </q-btn>

              <q-btn
                flat
                round
                dense
                color="negative"
                icon="delete"
                @click="deleteBoard(props.row)"
              >
                <q-tooltip>Delete Board</q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </div>

    <!-- Edit Board Modal -->
    <q-dialog
      v-model="showBoardEditModal"
      persistent
      position="top"
      :maximized="$q.screen.lt.sm"
    >
      <q-card
        :style="$q.screen.lt.sm ? '' : 'width: 510px'"
        :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'"
      >
        <div
          class="row justify-center full-width text-h6 text-white bg-primary q-py-sm q-mb-md"
        >
          <span>{{ BoardModalHeader }}</span>
        </div>

        <q-form class="q-gutter-md">
          <div class="text-subtitle2 q-mb-sm">
            Board ID: {{ editBoardCopy.id }}
          </div>

          <q-select
            v-model="editBoardCopy.type"
            filled
            square
            :options="boardTypes"
            emit-value
            map-options
            label="Type"
            class="q-mb-sm"
          />

          <q-input
            filled
            square
            v-model="editBoardCopy.rev"
            label="Revision"
            class="q-mb-sm"
          />

          <q-input
            filled
            square
            v-model="editBoardCopy.note"
            label="Description"
            class="q-mb-sm"
          />

          <div class="row q-mb-sm">
            <div class="col-6 q-pr-xs">
              <q-select
                v-model="editBoardCopy.status"
                filled
                square
                :options="boardStatusOptions"
                emit-value
                map-options
                label="Status"
              />
            </div>

            <div class="col-6 q-pl-xs">
              <q-select
                v-model="editBoardCopy.location"
                filled
                square
                :options="boardLocationOptions"
                emit-value
                map-options
                label="Location"
              />
            </div>
          </div>

          <div class="row full-width">
            <q-space />
            <q-btn
              label="Cancel"
              color="grey-3"
              text-color="black"
              class="q-mr-sm"
              @click="cancelBoardEdit"
            />
            <q-btn label="Save" color="primary" @click="onSubmitBoardEdit" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- Zebra Preview -->
    <ZebraPreview
      :show="showZebraPreview"
      :printData="selectedPreview"
      @complete="closeZebraPreview"
    />

    <!-- Delete Confirmation Dialog -->
    <q-dialog v-model="showDeleteDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section class="row items-center bg-primary text-white">
          <div class="text-h6">Confirm Delete</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-md">
          <p class="text-body1">Are you sure you want to delete this board?</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="Cancel"
            color="grey-3"
            text-color="black"
            v-close-popup
          />
          <q-btn label="Delete" color="negative" @click="confirmDelete" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Full Note Dialog -->
    <q-dialog v-model="showNoteDialog">
      <q-card style="min-width: 350px">
        <q-card-section
          class="row items-center q-pb-none bg-primary text-white"
        >
          <div class="text-h6">Description</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-md">
          <div class="text-body1">{{ decoder(selectedNoteText) }}</div>
        </q-card-section>

        <q-card-section class="text-subtitle2">
          <div><strong>Board ID:</strong> {{ selectedNoteId }}</div>
          <div><strong>Revision:</strong> {{ selectedNoteRev }}</div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- Location Dialog -->
    <q-dialog v-model="showLocationDialog">
      <q-card style="min-width: 350px">
        <q-card-section
          class="row items-center q-pb-none bg-primary text-white"
        >
          <div class="text-h6">Location Details</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-md">
          <div class="text-body1">{{ selectedLocationText }}</div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';
import store from '@/store';
import Vue from 'vue';
import { exportFile } from 'quasar';
import { BoardTypes } from '@/lib/board-types';
import { buildBoardLabel3x2 } from '@/lib/zebra-labels';
import ZebraPreview from '@/components/ZebraPreview';

export default {
  name: 'Boards',
  components: {
    ZebraPreview
  },
  data() {
    return {
      typeId: 'Main White',
      labelSelection: 'Avery 6427',
      quickJump: '',
      html5QrCode: null,
      printPreview: false,
      showBoardEditModal: false,
      scannerOpen: false,
      showZebraPreview: false,
      selectedPreview: {},
      printText: '',
      printList: {},
      editBoardCopy: {},
      scrollToId: null,
      showHighlight: true,
      showDeleteDialog: false,
      boardToDelete: null,
      showNoteDialog: false,
      selectedNoteText: '',
      selectedNoteId: null,
      selectedNoteRev: '',
      showLocationDialog: false,
      selectedLocationText: '',
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 300,
        sortBy: 'ID'
      },
      mobileColumns: [
        {
          name: 'ID',
          label: 'ID',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'Note',
          label: 'Description',
          align: 'left',
          field: 'note',
          sortable: true
        },
        {
          name: 'Rev',
          label: 'Rev',
          align: 'left',
          field: 'rev'
        },
        {
          name: 'Location',
          label: 'Location',
          align: 'left',
          field: (row) => this.boardLocation(row.id)
        },
        {
          name: 'PrintOpt',
          label: '',
          align: 'center',
          classes: 'print-checkbox-cell'
        },
        {
          name: 'Actions',
          label: '',
          align: 'center'
        }
      ],
      columns: [
        {
          name: 'ID',
          label: 'ID',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'Type',
          label: 'Type',
          align: 'left',
          field: 'type',
          sortable: true
        },
        {
          name: 'Note',
          label: 'Description',
          align: 'left',
          field: 'note'
        },
        {
          name: 'Rev',
          label: 'Rev/Ver',
          align: 'left',
          field: 'rev'
        },
        {
          name: 'Location',
          label: 'Location',
          align: 'left',
          field: (row) => this.boardLocation(row.id)
        },
        {
          name: 'PrintOpt',
          label: '',
          align: 'center',
          classes: 'print-checkbox-cell'
        },
        {
          name: 'Actions',
          label: 'Actions',
          align: 'center'
        }
      ],
      boardTypes: BoardTypes
    };
  },

  mounted() {
    const tab = sessionStorage.getItem('board-tab');
    if (tab && tab !== '') {
      this.typeId = tab;
    }

    // Check if board scanner passed in a new board
    if (this.$store.state.boardPage !== '') {
      const board_id = parseInt(this.$store.state.boardPage);
      if (isNaN(board_id) || board_id < 10000 || board_id > 20000) {
        this.$q.notify({
          message: 'Scan value invalid',
          color: 'negative',
          textColor: 'black'
        });
      } else {
        this.addBoard(board_id);
      }
      this.$store.dispatch('setBoardPage', '');
    }

    if (this.$store.state.itemSearchId) {
      this.quickJump = this.$store.state.itemSearchId;
      this.$store.dispatch('setItemSearchId', { itemSearchId: null });
      this.jumpToBoard();
    }

    // Check if there's a highlighted board ID
    if (this.$store.state.highlightBoardId) {
      this.scrollToId = this.$store.state.highlightBoardId;

      // Find the correct board type tab for this board
      const board = this.$store.state.boards.find(
        (brd) => brd.id === this.scrollToId
      );

      if (board) {
        // First set the right tab
        this.typeId = board.type;

        // Give time for the tab to change and render
        setTimeout(() => {
          // Now try to scroll to the board
          this.scrollToHighlightedBoard();
        }, 300);
      }

      // Clear the highlight board ID from store
      this.$store.dispatch('setHighlightBoardId', null);
    }
  },

  beforeDestroy() {
    sessionStorage.setItem('board-tab', this.typeId);
  },

  watch: {
    scrollToId(newVal) {
      if (newVal) {
        // Reset the highlight visibility
        this.showHighlight = true;

        // Clear any previous timeout
        if (this._scrollHighlightTimer) {
          clearTimeout(this._scrollHighlightTimer);
        }

        // Set a timeout to hide the highlight after 3 seconds
        this._scrollHighlightTimer = setTimeout(() => {
          // Just hide the highlight without changing scrollToId
          this.showHighlight = false;
          this._scrollHighlightTimer = null;
        }, 4000);
      }
    },

    typeId() {
      // If we have a highlighted board ID, check if it's in the new tab
      if (this.scrollToId) {
        // Try to find the board in the current tab
        const board = this.Boards.find((brd) => brd.id === this.scrollToId);

        if (board) {
          // Reset highlight visibility when switching tabs
          this.showHighlight = true;

          // If found, scroll to it
          this.$nextTick(() => {
            this.scrollToHighlightedBoard();
          });
        }
      }
    }
  },

  computed: {
    Boards() {
      let boards = this.$store.state.boards.slice();
      return boards.filter((brd) => brd.type === this.typeId);
    },

    BoardModalHeader() {
      return this.editBoardCopy.newBoard === true ? 'New Board' : 'Edit Board';
    },

    boardLocationOptions() {
      return [
        { label: 'Inventory', value: 'inventory' },
        { label: 'Deployed', value: 'deployed' },
        { label: 'Repair', value: 'repair' }
      ];
    },

    boardStatusOptions() {
      return [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
        { label: 'Retired', value: 'retired' }
      ];
    },

    selectedZebraOnline() {
      if (this.$store.state.zebraSelection === '') {
        return false;
      }
      return this.$store.state.zebraPrinters.find((p) => {
        return p.zebraId === this.$store.state.zebraSelection;
      });
    },

    showPrintOpt() {
      return Object.values(this.printList).some((e) => e === true);
    }
  },

  methods: {
    lastLogData(board_id) {
      const logs = this.$store.state.boardLogs.filter(
        (log) => log.board_id === board_id
      );
      if (logs.length === 0) {
        return '';
      }

      const logCopy = JSON.parse(JSON.stringify(logs));
      logCopy.sort((a, b) => {
        return a.date === b.date ? b.id - a.id : b.date - a.date;
      });

      return logCopy[0].notes;
    },

    decoder(text) {
      return decoder(text);
    },

    boardLocation(board_id) {
      const logs = this.$store.state.boardLogs.filter(
        (log) => log.board_id === board_id
      );
      if (logs.length === 0) {
        return '';
      }

      const logCopy = JSON.parse(JSON.stringify(logs));
      logCopy.sort((a, b) => {
        return a.date === b.date ? b.id - a.id : b.date - a.date;
      });

      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(logCopy[0].farm_id)
      );

      if (farm) {
        const pond = farm.farmASM.find(
          (pond) => pond.id === logCopy[0].pond_id
        );
        if (pond) {
          return `${decoder(farm.farm_name)} : ${decoder(pond.name)}`;
        } else {
          return `${decoder(farm.farm_name)}`;
        }
      }
      return '';
    },

    jumpToBoard() {
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.quickJump)
      );

      this.quickJump = '';
      if (board) {
        this.$router.push(`/boards/${board.id}`);
      } else {
        this.$q.notify({
          message: 'Board not found',
          color: 'negative',
          icon: 'error'
        });
      }
    },

    async cancelQR() {
      this.scannerOpen = false;
      if (this.html5QrCode !== null) {
        await this.html5QrCode.stop();
        this.html5QrCode.destroy();
        this.html5QrCode = null;
      }
    },

    async scanQR() {
      if (this.scannerOpen) {
        return;
      }

      try {
        const QrScanner = await import('qr-scanner');
        this.scannerOpen = true;

        await this.$nextTick();

        this.html5QrCode = new QrScanner.default(
          document.getElementById('readerVideo'),
          (result) => {
            const decodedText = result.data;
            this.scannerOpen = false;
            this.html5QrCode.stop();
            this.html5QrCode.destroy();

            let foundValue = null;
            if (isNaN(parseInt(decodedText))) {
              const tail = decodedText.split('/boards/');
              foundValue = tail[1];
            } else {
              foundValue = decodedText;
            }

            if (!foundValue || isNaN(parseInt(foundValue))) {
              this.$q.notify({
                message: 'Invalid QR Code',
                color: 'negative',
                icon: 'error'
              });
            } else {
              const board_id = parseInt(foundValue);
              if (this.$store.state.boards.find((brd) => brd.id === board_id)) {
                this.$router.push(`/boards/${foundValue}`);
              } else {
                if (board_id < 10000 || board_id > 20000) {
                  this.$q.notify({
                    message: 'Board not found',
                    color: 'negative',
                    icon: 'error'
                  });
                } else {
                  this.addBoard(parseInt(foundValue));
                }
              }
              this.html5QrCode = null;
            }
          },
          {
            returnDetailedScanResult: true,
            highlightScanRegion: true,
            highlightCodeOutline: true,
            calculateScanRegion: function (video) {
              const smallestDimension = Math.min(
                video.videoWidth,
                video.videoHeight
              );
              const scanRegionSize = Math.round((1 / 2) * smallestDimension);

              return {
                x: Math.round((video.videoWidth - scanRegionSize) / 2),
                y: Math.round((video.videoHeight - scanRegionSize) / 2),
                width: scanRegionSize,
                height: scanRegionSize,
                downScaledWidth: 300,
                downScaledHeight: 300
              };
            }
          }
        );

        await this.html5QrCode.start();
      } catch (error) {
        this.$q.notify({
          message: error.toString(),
          color: 'negative',
          icon: 'error'
        });

        this.scannerOpen = false;
        if (this.html5QrCode) {
          this.html5QrCode.stop();
          this.html5QrCode.destroy();
          this.html5QrCode = null;
        }
      }
    },

    closeZebraPreview() {
      this.showZebraPreview = false;
    },

    printRowToZebra(row) {
      this.selectedPreview = {
        type: row.type,
        id: row.id,
        rev: row.rev,
        note: row.note,
        logData: this.lastLogData(row.id)
      };
      this.showZebraPreview = true;
    },

    async printSelectedZebra() {
      const zebraId = this.$store.state.zebraSelection;
      const list = this.Boards.filter((brd) => this.printList[brd.id] === true);
      if (list.length === 0) {
        this.$q.notify({
          message: 'No boards selected for printing',
          color: 'warning',
          icon: 'warning'
        });
        return;
      }

      let zpl = '';
      for (const board of list) {
        zpl += buildBoardLabel3x2(
          board.type,
          board.id,
          board.rev,
          board.note,
          this.lastLogData(board.id)
        );
      }

      store
        .dispatch('printToZebra', {
          zebraId,
          zpl
        })
        .then((results) => {
          if (results.sent === false || results.reasonCode !== 0) {
            this.$q.notify({
              message: `${results.error}`,
              color: 'negative',
              icon: 'error'
            });
          } else {
            this.$q.notify({
              message: `Sent to ${zebraId}`,
              color: 'positive',
              icon: 'check_circle'
            });
          }
        });
    },

    async printSelected() {
      // Label definitions
      const Avery6427 = {
        name: 'Avery 6427',
        orientation: 'rotate',
        size: 175,
        font: 40,
        noteFont: 30,
        rows: [
          {
            width: 4.0,
            type: 'print'
          },
          {
            width: 0.166,
            type: 'space'
          },
          {
            width: 4.0,
            type: 'print'
          }
        ],
        columns: [
          {
            height: 2.0,
            type: 'print'
          },
          {
            height: 2.0,
            type: 'print'
          },
          {
            height: 2.0,
            type: 'print'
          },
          {
            height: 2.0,
            type: 'print'
          },
          {
            height: 2.0,
            type: 'print'
          }
        ],
        margins: {
          top: 0.5,
          bottom: 0.3,
          left: 0.166,
          right: 0.166
        },
        page: {
          width: 8.5,
          height: 11.0,
          orientation: 'portrait'
        }
      };

      const Avery27950 = {
        name: 'Avery 27950',
        labelOrientation: 'normal',
        size: 200,
        font: 50,
        noteFont: 38,
        rows: [
          {
            width: 3.75,
            type: 'print'
          },
          {
            width: 0.5,
            type: 'space'
          },
          {
            width: 3.75,
            type: 'print'
          }
        ],
        columns: [
          {
            height: 5.0,
            type: 'print'
          },
          {
            height: 5.0,
            type: 'print'
          }
        ],
        margins: {
          top: 0.5,
          bottom: 0.3,
          left: 0.5,
          right: 0.5
        },
        page: {
          width: 8.5,
          height: 11.0,
          orientation: 'portrait'
        }
      };

      const list = this.Boards.filter((brd) => this.printList[brd.id] === true);
      if (list.length === 0) {
        this.$q.notify({
          message: 'No boards selected for printing',
          color: 'warning',
          icon: 'warning'
        });
        return;
      }

      // Clear print list
      Object.keys(this.printList).forEach((key) => {
        Vue.set(this.printList, key, false);
      });

      const QRCode = await import('qrcode');

      const urlList = [];
      for (const board of list) {
        urlList.push({
          type: board.type,
          id: board.id,
          note: board.note,
          rev: board.rev,
          qrcode: await QRCode.toDataURL(
            `https://${window.location.host}/boards/${board.id}`
          )
        });
      }

      urlList.sort((a, b) => a.id - b.id);

      let label = Avery6427;
      if (this.labelSelection === 'Avery 27950') {
        label = Avery27950;
      }

      await this.printToDOCX(urlList, label);
    },

    setPrinting(board_id) {
      if (this.printList[board_id]) {
        Vue.set(this.printList, board_id, false);
      } else {
        Vue.set(this.printList, board_id, true);
      }
    },

    isPrinting(board_id) {
      const board = this.$store.state.boards.find((brd) => brd.id === board_id);
      return board && this.printList[board_id] === true;
    },

    navigateTo(board) {
      this.$router.push(`boards/${board.id}`);
    },

    editBoard(board) {
      this.editBoardCopy = JSON.parse(JSON.stringify(board));

      // Ensure status and location properties exist
      if (!this.editBoardCopy.status) {
        this.editBoardCopy.status = 'active';
      }

      if (!this.editBoardCopy.location) {
        this.editBoardCopy.location = 'inventory';
      }

      this.showBoardEditModal = true;
    },

    deleteBoard(board) {
      this.boardToDelete = board;
      this.showDeleteDialog = true;
    },

    confirmDelete() {
      this.showDeleteDialog = false;
      this.dispatchDelete(this.boardToDelete);
    },

    dispatchDelete(board) {
      store
        .dispatch('deleteBoard', {
          board
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(board);
            } else {
              this.$q.notify({
                message: 'Board deleted successfully',
                color: 'positive',
                icon: 'check_circle'
              });
            }
          });
        });
    },

    addBoard(board_id) {
      if (!board_id) {
        // Find lowest available ID
        board_id = 10001;
        while (this.$store.state.boards.find((b) => b.id === board_id)) {
          board_id++;
        }
      }

      this.editBoardCopy = {
        newBoard: true,
        id: board_id,
        rev: '',
        note: '',
        type: this.typeId,
        status: 'active',
        location: 'inventory'
      };

      this.showBoardEditModal = true;
    },

    onSubmitBoardEdit() {
      const dispatch = this.editBoardCopy.newBoard
        ? 'createBoard'
        : 'updateBoard';

      store
        .dispatch(dispatch, {
          board: this.editBoardCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmitBoardEdit();
            } else {
              this.showBoardEditModal = false;

              this.$q.notify({
                message: `Board ${
                  this.editBoardCopy.newBoard ? 'created' : 'updated'
                } successfully`,
                color: 'positive',
                icon: 'check_circle'
              });

              if (this.editBoardCopy.newBoard) {
                this.$router.push(`/boards/${this.editBoardCopy.id}`);
              }
            }
          });
        });
    },

    cancelBoardEdit() {
      this.showBoardEditModal = false;
    },

    async printToDOCX(urlList, labels) {
      const {
        Document,
        ImageRun,
        Packer,
        Paragraph,
        Table,
        TableCell,
        TableRow,
        TextRun,
        TextDirection,
        VerticalAlign,
        convertInchesToTwip,
        HeightRule,
        WidthType,
        BorderStyle
      } = await import('docx');

      const tableRows = [];
      let tableChildren = [];
      const borderStyle = {
        style: BorderStyle.NONE,
        size: 0,
        color: '000000'
      };

      const noBorders = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle
      };

      const labelOrientation =
        labels.orientation === 'rotate'
          ? TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT
          : TextDirection.LEFT_TO_RIGHT_TOP_TO_BOTTOM;

      let rowIndex = 0;
      let columnIndex = 0;
      let row = {};
      let column = {};

      for (const urlItem of urlList) {
        // Row column selection
        row = labels.rows[rowIndex];
        column = labels.columns[columnIndex];

        if (row.type === 'space') {
          // Push space
          tableChildren.push(
            new TableCell({
              children: [
                new Paragraph({
                  alignment: 'center',
                  children: [
                    new TextRun({
                      text: labels.name,
                      size: 12
                    })
                  ]
                }),
                new Paragraph({})
              ],
              verticalAlign: VerticalAlign.CENTER,
              textDirection: TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT,
              width: {
                size: convertInchesToTwip(row.width),
                type: WidthType.DXA
              },
              borders: noBorders
            })
          );
          row = labels.rows[++rowIndex];
        }

        if (column.type === 'space') {
          for (let j = 0; j < labels.rows.length; j++) {
            tableChildren.push(
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: 'center',
                    text: 'space'
                  }),
                  new Paragraph({})
                ],
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: convertInchesToTwip(labels.rows[j].width),
                  type: WidthType.DXA
                },
                borders: noBorders
              })
            );
          }

          tableRows.push(
            new TableRow({
              height: {
                value: convertInchesToTwip(column.height),
                rule: HeightRule.EXACT
              },
              children: tableChildren
            })
          );

          tableChildren = [];
          rowIndex = 0;
          column = labels.columns[++columnIndex];
        }

        // Core Label
        tableChildren.push(
          new TableCell({
            children: [
              new Paragraph({
                alignment: 'center',
                children: [
                  new TextRun({
                    text: urlItem.type,
                    size: labels.font
                  })
                ]
              }),
              new Paragraph({
                alignment: 'center',
                children: [
                  new TextRun({
                    text: urlItem.note,
                    size: labels.noteFont
                  })
                ]
              }),
              new Paragraph({
                alignment: 'center',
                children: [
                  new TextRun({
                    text: urlItem.rev,
                    size: labels.noteFont
                  })
                ]
              }),
              new Paragraph({
                alignment: 'center',
                children: [
                  new ImageRun({
                    data: urlItem.qrcode,
                    transformation: {
                      width: labels.size,
                      height: labels.size
                    }
                  })
                ]
              }),
              new Paragraph({
                alignment: 'center',
                children: [
                  new TextRun({
                    text: urlItem.id.toString(),
                    size: labels.font
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.CENTER,
            textDirection: labelOrientation,
            width: {
              size: convertInchesToTwip(row.width),
              type: WidthType.DXA
            },
            borders: noBorders
          })
        );

        // End Core Label
        rowIndex++;
        if (rowIndex >= labels.rows.length) {
          // Draw queue
          tableRows.push(
            new TableRow({
              height: {
                value: convertInchesToTwip(column.height),
                rule: HeightRule.EXACT
              },
              children: tableChildren
            })
          );

          tableChildren = [];
          rowIndex = 0;
          columnIndex++;
        }

        if (columnIndex >= labels.columns.length) {
          columnIndex = 0;
        }
      }

      // Finalize
      if (tableChildren.length > 0) {
        tableRows.push(
          new TableRow({
            height: {
              value: convertInchesToTwip(column.height),
              rule: HeightRule.EXACT
            },
            children: tableChildren
          })
        );
      }

      const doc = new Document({
        sections: [
          {
            properties: {
              page: {
                margin: {
                  top: convertInchesToTwip(labels.margins.top),
                  bottom: convertInchesToTwip(labels.margins.bottom),
                  right: convertInchesToTwip(labels.margins.right),
                  left: convertInchesToTwip(labels.margins.left)
                },
                size: {
                  width: convertInchesToTwip(labels.page.width),
                  height: convertInchesToTwip(labels.page.height),
                  orientation: labels.page.orientation
                }
              }
            },
            children: [
              new Table({
                rows: tableRows
              })
            ]
          }
        ]
      });

      Packer.toBuffer(doc).then((buffer) => {
        exportFile(
          `board-labels.docx`,
          buffer,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      });
    },

    getBoardTypeColor(type) {
      switch (type) {
        case 'Main White':
          return 'blue-grey-8';
        case 'Main Green':
          return 'green-8';
        case 'Main Blue':
          return 'blue-8';
        case 'Screen Green':
          return 'green-9';
        case 'Screen White':
          return 'blue-grey-9';
        case 'Screen Blue':
          return 'blue-9';
        case 'CPU Blue':
          return 'indigo-8';
        case 'HB20':
          return 'orange-8';
        case 'RM420':
          return 'red-8';
        case 'PC':
          return 'purple-8';
        default:
          return 'primary';
      }
    },

    scrollToHighlightedBoard() {
      // First make sure virtual-scroll is disabled
      const wasVirtualScrollEnabled = this.pagination.rowsPerPage < 100;

      if (wasVirtualScrollEnabled) {
        // Set pagination to show more rows
        this.pagination = {
          ...this.pagination,
          rowsPerPage: 1000 // Show many rows
        };
      }

      // Find the target board
      setTimeout(() => {
        // Try multiple selectors to find the row
        let targetRow = null;

        // Try by ID attribute
        targetRow = document.getElementById(String(this.scrollToId));

        // If not found, try by data-id attribute (used by q-table internally)
        if (!targetRow) {
          targetRow = document.querySelector(`[data-id="${this.scrollToId}"]`);
        }

        // If not found, look for cells containing the board ID
        if (!targetRow) {
          const cells = document.querySelectorAll('.q-td');
          for (const cell of cells) {
            if (cell.textContent.trim() === String(this.scrollToId)) {
              targetRow = cell.closest('tr');
              break;
            }
          }
        }

        if (targetRow) {
          // Scroll the row into view
          targetRow.scrollIntoView({ behavior: 'smooth', block: 'center' });

          // Reset pagination after delay if needed
          if (wasVirtualScrollEnabled) {
            setTimeout(() => {
              this.pagination = {
                ...this.pagination,
                rowsPerPage: 300
              };
            }, 5000);
          }
        }
      }, 300); // Wait for DOM to update
    },

    getRowClass(row) {
      // Only show the highlight if both conditions are met:
      // 1. The row ID matches the scrollToId
      // 2. showHighlight flag is true
      if (row.id === this.scrollToId && this.showHighlight) {
        return 'bg-grey-4';
      }
      return '';
    },

    showFullNote(board) {
      this.selectedNoteText = board.note;
      this.selectedNoteId = board.id;
      this.selectedNoteRev = board.rev;
      this.showNoteDialog = true;
    },

    showLocation(boardId) {
      this.selectedLocationText = this.boardLocation(boardId);
      this.showLocationDialog = true;
    }
  }
};
</script>
<style>
/* No custom styles */
</style>
