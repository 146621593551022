<template>
  <div>
    <q-tabs
      v-model="categoryId"
      align="justify"
      dense
      no-caps
      mobile-arrows
      class="shadow-2"
    >
      <q-tab
        v-for="(category, index) in PartsCategories"
        :key="index"
        class="custom-wrap"
        :name="category.id"
        :label="category.item"
      />
      <q-btn outline @click="addCategory">
        <q-icon name="add" size="md"></q-icon>
      </q-btn>
    </q-tabs>

    <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
      <q-table
        :title="activeCategoryName"
        :data="Parts"
        :columns="columns"
        :pagination.sync="pagination"
        row-key="id"
        no-data-label="No parts in this category"
      >
        <template v-slot:body-cell-Image="props">
          <q-td :props="props" style="padding: 0; width: 48px; height: 48px">
            <div
              style="
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f5f5f5;
                position: relative;
              "
            >
              <q-img
                v-if="props.row.attachments?.length"
                :src="
                  getLatestAttachment(props.row.attachments).compressed_url ||
                  getLatestAttachment(props.row.attachments).img_url
                "
                style="width: 100%; height: 100%"
                spinner-color="primary"
              />
              <q-icon v-else name="image" size="24px" color="grey-5" />
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-Link="props">
          <q-td :props="props">
            <a
              class="link-color"
              :href="props.row.link"
              rel="noopener noreferrer"
              target="_blank"
              ><q-icon name="link" size="sm"
            /></a>
          </q-td>
        </template>
        <template v-slot:body-cell-Actions="props">
          <q-td :props="props">
            <div class="row no-wrap justify-center">
              <q-btn
                class="btn-table-primary q-mr-sm"
                outline
                icon="image"
                color="primary"
                size="md"
                padding="xs"
                @click="showAttachments(props.row)"
              />
              <q-btn
                class="btn-table-primary q-mr-sm"
                outline
                color="primary"
                icon="edit"
                size="md"
                padding="xs"
                @click="editItem(props.row)"
              />
              <q-btn
                class="btn-table-negative"
                outline
                color="accent"
                icon="delete"
                size="md"
                padding="xs"
                @click="deleteItem(props.row)"
              />
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-Orders="props">
          <q-td
            :props="props"
            class="cursor-pointer"
            @click="quickEditOrder(props.row)"
            style="text-align: left"
          >
            <div
              style="
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ props.row.orders ? decoder(props.row.orders) : '' }}
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-Notes="props">
          <q-td
            :props="props"
            class="cursor-pointer"
            @click="quickEditNote(props.row)"
            style="text-align: left"
          >
            <div
              style="
                max-width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ props.row.notes ? decoder(props.row.notes) : '' }}
            </div>
          </q-td>
        </template>
      </q-table>
    </div>

    <q-page-sticky
      v-if="Parts.length < 1"
      position="bottom-right"
      :offset="[100, 28]"
    >
      <q-btn fab icon="delete" color="negative" @click="deleteCategory" />
    </q-page-sticky>
    <q-page-sticky
      v-if="Parts.length > 0"
      position="bottom-right"
      :offset="[100, 28]"
    >
      <q-btn fab icon="edit" color="primary" @click="editCategory" />
    </q-page-sticky>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addItem" />
    </q-page-sticky>
    <!-- edit modal -->
    <q-dialog v-model="showEditModal" position="top" persistent>
      <q-card
        style="width: 510px"
        :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'"
      >
        <q-form @submit="onSubmit">
          <div
            class="row justify-center full-width text-h6 text-white bg-primary q-py-sm q-mb-md"
          >
            <span v-if="editCopy.id === 'new'">New Part</span>
            <span v-if="editCopy.id !== 'new'">Edit Part</span>
          </div>

          <div class="q-mb-sm">
            <q-input
              filled
              v-model="editCopy.name"
              label="Name"
              maxlength="64"
              outlined
              square
            />
          </div>

          <div class="q-mb-sm">
            <q-input
              filled
              v-model="editCopy.mfgpart"
              label="Part No."
              maxlength="64"
              outlined
              square
            />
          </div>

          <div class="q-mb-sm">
            <q-input
              filled
              v-model="editCopy.vendor"
              label="Vendor"
              maxlength="64"
              outlined
              square
            />
          </div>

          <div class="q-mb-sm">
            <q-input
              filled
              v-model="editCopy.orders"
              label="Orders"
              type="textarea"
              maxlength="4000"
              outlined
              square
              autogrow
            />
          </div>

          <div class="q-mb-sm">
            <q-input
              filled
              v-model="editCopy.notes"
              label="Notes"
              type="textarea"
              maxlength="4000"
              outlined
              square
              autogrow
            />
          </div>

          <div class="q-mb-sm">
            <q-input
              filled
              v-model="editCopy.link"
              label="Link"
              maxlength="256"
              outlined
              square
            />
          </div>

          <div class="q-mb-sm">
            <q-select
              v-model="editCopy.category"
              square
              filled
              :options="categoryOptions(false)"
              emit-value
              map-options
              label="Category"
            />
          </div>

          <div class="q-mb-sm">
            <q-select
              v-model="editCopy.categoryOpt2"
              square
              filled
              :options="categoryOptions(true)"
              emit-value
              map-options
              label="Category 2"
            />
          </div>

          <div class="q-mb-sm">
            <q-select
              v-model="editCopy.categoryOpt3"
              square
              filled
              :options="categoryOptions(true)"
              emit-value
              map-options
              label="Category 3"
            />
          </div>

          <div class="row full-width">
            <q-space />
            <q-btn
              unelevated
              label="Cancel"
              color="grey-2"
              text-color="black"
              class="q-mr-sm"
              @click="cancelEdit"
            />
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
    <!-- edit category modal -->
    <q-dialog v-model="showEditCategory" persistent>
      <q-card
        style="width: 510px"
        :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'"
      >
        <q-form @submit="onSubmitCategory">
          <div
            class="row justify-center full-width text-h6 text-white bg-primary q-py-sm q-mb-md"
          >
            <span v-if="editCategoryCopy.id === 'new'">New Category</span>
            <span v-if="editCategoryCopy.id !== 'new'">Edit Category</span>
          </div>

          <div class="q-mb-sm">
            <q-input
              filled
              v-model="editCategoryCopy.item"
              label="Name"
              maxlength="32"
              outlined
              square
            />
          </div>

          <div class="row full-width">
            <q-space />
            <q-btn
              unelevated
              label="Cancel"
              color="grey-2"
              text-color="black"
              class="q-mr-sm"
              @click="cancelEdit"
            />
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
    <!-- end edit category modal -->
    <!-- attachment dialog -->
    <q-dialog
      v-model="showAttachmentDialog"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card v-if="selectedPart">
        <q-card-actions>
          <q-btn
            outline
            label="Back"
            color="primary"
            icon="arrow_back"
            padding="xs sm"
            v-close-popup
          />
        </q-card-actions>
        <q-separator />

        <q-card-section
          style="height: calc(100vh - 60px)"
          class="scroll q-pt-lg"
        >
          <Attachments :parentObj="selectedPart" :parentType="'part'" />
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- quick note edit dialog -->
    <q-dialog v-model="showQuickNoteEdit" persistent>
      <q-card
        style="width: 510px"
        :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'"
      >
        <q-form @submit="saveQuickNote">
          <div
            class="row justify-center full-width text-h6 text-primary q-pb-sm"
          >
            <span>Notes - {{ decoder(quickNoteEdit.name) }}</span>
          </div>

          <q-separator color="primary" />

          <div class="q-mb-sm q-mt-md">
            <q-input
              filled
              v-model="quickNoteEdit.notes"
              type="textarea"
              maxlength="4000"
              outlined
              square
              autogrow
              autofocus
              label="Notes"
            />
          </div>

          <div class="row full-width">
            <q-space />
            <q-btn
              unelevated
              label="Cancel"
              color="grey-2"
              text-color="black"
              class="q-mr-sm"
              @click="showQuickNoteEdit = false"
            />
            <q-btn label="Save" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
    <!-- end quick note edit dialog -->
    <!-- quick order edit dialog -->
    <q-dialog v-model="showQuickOrderEdit" persistent>
      <q-card
        style="width: 510px"
        :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'"
      >
        <q-form @submit="saveQuickOrder">
          <div
            class="row justify-center full-width text-h6 text-primary q-pb-sm"
          >
            <span>Orders - {{ decoder(quickNoteEdit.name) }}</span>
          </div>

          <q-separator color="primary" />

          <div class="q-mb-sm q-mt-md">
            <q-input
              filled
              v-model="quickNoteEdit.orders"
              type="textarea"
              maxlength="4000"
              outlined
              square
              autogrow
              autofocus
              label="Orders"
            />
          </div>

          <div class="row full-width">
            <q-space />
            <q-btn
              unelevated
              label="Cancel"
              color="grey-2"
              text-color="black"
              class="q-mr-sm"
              @click="showQuickOrderEdit = false"
            />
            <q-btn label="Save" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
    <!-- end quick order edit dialog -->
  </div>
</template>

<script>
import store from '@/store';
import Attachments from '@/components/Attachments';
// import { parseTimeStamp } from "@/lib/date-utils";

export default {
  name: 'Farms',
  components: {
    Attachments
  },
  data() {
    return {
      categoryId:
        this.$store.state.partsCategories.length > 0
          ? this.$store.state.partsCategories[0].id
          : -1,
      showEditModal: false,
      showEditCategory: false,
      showAttachmentDialog: false,
      showQuickNoteEdit: false,
      showQuickOrderEdit: false,
      quickNoteEdit: { id: null, notes: '' },
      selectedPart: null,
      editCopy: {},
      editCategoryCopy: {},
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          name: 'Image',
          label: '',
          align: 'center',
          field: (row) => row.attachments?.length || 0
        },
        {
          sortable: true,
          name: 'Item',
          label: 'Item',
          align: 'left',
          classes: 'table-highlight-column',
          field: (row) => this.decoder(row.name)
        },
        {
          sortable: true,
          name: 'MfgPart',
          label: 'Part No.',
          align: 'left',
          field: (row) => this.decoder(row.mfgpart)
        },
        {
          name: 'Link',
          label: 'Link',
          align: 'center',
          field: (row) => row.link
        },
        {
          sortable: true,
          name: 'Vendor',
          label: 'Vendor',
          align: 'left',
          field: (row) => this.decoder(row.vendor)
        },
        {
          sortable: true,
          name: 'Orders',
          label: 'Orders',
          align: 'left',
          style:
            'width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'
        },
        {
          sortable: true,
          name: 'Notes',
          label: 'Notes',
          align: 'left',
          style:
            'width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'
        },
        {
          name: 'Actions',
          label: '',
          align: 'right'
        }
      ]
    };
  },
  methods: {
    decoder(str) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = str;
      return textArea.value;
    },
    getLatestAttachment(attachments) {
      if (!attachments?.length) return null;

      // First check if there's a thumbnail marked
      for (const attachment of attachments) {
        if (attachment.is_pdf === 0 && attachment.is_thumbnail === 1) {
          return attachment;
        }
      }

      // Fallback to latest by sort_date if no thumbnail is marked
      let latestNonPdf = null;

      for (const attachment of attachments) {
        if (attachment.is_pdf === 1) continue;
        if (!latestNonPdf) {
          latestNonPdf = attachment;
        } else if (attachment.sort_date > latestNonPdf.sort_date) {
          latestNonPdf = attachment;
        }
      }

      return latestNonPdf;
    },
    addCategory() {
      this.editCategoryCopy = {
        id: 'new',
        item: ''
      };
      this.showEditCategory = true;
    },
    editCategory() {
      const category = this.$store.state.partsCategories.find(
        (item) => item.id === this.categoryId
      );
      this.editCategoryCopy = JSON.parse(JSON.stringify(category));
      this.showEditCategory = true;
    },
    deleteCategory() {
      const category = this.$store.state.partsCategories.find(
        (item) => item.id === this.categoryId
      );
      this.$deleteResult
        .getResultsAsync('setting', category.item, 'Delete category?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDeleteCategory(category);
          }
        });
    },
    onSubmitCategory() {
      let dispatch;
      if (this.editCategoryCopy.id === 'new') {
        dispatch = 'createCategory';
      } else {
        dispatch = 'updateCategory';
      }
      store.dispatch(dispatch, this.editCategoryCopy).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.onSubmitCategory();
          } else {
            this.showEditCategory = false;
          }
        });
      });
    },
    dispatchDeleteCategory(category) {
      store.dispatch('deleteCategory', category).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.dispatchDeleteCategory(category);
          }
        });
      });
    },
    onSubmit() {
      let dispatch;
      if (this.editCopy.id === 'new') {
        dispatch = 'createPart';
      } else {
        dispatch = 'updatePart';
      }
      store.dispatch(dispatch, this.editCopy).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.onSubmit();
          } else {
            this.showEditModal = false;
          }
        });
      });
    },
    addItem() {
      this.editCopy = {
        id: 'new',
        item: '',
        category: this.categoryId,
        categoryOpt2: null,
        categoryOpt3: null,
        link: '',
        mfgpart: '',
        vendor: '',
        orders: '',
        notes: ''
      };
      this.showEditModal = true;
    },
    editItem(part) {
      this.editCopy = JSON.parse(JSON.stringify(part));
      this.showEditModal = true;
    },
    deleteItem(part) {
      this.$deleteResult
        .getResultsAsync('build', part.name, 'Delete part?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(part);
          }
        });
    },
    dispatchDelete(part) {
      store.dispatch('deletePart', part).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.dispatchDelete(part);
          }
        });
      });
    },
    cancelEdit() {
      this.showEditModal = false;
      this.showEditCategory = false;
    },
    categoryOptions(allowNone) {
      const list = [];
      for (const category of this.$store.state.partsCategories) {
        list.push({
          label: category.item,
          value: category.id
        });
      }
      if (allowNone) {
        list.push({
          label: 'None selected',
          value: null
        });
      }
      return list;
    },
    showAttachments(part) {
      this.selectedPart = part;
      this.showAttachmentDialog = true;
    },
    quickEditNote(part) {
      // Clone the entire part first to preserve all properties
      const partClone = JSON.parse(JSON.stringify(part));

      // Just update the notes field
      this.quickNoteEdit = {
        ...partClone,
        notes: part.notes || ''
      };
      this.showQuickNoteEdit = true;
    },
    quickEditOrder(part) {
      // Clone the entire part first to preserve all properties
      const partClone = JSON.parse(JSON.stringify(part));

      // Just update the orders field
      this.quickNoteEdit = {
        ...partClone,
        orders: part.orders || ''
      };
      this.showQuickOrderEdit = true;
    },
    saveQuickNote() {
      store.dispatch('updatePart', this.quickNoteEdit).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.saveQuickNote();
          } else {
            this.showQuickNoteEdit = false;
          }
        });
      });
    },
    saveQuickOrder() {
      store.dispatch('updatePart', this.quickNoteEdit).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.saveQuickOrder();
          } else {
            this.showQuickOrderEdit = false;
          }
        });
      });
    }
  },
  computed: {
    Parts() {
      return this.$store.state.parts
        .filter(
          (part) =>
            part.category === this.categoryId ||
            part.categoryOpt2 === this.categoryId ||
            part.categoryOpt3 === this.categoryId
        )
        .sort(function (a, b) {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
    },
    PartsCategories() {
      const cats = JSON.parse(
        JSON.stringify(this.$store.state.partsCategories)
      );
      cats.sort(function (a, b) {
        return a.item.localeCompare(b.item, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return cats;
    },
    activeCategoryName() {
      const category = this.$store.state.partsCategories.find(
        (item) => item.id === this.categoryId
      );
      return category ? category.item : '--';
    }
  },
  watch: {
    '$store.state.partsCategories'() {
      if (
        this.$store.state.partsCategories.length > 0 &&
        this.$store.state.partsCategories.findIndex(
          (item) => item.id === this.categoryId
        ) === -1
      ) {
        this.categoryId = -1;
      }
      if (
        this.$store.state.partsCategories.length > 0 &&
        this.categoryId === -1
      ) {
        this.categoryId = this.$store.state.partsCategories[0].id;
      }
    },
    '$store.state.parts': {
      deep: true,
      handler(newParts) {
        if (this.selectedPart) {
          const updatedPart = newParts.find(
            (part) => part.id === this.selectedPart.id
          );
          if (updatedPart) {
            this.selectedPart = updatedPart;
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
/* Allow multi row q-categoryId */
@media (min-width: 700px) {
  .q-tab {
    &::v-deep {
      .q-tab__content {
        flex-wrap: wrap !important;
      }
    }
  }

  .q-tabs {
    &::v-deep {
      .q-tabs__content {
        flex-wrap: wrap !important;
      }
    }
  }
}
</style>

<style scoped>
@media only screen and (min-width: 1000px) {
  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }
}

.spaced {
  margin-left: 2px;
  margin-right: 2px;
}

.name-header {
  color: #d5d9dd;
  background-color: #424242;
  padding: 10px 10px 10px 0px;
  width: 35px;
}

.contact-td {
  padding-left: 15px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
}

.item-td {
  padding-left: 10px;
  text-align: left;
  font-weight: bold;
}

.col-td {
  padding-left: 10px;
  text-align: left;
}

.col-th {
  padding-left: 15px;
  text-align: left;
}

table {
  margin: 0px auto 0px auto;
  font-size: 12px;
  border-collapse: collapse;
  border-left: none;
  border-right: none;
}

td {
  text-align: center;
  border: 1px solid #212121;
  border-left: none;
  border-right: none;
}

th {
  padding: 10px 0px;
  border: 1px solid #212121;
  border-left: none;
  border-right: none;
}
</style>
