<template>
  <div>
    <!-- Board Info Header -->
    <div>
      <!-- For larger screens: buttons on left and right, board name in the middle -->
      <div class="row justify-between items-center q-pa-md">
        <div>
          <q-btn
            icon="arrow_back"
            color="primary"
            label="Boards"
            @click="backToBoards"
          />
        </div>
        <div v-if="$q.screen.gt.sm" class="text-h6 text-center">
          {{ TableName }}
        </div>
        <div>
          <q-btn color="accent" icon="add" @click="addLog"> </q-btn>
        </div>
      </div>
      <q-separator v-if="$q.screen.lt.sm" class="full-width" />

      <!-- For smaller screens: buttons on top row, board name below -->
      <div v-if="$q.screen.lt.sm">
        <div class="text-h6 text-center q-py-sm">{{ TableName }}</div>
      </div>
    </div>

    <!-- Action Bar -->
    <div class="action-bar q-mb-sm">
      <div class="row justify-between items-center q-col-gutter-sm">
        <div class="row items-center q-gutter-xs">
          <q-btn
            v-if="selectedZebraOnline"
            color="primary"
            icon="print"
            flat
            :round="$q.screen.gt.xs"
            :dense="$q.screen.gt.xs"
            size="sm"
            @click="printSelectedZebra()"
            :disable="!selectedZebraOnline"
          >
            <q-tooltip>Print to Zebra</q-tooltip>
          </q-btn>
        </div>
      </div>
    </div>

    <!-- Logs Table -->
    <div>
      <q-table
        square
        :data="BoardLogs"
        :columns="columns"
        :pagination="pagination"
        row-key="id"
        separator="cell"
        card-class="board-logs-card"
        :loading="$store.state.loading"
        no-data-label="No Board Logs"
        :dense="$q.screen.lt.md"
        :table-header-class="$q.screen.lt.sm ? 'q-table--dense' : ''"
      >
        <template v-slot:top>
          <div class="text-subtitle1 q-py-sm q-px-sm">Board Logs</div>
        </template>

        <template v-if="$q.screen.lt.sm" v-slot:body="props">
          <q-tr :props="props">
            <q-td key="Date">
              {{ parseTimeStamp(props.row.date, 'short') }}
            </q-td>
            <q-td
              key="Notes"
              class="ellipsis cursor-pointer"
              style="
                max-width: 80px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              @click="showFullNote(props.row)"
            >
              {{ decoder(props.row.notes) }}
            </q-td>
            <q-td key="In-service" class="ellipsis" style="max-width: 80px">
              {{ inServiceName(props.row.farm_id, props.row.pond_id) }}
            </q-td>
            <q-td key="Actions">
              <div class="row no-wrap justify-center">
                <q-btn
                  flat
                  round
                  size="sm"
                  color="primary"
                  icon="edit"
                  class="q-mx-sm"
                  @click="editLog(props.row)"
                />
                <q-btn
                  flat
                  round
                  size="sm"
                  :color="props.row.attachments.length ? 'primary' : 'grey-6'"
                  icon="photo_library"
                  class="q-mr-sm"
                  @click="showAttachmentClick(props.row)"
                />
                <q-btn
                  flat
                  round
                  size="sm"
                  color="negative"
                  icon="delete"
                  @click="deleteLog(props.row)"
                />
              </div>
            </q-td>
          </q-tr>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-Date="props">
          <q-td :props="props">
            <div class="text-weight-medium">
              {{ parseTimeStamp(props.row.date, 'short') }}
            </div>
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-Notes="props">
          <q-td
            :props="props"
            class="cursor-pointer"
            @click="showFullNote(props.row)"
          >
            <div
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ decoder(props.row.notes) }}
            </div>
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-In-service="props">
          <q-td :props="props">
            <div>{{ inServiceName(props.row.farm_id, props.row.pond_id) }}</div>
          </q-td>
        </template>

        <template v-if="$q.screen.gt.xs" v-slot:body-cell-Actions="props">
          <q-td :props="props" class="actions-cell">
            <div class="row no-wrap justify-center">
              <q-btn
                flat
                round
                dense
                color="primary"
                icon="edit"
                @click="editLog(props.row)"
              >
                <q-tooltip>Edit Log</q-tooltip>
              </q-btn>
              <q-btn
                flat
                round
                dense
                :color="props.row.attachments.length ? 'primary' : 'grey-6'"
                icon="photo_library"
                @click="showAttachmentClick(props.row)"
              >
                <q-tooltip>View Attachments</q-tooltip>
              </q-btn>
              <q-btn
                flat
                round
                dense
                color="negative"
                icon="delete"
                @click="deleteLog(props.row)"
              >
                <q-tooltip>Delete Log</q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </div>

    <!-- Edit Log Modal -->
    <q-dialog
      v-model="showEditModal"
      persistent
      position="top"
      :maximized="$q.screen.lt.sm"
    >
      <q-card
        :style="$q.screen.lt.sm ? '' : 'width: 510px'"
        :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'"
      >
        <div
          class="row justify-center full-width text-h6 text-white bg-primary q-py-sm q-mb-md"
        >
          <span>{{ ModalHeader }}</span>
        </div>

        <q-form>
          <q-input
            filled
            square
            autogrow
            v-model="editLogCopy.notes"
            type="textarea"
            label="Repair Notes"
            maxlength="1024"
            class="q-mb-md"
          />

          <q-select
            v-model="editLogCopy.farm_id"
            filled
            square
            :options="FarmOptions"
            emit-value
            map-options
            label="Farm"
            class="q-mb-md"
          />

          <q-select
            v-model="editLogCopy.pond_id"
            filled
            square
            :options="PondOptions"
            emit-value
            map-options
            label="Pond"
            :disable="!editLogCopy.farm_id"
            class="q-mb-md"
          />

          <q-input filled square label="Date" v-model="logDate" class="q-mb-md">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="logDate" mask="MM/DD/YYYY">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <div class="row full-width">
            <q-space />
            <q-btn
              label="Cancel"
              color="grey-3"
              text-color="black"
              class="q-mr-sm"
              @click="cancelEdit"
            />
            <q-btn label="Save" color="primary" @click="onSubmit" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- Attachments Dialog -->
    <q-dialog
      v-model="showAttachment"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card v-if="selectedLog">
        <q-card-actions>
          <q-btn
            label="Back"
            color="primary"
            icon="arrow_back"
            padding="xs sm"
            @click="showAttachment = false"
          />
        </q-card-actions>
        <q-separator />

        <q-card-section
          style="height: calc(100vh - 60px)"
          class="scroll q-pt-lg"
        >
          <Attachments :parentObj="selectedLog" :parentType="'repair-log'" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- Zebra Preview -->
    <ZebraPreview
      :show="showZebraPreview"
      :printData="selectedPreview"
      @complete="closeZebraPreview"
    />

    <!-- Full Note Dialog -->
    <q-dialog v-model="showNoteDialog">
      <q-card style="min-width: 350px">
        <q-card-section
          class="row items-center q-pb-none bg-primary text-white"
        >
          <div class="text-h6">Notes</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-md">
          <div class="text-body1">{{ decoder(selectedNoteText) }}</div>
        </q-card-section>

        <q-card-section class="text-caption text-grey">
          {{ selectedNoteDate ? parseTimeStamp(selectedNoteDate, 'long') : '' }}
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- Delete Confirmation Dialog -->
    <q-dialog v-model="showDeleteDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section class="row items-center bg-primary text-white">
          <div class="text-h6">Confirm Delete</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-md">
          <p class="text-body1">Are you sure you want to delete this log?</p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            label="Cancel"
            color="grey-3"
            text-color="black"
            v-close-popup
          />
          <q-btn label="Delete" color="negative" @click="confirmDelete" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Attachments from '@/components/Attachments';
import ZebraPreview from '@/components/ZebraPreview';
import { parseTimeStamp } from '@/lib/date-utils';
import { decoder } from '@/lib/helpers';
import store from '@/store';
import { date } from 'quasar';
import { BoardTypes } from '@/lib/board-types';

export default {
  name: 'BoardLogs',
  components: {
    Attachments,
    ZebraPreview
  },
  props: {
    boardId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      editLogCopy: {},
      selectedLog: null,
      logDate: null,
      showEditModal: false,
      showZebraPreview: false,
      selectedPreview: {},
      showAttachment: false,
      showNoteDialog: false,
      selectedNoteText: '',
      selectedNoteDate: null,
      showDeleteDialog: false,
      logToDelete: null,
      pagination: {
        sortBy: 'Date',
        descending: true,
        page: 1,
        rowsPerPage: 15
      },
      columns: [
        {
          name: 'Date',
          required: true,
          label: 'Date',
          align: 'left',
          field: (row) => row.date,
          sortable: true,
          sort: (a, b) => b - a
        },
        {
          name: 'Notes',
          required: true,
          label: 'Repair Notes',
          align: 'left',
          field: 'notes',
          sortable: true,
          style: 'max-width: 250px; min-width: 150px; overflow: hidden;'
        },
        {
          name: 'In-service',
          label: 'Location',
          align: 'left',
          field: (row) => `${row.farm_id}:${row.pond_id}`,
          sortable: true
        },
        {
          name: 'Actions',
          label: 'Actions',
          align: 'center'
        }
      ],
      boardTypes: BoardTypes
    };
  },
  computed: {
    BoardLogs() {
      const logs = this.$store.state.boardLogs.filter(
        (log) => log.board_id === parseInt(this.boardId)
      );
      return [...logs].sort((a, b) => b.date - a.date);
    },

    TableTitle() {
      return `Board Logs - ${this.boardId}`;
    },

    TableName() {
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.boardId)
      );
      return board
        ? `${board.type} #${board.id} (${board.rev})`
        : 'Loading board details...';
    },

    ModalHeader() {
      return this.editLogCopy.id === 'new'
        ? 'New Repair Log'
        : 'Edit Repair Log';
    },

    FarmOptions() {
      const ret = [];
      ret.push({
        label: '--- Select Farm ---',
        value: null,
        name: '---'
      });

      let farmList;
      if (this.$store.getters.isSuperAdmin) {
        farmList = this.$store.state.farmList;
      } else {
        farmList = this.$store.state.farmList.filter((e) => {
          return this.$store.state.user.farms.find((f) => f === e.id);
        });
      }

      for (const farm of farmList) {
        ret.push({
          label: decoder(farm.farm_name),
          value: farm.id,
          name: farm.farm_name
        });
      }

      return ret;
    },

    PondOptions() {
      const ret = [];
      ret.push({
        label: '--- Select Pond ---',
        value: null,
        name: '---'
      });

      const farm = this.$store.state.farmList.find(
        (farm) => farm.id === this.editLogCopy.farm_id
      );

      if (farm && farm.farmASM) {
        for (const pond of farm.farmASM) {
          ret.push({
            label: decoder(pond.name),
            value: pond.id,
            name: pond.name
          });
        }
      }

      return ret;
    },

    selectedZebraOnline() {
      if (this.$store.state.zebraSelection === '') {
        return false;
      }
      return this.$store.state.zebraPrinters.find(
        (p) => p.zebraId === this.$store.state.zebraSelection
      );
    },

    lastLogData() {
      const logs = this.BoardLogs;
      if (logs.length === 0) {
        return '';
      }

      return logs[0].notes;
    }
  },
  methods: {
    backToBoards() {
      this.$store.dispatch('setHighlightBoardId', parseInt(this.boardId));
      this.$router.push('/boards');
    },

    decoder(text) {
      return decoder(text);
    },

    parseTimeStamp(timestamp, format) {
      return parseTimeStamp(timestamp, format);
    },

    inServiceName(farm_id, pond_id) {
      if (farm_id) {
        const farm = this.$store.state.farmList.find(
          (farm) => farm.id === farm_id
        );

        if (farm) {
          const pond = farm.farmASM.find((pond) => pond.id === pond_id);
          if (pond) {
            return `${decoder(farm.farm_name)} : ${decoder(pond.name)}`;
          } else {
            return `${decoder(farm.farm_name)}`;
          }
        }
      }

      return '---';
    },

    editLog(log) {
      this.editLogCopy = JSON.parse(JSON.stringify(log));
      this.logDate = date.formatDate(
        this.editLogCopy.date * 1000,
        'MM/DD/YYYY'
      );
      this.showEditModal = true;
    },

    deleteLog(log) {
      this.logToDelete = log;
      this.showDeleteDialog = true;
    },

    confirmDelete() {
      this.showDeleteDialog = false;
      this.dispatchDelete(this.logToDelete);
    },

    dispatchDelete(log) {
      store
        .dispatch('deleteBoardLog', {
          log
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(log);
            } else {
              this.$q.notify({
                message: 'Log deleted successfully',
                color: 'positive',
                icon: 'check_circle'
              });
            }
          });
        });
    },

    addLog() {
      this.editLogCopy = {
        id: 'new',
        board_id: parseInt(this.boardId),
        notes: '',
        farm_id: null,
        pond_id: null,
        attachments: [],
        date: Math.floor(Date.now() / 1000)
      };
      this.logDate = date.formatDate(Date.now(), 'MM/DD/YYYY');
      this.showEditModal = true;
    },

    onSubmit() {
      const dispatch =
        this.editLogCopy.id === 'new' ? 'createBoardLog' : 'updateBoardLog';

      this.editLogCopy.date = date.formatDate(new Date(this.logDate), 'X');

      store
        .dispatch(dispatch, {
          log: this.editLogCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit();
            } else {
              this.showEditModal = false;
              this.$q.notify({
                message: `Log ${
                  this.editLogCopy.id === 'new' ? 'created' : 'updated'
                } successfully`,
                color: 'positive',
                icon: 'check_circle'
              });
            }
          });
        });
    },

    cancelEdit() {
      this.showEditModal = false;
    },

    showAttachmentClick(row) {
      this.selectedLog = row;
      this.showAttachment = true;
    },

    printSelectedZebra() {
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.boardId)
      );

      if (!board) return;

      this.selectedPreview = {
        type: board.type,
        id: board.id,
        rev: board.rev,
        note: board.note,
        logData: this.lastLogData
      };

      this.showZebraPreview = true;
    },

    closeZebraPreview() {
      this.showZebraPreview = false;
    },

    showFullNote(log) {
      this.selectedNoteText = log.notes;
      this.selectedNoteDate = log.date;
      this.showNoteDialog = true;
    }
  },
  watch: {
    '$store.state.boardLogs'() {
      if (this.showAttachment === true && this.selectedLog) {
        const log = this.$store.state.boardLogs.find(
          (log) => log.id === this.selectedLog.id
        );
        if (log) {
          this.selectedLog = log;
        }
      }
    }
  }
};
</script>

<style></style>
