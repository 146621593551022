const buildBoardLabel3x2 = function (type, id, rev, notes, repairNotes) {
  return `CT~~CD,~CC^~CT~
^XA
~JSN
^MNW
^PMN
^CI27
^XZ
^XA
^MMT
^PW635
^LL432
^FPH,3^FT59,68^A0N,39,38^FH\\^CI28^FD${type}^FS^CI27
^FT57,347^BQN,2,8
^FH\\^FDLA,https://aerworx.aercon.net/boards/${id}^FS
^FPH,4^FT52,379^ACN,50,40^FH\\^FD${id}^FS
^FB250,4,,,
^FPH,4^FT336,192^A0N,28,28^FH\\^CI28^FD${notes}^FS^CI27
^FB250,9,,,
^FPH,4^FT336,365^A0N,20,20^FH\\^CI28^FD${repairNotes}^FS^CI27
^FPH,3^FT438,67^A0N,34,33^FH\\^CI28^FD${rev}^FS^CI27
^PQ1,0,1,Y
^XZ`;
};

module.exports = {
  buildBoardLabel3x2
};
