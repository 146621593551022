<template>
  <div class="fit">
    <div class="q-pa-sm row items-start q-gutter-sm">
      <div class="row">
        <q-toggle
          dense
          size="md"
          v-model="showHB20"
          val="md"
          color="primary"
          keep-color
          :class="$q.screen.xs ? 'q-ml-xs' : 'q-ml-lg'"
          label="HB20"
          @input="limitToggle('showHB20')"
        />
        <q-toggle
          dense
          size="md"
          v-model="showTodo"
          val="md"
          color="primary"
          keep-color
          class="q-ml-sm"
          label="To-Do's"
          @input="limitToggle('showTodo')"
        />
        <q-toggle
          dense
          size="md"
          v-model="showHopping"
          val="md"
          color="primary"
          keep-color
          :label="$q.screen.xs ? 'RF' : 'RF Channels'"
          class="q-ml-sm"
          @input="limitToggle('showHopping')"
        />
        <q-btn
          v-if="showHopping"
          rounded
          :label="$q.screen.xs ? 'Radius' : 'RF Radius'"
          size="sm"
          color="primary"
          padding="xs sm"
          class="q-ml-sm"
          @click="setRadius"
        />
      </div>
    </div>
    <div id="mapCanvas" ref="mapCanvas"></div>
  </div>
</template>

<script>
import { SplitGps } from '@/lib/helpers';
import { GoogleMapsOverlay as DeckOverlay } from '@deck.gl/google-maps';
import { GeoJsonLayer } from '@deck.gl/layers';
import { qColors } from '@/lib/static-data';

export default {
  name: 'MainMap',
  data() {
    return {
      computerMarkers: [],
      computerMarkerIds: [],
      hb20Markers: [],
      hb20MarkerIds: [],
      hoppingRadius: 0.4,
      showHB20: false,
      showTodo: false,
      showHopping: false,
      map: null,
      farmLabels: [],
      farmLabelIds: [],
      farmMarkers: [],
      farmMarkerIds: [],
      google: {
        advancedMarkerEl: null,
        infoWindowEl: null,
        pinEl: null
      },
      repeaterMarkers: [],
      repeaterMarkerIds: [],
      repeaterLines: [],
      repeaterLineIds: [],
      rfCircles: [],
      rfCircleIds: [],
      overlay: null,
      pondLabels: [],
      pondLabelIds: [],
      pondMarkers: [],
      pondMarkerIds: [],
      throttled: false,
      zoomed: false
    };
  },
  mounted() {
    this.loadMap();
    window.goToFarmMap = this.goToFarmMap;
  },
  methods: {
    buildFarmMarker(farm, idStr, lat, lng, farmColor) {},
    calculateFarmCenter(farmGps) {
      const gpsLocations = [];
      for (let i = 0; i < farmGps.ponds.length; i++) {
        const loc = SplitGps(farmGps.ponds[i].gps);
        if (loc !== null) {
          gpsLocations.push(loc);
        }
      }
      for (let i = 0; i < farmGps.computers.length; i++) {
        const loc = SplitGps(farmGps.computers[i].gps);
        if (loc !== null) {
          gpsLocations.push(loc);
        }
      }
      for (let i = 0; i < farmGps.repeaters.length; i++) {
        const loc = SplitGps(farmGps.repeaters[i].gps);
        if (loc !== null) {
          gpsLocations.push(loc);
        }
      }
      if (gpsLocations.length === 0) {
        return null;
      }
      let lng = 0;
      let lat = 0;
      for (let i = 0; i < gpsLocations.length; i++) {
        lng += gpsLocations[i].lng;
        lat += gpsLocations[i].lat;
      }
      lng /= gpsLocations.length;
      lat /= gpsLocations.length;
      return {
        lat,
        lng
      };
    },
    checkInBounds(bounds, lat, lng) {
      const NE = bounds.getNorthEast();
      const SW = bounds.getSouthWest();
      return (
        lat < NE.lat() && lat > SW.lat() && lng < NE.lng() && lng > SW.lng()
      );
    },
    getDistance(lat1, lon1, lat2, lon2) {
      const deg2rad = (deg) => {
        return deg * (Math.PI / 180);
      };

      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(lat2 - lat1); // deg2rad below
      const dLon = deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
          Math.cos(deg2rad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d * 3280.84; // Distance in feet
    },
    goToFarmMap(farmId) {
      this.$router.push(`/farm/${farmId}/`);
    },
    async loadMap() {
      if (window.google && window.google.maps) {
        const { Map, InfoWindow } = await window.google.maps.importLibrary(
          'maps'
        );
        const { AdvancedMarkerElement, PinElement } =
          await window.google.maps.importLibrary('marker');
        this.google.advancedMarkerEl = AdvancedMarkerElement;
        this.google.infoWindowEl = InfoWindow;
        this.google.pinEl = PinElement;

        const center = SplitGps('32.583486, -87.511146');
        const mapOptions = {
          center: new window.google.maps.LatLng(center.lat, center.lng),
          zoom: 9.4,
          mapTypeId: window.google.maps.MapTypeId.SATELLITE,
          gestureHandling: 'greedy',
          mapId: 'AERWORX_MAP_ID'
        };
        this.map = new window.google.maps.Map(this.$refs.mapCanvas, mapOptions);

        const shopCenter = new window.google.maps.LatLng(
          SplitGps('32.583486, -87.511146')
        );

        const el = document.createElement('div');
        el.innerHTML = `<i class="q-icon notranslate material-icons text-black"
            style="font-size: 30px;">warehouse</i>`;

        const pin = new this.google.pinEl({
          glyph: el,
          glyphColor: '#eeeeee',
          background: '#1976d2',
          borderColor: '#000000',
          scale: 2
        });

        const marker = new this.google.advancedMarkerEl({
          map: this.map,
          position: { lat: shopCenter.lat(), lng: shopCenter.lng() },
          content: pin.element,
          zIndex: 0.5
        });

        window.google.maps.event.addListener(this.map, 'zoom_changed', () => {
          this.throttle(this.loadMapMarkers, this.removeSmart);
        });

        window.google.maps.event.addListener(this.map, 'dragend', () => {
          this.throttle(this.loadMapMarkers, this.removeSmart);
        });
      } else {
        setTimeout(this.loadMap, 250);
      }
    },
    loadMapMarkers(removeFn) {
      if (!this.map) {
        this.throttle(this.loadMapMarkers, removeFn);
      }

      this.zoomIntoFarm();
      const mapBounds = this.map.getBounds();
      const zoomLevel = this.map.getZoom();

      removeFn();

      const farms = this.$store.getters.filteredFarms;
      if (farms.length === 0) {
        return;
      }

      let colorIndex = 0;
      for (let j = 0; j < farms.length; j++) {
        colorIndex++;
        colorIndex = colorIndex > 27 ? 0 : colorIndex;

        const farmColor = farms[j].markup.labelColor
          ? farms[j].markup.labelColor
          : qColors[colorIndex];

        const center = this.calculateFarmCenter(farms[j].gps);
        if (!center) {
          continue;
        }

        const farmGps = farms[j].gps;
        for (let i = 0; i < farmGps.ponds.length; i++) {
          const loc = SplitGps(farmGps.ponds[i].gps);
          const pond = farmGps.ponds[i];

          if (loc === null) {
            continue;
          }

          if (this.showHB20 && farmGps.ponds[i].hb20 === 1) {
            const hb20 = farmGps.ponds[i];

            if (!this.checkInBounds(mapBounds, loc.lat, loc.lng)) {
              continue;
            }
            this.markerAddHB20(hb20, loc, farmColor);
            continue;
          }

          if (zoomLevel > 11 && zoomLevel < 15) {
            if (this.pondMarkerIds.includes(pond.id)) {
              continue;
            }
            if (!this.checkInBounds(mapBounds, loc.lat, loc.lng)) {
              continue;
            }
            this.markerAddPond(pond, loc, farmColor);
          }

          if (zoomLevel > 14) {
            if (this.pondLabelIds.includes(pond.id)) {
              continue;
            }
            if (!this.checkInBounds(mapBounds, loc.lat, loc.lng)) {
              continue;
            }
            this.markerAddPondLabel(pond, loc, farmColor);
          }
        }

        if (zoomLevel > 11) {
          for (let i = 0; i < farmGps.computers.length; i++) {
            const loc = SplitGps(farmGps.computers[i].gps);
            const computer = farmGps.computers[i];

            if (loc === null) {
              continue;
            }

            if (!this.checkInBounds(mapBounds, loc.lat, loc.lng)) {
              continue;
            }

            if (this.showHopping) {
              let coord = { lat: 90.0, lng: 135.0 };

              const dist = this.getDistance(
                coord.lat,
                coord.lng,
                loc.lat,
                loc.lng
              );
              if (dist > 7000) {
                coord = loc;
                if (!this.rfCircleIds.includes(computer.id)) {
                  this.markerAddRFChannel(computer, loc, farmColor);
                }
              }
            }

            if (this.computerMarkerIds.includes(computer.id)) {
              continue;
            }
            this.markerAddComputer(computer, loc, farmColor);
          }
        }

        if (zoomLevel > 11) {
          for (let i = 0; i < farmGps.repeaters.length; i++) {
            const loc = SplitGps(farmGps.repeaters[i].gps);
            const repeater = farmGps.repeaters[i];

            if (loc === null) {
              continue;
            }
            if (!this.checkInBounds(mapBounds, loc.lat, loc.lng)) {
              continue;
            }

            if (this.showHopping) {
              let coord = { lat: 90.0, lng: 135.0 };

              const dist = this.getDistance(
                coord.lat,
                coord.lng,
                loc.lat,
                loc.lng
              );
              if (dist > 7000) {
                coord = loc;
                if (!this.rfCircleIds.includes(repeater.id)) {
                  this.markerAddRFChannel(repeater, loc, farmColor);
                }
              }
            }

            if (this.repeaterMarkerIds.includes(repeater.id)) {
              continue;
            }
            this.markerAddRepeater(repeater, loc, farmColor);
          }
        }

        if (this.showHopping && zoomLevel > 11) {
          for (const line of farms[j].markup.repeaterLines) {
            this.markerAddRepeaterLine(farms[j], line, farmColor);
          }
        }

        if (!this.checkInBounds(mapBounds, center.lat, center.lng)) {
          continue;
        }

        if (zoomLevel > 10) {
          this.markerAddFarmLabel(farms[j], center, farmColor);
        }

        if (zoomLevel < 11) {
          this.markerAddFarmMarker(farms[j], center, farmColor);
        }
      }
    },
    limitToggle(caller) {
      if (caller === 'showHB20') {
        this.showTodo = false;
        this.showHopping = false;
      }

      if (caller === 'showTodo') {
        this.showHB20 = false;
        this.showHopping = false;
      }

      if (caller === 'showHopping') {
        this.showHB20 = false;
        this.showTodo = false;
      }

      this.throttle(this.loadMapMarkers, this.removeAll);
    },
    markerAddComputer(computer, loc, farmColor) {
      if (!this.computerMarkerIds.includes(computer.id)) {
        const markerEl = document.createElement('div');
        markerEl.innerHTML = this.showHopping
          ? this.stringBuilderIconWithHopping(computer, 'computer', farmColor)
          : this.stringBuilderIcon('computer', farmColor);

        const marker = new this.google.advancedMarkerEl({
          map: this.map,
          position: { lat: loc.lat, lng: loc.lng },
          content: markerEl,
          zIndex: 1
        });

        this.computerMarkers.push(marker);
        this.computerMarkerIds.push(computer.id);
      }
    },
    markerAddFarmLabel(farm, center, farmColor) {
      const addLabel = (pos) => {
        const idStr = farm.id.toString() + pos.lat.toString();
        if (!this.farmLabelIds.includes(idStr)) {
          let showTodo = false;
          let zIndex = 10;
          if (this.showTodo && this.todoIds.includes(farm.id)) {
            showTodo = true;
            zIndex = 20;
          }

          const markerEl = document.createElement('div');
          markerEl.innerHTML = this.stringBuilderFarmLabel(
            farm,
            showTodo,
            farmColor
          );

          const marker = new this.google.advancedMarkerEl({
            map: this.map,
            position: { lat: pos.lat, lng: pos.lng },
            content: markerEl,
            zIndex
          });

          const markerObj = {};
          markerObj.clickListener = marker.addListener('click', (event) => {
            this.goToFarmMap(farm.id);
          });

          this.farmLabels.push({ marker, markerObj });
          this.farmLabelIds.push(idStr);
        }
      };

      if ((farm.markup?.labelPositions?.length ?? 0) === 0) {
        addLabel(center);
      } else {
        for (let i = 0; i < farm.markup.labelPositions.length; i++) {
          const pos = farm.markup.labelPositions[i];
          addLabel(pos);
        }
      }
    },
    markerAddFarmMarker(farm, center, farmColor) {
      if (!this.farmMarkerIds.includes(farm.id)) {
        const el = document.createElement('div');
        el.innerHTML = '<i class="fa fa-flag" style="font-size: 16px;"></i>';

        let borderColor = '#000000';
        let glyphColor = '#eeeeee';
        let scale = 1.2;
        let zIndex = 10;
        if (this.showTodo && this.todoIds.includes(farm.id)) {
          el.innerHTML = `<i class="q-icon notranslate material-icons"
            style="font-size: 20px;">assignment_late</i>`;
          borderColor = '#d50000';
          scale = 2.2;
          zIndex = 20;
        }

        const pin = new this.google.pinEl({
          glyph: el,
          glyphColor,
          background: farmColor,
          borderColor,
          scale
        });

        const marker = new this.google.advancedMarkerEl({
          map: this.map,
          position: { lat: center.lat, lng: center.lng },
          content: pin.element,
          zIndex
        });

        const markerObj = {};
        markerObj.clickListener = marker.addListener('click', (event) => {
          this.goToFarmMap(farm.id);
        });

        this.farmMarkers.push({ marker, markerObj });
        this.farmMarkerIds.push(farm.id);
      }
    },
    markerAddHB20(hb20, loc, farmColor) {
      const el = document.createElement('div');
      el.innerHTML = `<i class="q-icon notranslate material-icons"
            style="font-size: 20px;">location_searching</i>`;

      const pin = new this.google.pinEl({
        glyph: el,
        glyphColor: '#eeeeee',
        background: farmColor,
        borderColor: '#000000',
        scale: 2.2
      });

      const marker = new this.google.advancedMarkerEl({
        map: this.map,
        position: { lat: loc.lat, lng: loc.lng },
        content: pin.element,
        zIndex: 30
      });

      this.hb20MarkerIds.push(hb20.id);
      this.hb20Markers.push(marker);
    },
    markerAddPond(pond, loc, farmColor) {
      const markerEl = document.createElement('div');
      markerEl.innerHTML = `<div class="pond-dot"
        style="background-color: ${farmColor}"></div>`;

      const marker = new this.google.advancedMarkerEl({
        map: this.map,
        position: { lat: loc.lat, lng: loc.lng },
        content: markerEl,
        zIndex: 0.7
      });

      this.pondMarkerIds.push(pond.id);
      this.pondMarkers.push(marker);
    },
    markerAddPondLabel(pond, loc, farmColor) {
      const markerEl = document.createElement('div');
      markerEl.innerHTML = `<div
        class="marker-el text-black cursor-pointer rounded-borders"
        style="width: 100%; padding: 5px; background-color: ${farmColor}">
        <div class="bg-white rounded-borders"
        style="padding: 2px; display: table-caption; font-size: 10px">
        ${pond.name}</div></div>`;

      const marker = new this.google.advancedMarkerEl({
        map: this.map,
        position: { lat: loc.lat, lng: loc.lng },
        content: markerEl,
        zIndex: 0.7
      });

      this.pondLabels.push(marker);
      this.pondLabelIds.push(pond.id);
    },
    markerAddRFChannel(item, loc, farmColor) {
      const circle = new window.google.maps.Circle({
        strokeColor: farmColor,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: farmColor,
        fillOpacity: 0.3,
        map: this.map,
        center: loc,
        radius: this.hoppingRadius * 1609, // mi to meters
        id: item.id
      });
      this.rfCircles.push(circle);
      this.rfCircleIds.push(item.id);
    },
    markerAddRepeater(repeater, loc, farmColor) {
      if (!this.repeaterMarkerIds.includes(repeater.id)) {
        const markerEl = document.createElement('div');
        markerEl.innerHTML = this.showHopping
          ? this.stringBuilderIconWithHopping(repeater, 'campaign', farmColor)
          : this.stringBuilderIcon('campaign', farmColor);

        const marker = new this.google.advancedMarkerEl({
          map: this.map,
          position: { lat: loc.lat, lng: loc.lng },
          content: markerEl,
          zIndex: 1
        });

        this.repeaterMarkers.push(marker);
        this.repeaterMarkerIds.push(repeater.id);
      }
    },
    markerAddRepeaterLine(farm, line, farmColor) {
      const idStr = farm.id.toString() + line.start.lat.toString();
      if (!this.repeaterLineIds.includes(idStr)) {
        const repeaterLine = new window.google.maps.Polyline({
          path: [line.start, line.end],
          geodesic: true,
          strokeColor: farmColor,
          strokeWeight: 10
        });

        repeaterLine.setMap(this.map);
        this.repeaterLines.push(repeaterLine);
        this.repeaterLineIds.push(idStr);
      }
    },
    removeAll() {
      for (let i = 0; i < this.farmLabels.length; i++) {
        this.farmLabels[i].marker.map = null;
        window.google.maps.event.removeListener(
          this.farmLabels[i].markerObj.clickListener
        );
      }
      this.farmLabels.splice(0);
      this.farmLabelIds.splice(0);

      for (let i = 0; i < this.farmMarkers.length; i++) {
        window.google.maps.event.removeListener(
          this.farmMarkers[i].markerObj.clickListener
        );
        this.farmMarkers[i].marker.map = null;
      }
      this.farmMarkers.splice(0);
      this.farmMarkerIds.splice(0);

      for (let i = 0; i < this.pondMarkers.length; i++) {
        this.pondMarkers[i].map = null;
      }
      this.pondMarkers.splice(0);
      this.pondMarkerIds.splice(0);

      for (let i = 0; i < this.pondLabels.length; i++) {
        this.pondLabels[i].map = null;
      }
      this.pondLabels.splice(0);
      this.pondLabelIds.splice(0);

      for (let i = 0; i < this.computerMarkers.length; i++) {
        this.computerMarkers[i].map = null;
      }
      this.computerMarkers.splice(0);
      this.computerMarkerIds.splice(0);

      for (let i = 0; i < this.repeaterMarkers.length; i++) {
        this.repeaterMarkers[i].map = null;
      }
      this.repeaterMarkers.splice(0);
      this.repeaterMarkerIds.splice(0);

      for (let i = 0; i < this.repeaterLines.length; i++) {
        this.repeaterLines[i].setMap(null);
      }
      this.repeaterLines.splice(0);
      this.repeaterLineIds.splice(0);

      for (let i = 0; i < this.hb20Markers.length; i++) {
        this.hb20Markers[i].map = null;
      }
      this.hb20Markers.splice(0);
      this.hb20MarkerIds.splice(0);

      for (let i = 0; i < this.rfCircles.length; i++) {
        this.rfCircles[i].setMap(null);
      }
      this.rfCircles.splice(0);
    },
    removeSmart() {
      // Removes only markers that are not in mapBounds
      const mapBounds = this.map.getBounds();
      const zoomLevel = this.map.getZoom();

      // Remove Farm Labels
      const tmpFarmLabels = [];
      const tmpFarmIds = [];
      for (let i = 0; i < this.farmLabels.length; i++) {
        const lat = this.farmLabels[i].marker.position.lat;
        const lng = this.farmLabels[i].marker.position.lng;

        if (this.checkInBounds(mapBounds, lat, lng) && zoomLevel > 10) {
          tmpFarmLabels.push(this.farmLabels[i]);
          tmpFarmIds.push(this.farmLabelIds[i]);
          continue;
        }
        this.farmLabels[i].marker.map = null;
        window.google.maps.event.removeListener(
          this.farmLabels[i].markerObj.clickListener
        );
      }
      this.farmLabels = tmpFarmLabels;
      this.farmLabelIds = tmpFarmIds;

      // Remove Farm Markers
      const tmpFarmMarkers = [];
      const tmpFarmMarkerIds = [];
      for (let i = 0; i < this.farmMarkers.length; i++) {
        const lat = this.farmMarkers[i].marker.position.lat;
        const lng = this.farmMarkers[i].marker.position.lng;

        if (this.checkInBounds(mapBounds, lat, lng) && zoomLevel < 11) {
          tmpFarmMarkers.push(this.farmMarkers[i]);
          tmpFarmMarkerIds.push(this.farmMarkerIds[i]);
          continue;
        }
        this.farmMarkers[i].marker.map = null;
        window.google.maps.event.removeListener(
          this.farmMarkers[i].markerObj.clickListener
        );
      }
      this.farmMarkers = tmpFarmMarkers;
      this.farmMarkerIds = tmpFarmMarkerIds;

      // Remove Ponds
      const tmpPondMarkers = [];
      const tmpPondIds = [];
      for (let i = 0; i < this.pondMarkers.length; i++) {
        const lat = this.pondMarkers[i].position.lat;
        const lng = this.pondMarkers[i].position.lng;

        if (
          this.checkInBounds(mapBounds, lat, lng) &&
          zoomLevel > 11 &&
          zoomLevel < 15
        ) {
          tmpPondMarkers.push(this.pondMarkers[i]);
          tmpPondIds.push(this.pondMarkerIds[i]);
          continue;
        }
        this.pondMarkers[i].map = null;
      }
      this.pondMarkers = tmpPondMarkers;
      this.pondMarkerIds = tmpPondIds;

      // Remove Pond Labels
      const tmpPondLabels = [];
      const tmpPondLabelIds = [];
      for (let i = 0; i < this.pondLabels.length; i++) {
        const lat = this.pondLabels[i].position.lat;
        const lng = this.pondLabels[i].position.lng;

        if (this.checkInBounds(mapBounds, lat, lng) && zoomLevel > 14) {
          tmpPondLabels.push(this.pondLabels[i]);
          tmpPondLabelIds.push(this.pondLabelIds[i]);
          continue;
        }
        this.pondLabels[i].map = null;
      }
      this.pondLabels = tmpPondLabels;
      this.pondLabelIds = tmpPondLabelIds;

      // remove Computers
      const tmpCompMarkers = [];
      const tmpCompIds = [];
      for (let i = 0; i < this.computerMarkers.length; i++) {
        const lat = this.computerMarkers[i].position.lat;
        const lng = this.computerMarkers[i].position.lng;

        if (this.checkInBounds(mapBounds, lat, lng) && zoomLevel > 11) {
          tmpCompMarkers.push(this.computerMarkers[i]);
          tmpCompIds.push(this.computerMarkerIds[i]);
          continue;
        }
        this.computerMarkers[i].map = null;
      }
      this.computerMarkers = tmpCompMarkers;
      this.computerMarkerIds = tmpCompIds;

      // remove Repeaters
      const tmpRepeaterMarkers = [];
      const tmpRepeaterIds = [];
      for (let i = 0; i < this.repeaterMarkers.length; i++) {
        const lat = this.repeaterMarkers[i].position.lat;
        const lng = this.repeaterMarkers[i].position.lng;

        if (this.checkInBounds(mapBounds, lat, lng) && zoomLevel > 11) {
          tmpRepeaterMarkers.push(this.repeaterMarkers[i]);
          tmpRepeaterIds.push(this.repeaterMarkerIds[i]);
          continue;
        }
        this.repeaterMarkers[i].map = null;
      }
      this.repeaterMarkers = tmpRepeaterMarkers;
      this.repeaterMarkerIds = tmpRepeaterIds;

      // remove Repeater Lines
      const tmpRepeaterLines = [];
      const tmpRepeaterLineIds = [];
      for (let i = 0; i < this.repeaterLines.length; i++) {
        const lineArr = this.repeaterLines[i].getPath().getArray();

        if (zoomLevel > 12 && this.showHopping) {
          tmpRepeaterLines.push(this.repeaterLines[i]);
          tmpRepeaterLineIds.push(this.repeaterLineIds[i]);
          continue;
        }
        this.repeaterLines[i].setMap(null);
      }
      this.repeaterLines = tmpRepeaterLines;
      this.repeaterLineIds = tmpRepeaterLineIds;

      // remove hb20
      const tmpHB20Markers = [];
      const tmpHB20Ids = [];
      for (let i = 0; i < this.hb20Markers.length; i++) {
        const lat = this.hb20Markers[i].position.lat;
        const lng = this.hb20Markers[i].position.lng;

        if (this.checkInBounds(mapBounds, lat, lng)) {
          tmpHB20Markers.push(this.hb20Markers[i]);
          tmpHB20Ids.push(this.hb20Markers[i].pondId);
          continue;
        }
        this.hb20Markers[i].map = null;
      }
      this.hb20Markers = tmpHB20Markers;
      this.hb20MarkerIds = tmpHB20Ids;

      // remove rfCircles
      const tmpRFCircles = [];
      const tmpRFCircleIds = [];
      for (let i = 0; i < this.rfCircles.length; i++) {
        const lat = this.rfCircles[i].center.lat();
        const lng = this.rfCircles[i].center.lng();

        if (this.checkInBounds(mapBounds, lat, lng) && zoomLevel > 11) {
          tmpRFCircles.push(this.rfCircles[i]);
          tmpRFCircleIds.push(this.rfCircles[i].id);
          continue;
        }
        this.rfCircles[i].setMap(null);
      }
      this.rfCircles = tmpRFCircles;
      this.rfCircleIds = tmpRFCircleIds;
    },
    setRadius() {
      this.$q
        .dialog({
          title: 'Hopping Channel Radius',
          message: 'Radius In Miles',
          prompt: {
            model: this.hoppingRadius,
            type: 'number'
          },
          cancel: true,
          persistent: true
        })
        .onOk((data) => {
          this.hoppingRadius = data;
          this.throttle(this.loadMapMarkers, this.removeAll);
        });
    },
    stringBuilderIcon(icon, farmColor) {
      return `<div class="text-black rounded-borders"
        style="width: 100%; padding: 4px; background-color: ${farmColor}">
        <div class="bg-white rounded-borders" style="padding: 0px 1px 0px 1px">
        <i class="q-icon notranslate material-icons">${icon}</i>
        </div></div>`;
    },
    stringBuilderIconWithHopping(item, icon, farmColor) {
      return `<div class="text-black rounded-borders" style="width: 100%;
        padding: 4px; background-color: ${farmColor}">
        <div class="bg-white rounded-borders" style="padding: 0px 1px 0px 1px">
        <i class="q-icon notranslate material-icons">${icon}</i>
        <br>${item.radio_channel ?? 'X'} - ${item.vid ?? 'X'}
        </div></div>`;
    },
    stringBuilderFarmLabel(farm, showTodo, farmColor) {
      let todoStr = '';
      if (showTodo) {
        todoStr = `<i class="q-icon notranslate material-icons text-red-14 full-width"
        style="font-size: 24px">assignment_late</i>`;
      }

      return `<div class="marker-el text-black cursor-pointer rounded-borders"
        style="width: 100%; padding: 5px; background-color: ${farmColor}">
        <div class="bg-white rounded-borders" style="padding: 2px; display: table-caption">
        ${todoStr}${farm.farm_name}</div></div>`;
    },
    throttle(callback, removeFn) {
      if (this.throttled) {
        return;
      }

      this.throttled = true;

      setTimeout(() => {
        callback(removeFn);
        this.throttled = false;
      }, 300);
    },
    zoomIntoFarm() {
      if (this.zoomed) {
        return;
      }

      this.zoomed = true;
      const farmMapId = parseInt(this.$store.state.farmMapId);

      if (!farmMapId) {
        return;
      }
      this.$store.dispatch('goToFarmOnMap', null);

      const farms = this.$store.getters.filteredFarms;
      const farm = farms.find((x) => x.id === farmMapId);
      const center = this.calculateFarmCenter(farm.gps);
      this.map.setCenter(center);
      this.map.setZoom(14);
    }
  },
  computed: {
    todoIds() {
      const filteredTodos = this.$store.state.todos.filter(
        (e) => e.status !== 'Complete'
      );

      return filteredTodos.map((todo) => todo.farm_id);
    }
  },
  beforeDestroy() {
    this.removeAll();
  },
  watch: {
    '$store.getters.filteredFarms': {
      deep: true,
      handler() {
        this.throttle(this.loadMapMarkers, this.removeAll);
      }
    },
    '$store.state.todos': {
      deep: true,
      handler() {
        if (this.showTodo) {
          this.throttle(this.loadMapMarkers, this.removeAll);
        }
      }
    }
  }
};
</script>

<style scoped>
#mapCanvas {
  text-align: center;
  height: calc(100vh - 120px);
  width: 95%;
  margin: auto;
}

.flexbody {
  flex: 1;
  height: 60%;
  border: 3px solid black;
}

.contact-card {
  width: 100%;
  max-width: 350px;
}
</style>

<!--Not Scoped for map-->
<style>
.pond-dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  border: 2px solid #000000;
}
</style>
