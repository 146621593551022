<template>
  <TodoPlugin :farm-id="farmId" :farm-only="true" />
</template>

<script>
import TodoPlugin from '@/components/TodoPlugin';

export default {
  name: 'Farm-Todos',
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  components: {
    TodoPlugin
  }
};
</script>
