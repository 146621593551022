const decoder = function (str) {
  return new DOMParser().parseFromString(str, 'text/html').body.textContent;
};

const trimName = (name) => {
  const arrName = name.split(' ');
  return arrName[0] + ' ' + arrName[1]?.charAt(0) ?? '';
};

const chatInfo = (chats, currUserId) => {
  let numUnread = 0;
  let numStarred = 0;
  let isPartOfChat = false;
  let latestMsgTs = 0;
  for (let i = 0; i < chats.length; i++) {
    latestMsgTs = chats[i].time > latestMsgTs ? chats[i].time : latestMsgTs;

    for (let j = 0; j < chats[i].chatGroup.length; j++) {
      if (chats[i].chatGroup[j].userId === currUserId) {
        isPartOfChat = true;

        if (chats[i].chatGroup[j].viewed === 0) {
          numUnread++;
        }

        if (chats[i].chatGroup[j].viewed === 2) {
          numStarred++;
        }
      }
    }
  }
  return { isPartOfChat, numUnread, numStarred, latestMsgTs };
};

const countUnread = (chats, currUserId) => {
  let unread = 0;
  for (let i = 0; i < chats.length; i++) {
    for (let j = 0; j < chats[i].chatGroup.length; j++) {
      if (
        !chats[i].chatGroup[j].viewed &&
        chats[i].chatGroup[j].userId === currUserId
      ) {
        unread++;
      }
    }
  }
  return unread;
};

const countUnreadAll = (items, currUserId) => {
  let numUnread = 0;
  let numStarred = 0;
  const unreadArr = [];
  for (let i = 0; i < items.length; i++) {
    const chats = items[i].chats;
    for (let j = 0; j < chats.length; j++) {
      for (let k = 0; k < chats[j].chatGroup.length; k++) {
        if (chats[j].chatGroup[k].userId === currUserId) {
          if (chats[j].chatGroup[k].viewed === 0) {
            numUnread++;
            unreadArr.push(chats[j].id);
          }

          if (chats[j].chatGroup[k].viewed === 2) {
            numStarred++;
          }
        }
      }
    }
  }
  return { numUnread, numStarred, unreadArr };
};

/**
 *
 * @param value
 * @returns {null|{lng: number, lat: number}}
 * @constructor
 */
function SplitGps(value) {
  if (!value || value === '' || value === null) {
    return null;
  }
  try {
    let lats = '';
    let lngs = '';
    let a;
    for (a = 0; a < value.length; a++) {
      if (value[a] === ',') {
        a++;
        break;
      } else {
        if (
          (value[a] >= '0' && value[a] <= '9') ||
          value[a] === '.' ||
          value[a] === '-'
        ) {
          lats += value[a];
        }
      }
    }
    for (let b = a; b < value.length; b++) {
      if (value[b] === ',') {
        break;
      } else {
        if (
          (value[b] >= '0' && value[b] <= '9') ||
          value[b] === '.' ||
          value[b] === '-'
        ) {
          lngs += value[b];
        }
      }
    }
    const lat = Number(lats);
    const lon = Number(lngs);
    if (lat === 0 || lon === 0) {
      return null;
    }
    return {
      lat: lat,
      lng: lon
    };
  } catch (err) {
    return null;
  }
}

const listReminders = (todos, workLogs, currUserId) => {
  const remindNow = [];
  const upcoming = [];
  const upcomingTimestamps = [];
  const pinned = [];
  for (let i = 0; i < todos.length; i++) {
    for (let j = 0; j < todos[i].reminders.length; j++) {
      if (todos[i].reminders[j].reminderRecipient === currUserId) {
        const rDate = todos[i].reminders[j].reminderDate;
        const now = +new Date();
        const triggerDate = +new Date() + 86400000 * 3; // 3 days

        if (rDate < now) {
          if (todos[i].reminders[j].pinned) {
            pinned.push(todos[i]);
          } else {
            remindNow.push(todos[i]);
          }
        } else if (rDate < triggerDate) {
          upcoming.push(todos[i]);
          upcomingTimestamps.push(rDate);
        }
      }
    }
  }

  for (let i = 0; i < workLogs.length; i++) {
    for (let j = 0; j < workLogs[i].reminders.length; j++) {
      if (workLogs[i].reminders[j].reminderRecipient === currUserId) {
        const rDate = workLogs[i].reminders[j].reminderDate;
        const now = +new Date();
        const triggerDate = +new Date() + 86400000 * 3; // 3 days

        if (rDate < now) {
          if (workLogs[i].reminders[j].pinned) {
            pinned.push(workLogs[i]);
          } else {
            remindNow.push(workLogs[i]);
          }
        } else if (rDate < triggerDate) {
          upcoming.push(workLogs[i]);
          upcomingTimestamps.push(rDate);
        }
      }
    }
  }

  return {
    pinned,
    remindNow,
    upcoming,
    upcomingTimestamps
  };
};

const cloneObj = (obj) => {
  if (obj === null || typeof obj !== 'object' || 'isActiveClone' in obj)
    return obj;

  let temp;
  if (obj instanceof Date) {
    temp = new obj.constructor(); // Creates a new Date object
  } else {
    temp = obj.constructor();
  }

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj['isActiveClone'] = null;
      temp[key] = cloneObj(obj[key]);
      delete obj['isActiveClone'];
    }
  }
  return temp;
};

const movePond = (Vue, state, moveObj) => {
  if (!moveObj.move) {
    return;
  }

  let pond;
  let workLogs = [];
  for (let i = 0; i < state.farmList.length; i++) {
    if (state.farmList[i].id === moveObj.farmFrom) {
      const pondIndex = state.farmList[i].gps.ponds.findIndex(
        (x) => x.id === moveObj.pondId
      );
      pond = state.farmList[i].gps.ponds.splice(pondIndex, 1);
      break;
    }
  }

  for (let i = 0; i < state.farmList.length; i++) {
    if (state.farmList[i].id === moveObj.farmTo) {
      pond[0].moved = moveObj;
      state.farmList[i].gps.ponds.push(pond[0]);
      break;
    }
  }

  for (let i = 0; i < state.workLogs.length; i++) {
    if (moveObj.workLogIds.includes(state.workLogs[i].id)) {
      workLogs.push(state.workLogs[i]);
      Vue.set(state.workLogs[i], 'farm_id', moveObj.farmTo);
    }
  }

  if (parseInt(state.farm.id) === moveObj.farmFrom) {
    const index = state.farm.ponds.findIndex((x) => x.id === moveObj.pondId);

    state.farm.ponds.splice(index, 1);

    state.farm.logs = state.farm.logs.filter(
      (x) => !moveObj.workLogIds.includes(x.id)
    );
  }
  if (parseInt(state.farm.id) === moveObj.farmTo) {
    state.farm.ponds.push(pond[0]);
    workLogs.forEach((x) => state.farm.logs.push(x));
  }
};

const getReminderStatus = (reminders, state) => {
  let hasReminder = false;
  let userReminder = null;
  let remindNow = false;
  if (reminders.length) {
    if (state.user.aerworx_level === 'super-admin') {
      hasReminder = true;
    }

    userReminder = reminders.find(
      (x) => x.reminderRecipient === state.user.user_id
    );

    const now = +new Date();
    remindNow = userReminder ? userReminder.reminderDate < now : false;
    userReminder = userReminder ? true : false;

    if (state.user.aerworx_level !== 'super-admin') {
      hasReminder = userReminder;
    }
  }

  return { hasReminder, userReminder, remindNow };
};

const createHyperLink = (text) => {
  if (!text) {
    return '';
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
};

const smartCompare = (a, b) => {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
};

const checkPondNotInUse = (pondName) => {
  const pondNameLower = pondName.toLowerCase();

  // Jalon's code names for not in use ponds
  return (
    pondNameLower.endsWith('royce') ||
    pondNameLower.endsWith('archived') ||
    pondNameLower.endsWith('rebuilding') ||
    pondNameLower.endsWith('self service')
  );
};

module.exports = {
  decoder,
  trimName,
  chatInfo,
  countUnread,
  countUnreadAll,
  SplitGps,
  listReminders,
  cloneObj,
  movePond,
  getReminderStatus,
  createHyperLink,
  smartCompare,
  checkPondNotInUse
};
