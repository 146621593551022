<template>
  <div>
    <router-view :farmId="farmId"></router-view>
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'pond-root',
  data() {
    return {
      timer: null
    };
  },
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.subscribe();
  },
  methods: {
    subscribe() {
      store.dispatch('subscribeFarm', this.farmId).then((results) => {
        if (results === false) {
          this.timer = setTimeout(this.subscribe, 250);
        } else {
          this.timer = null;
        }
      });
    },
    unSubscribe() {
      store.dispatch('unSubscribeFarm', this.farmId).then((results) => {
        if (results === false) {
          this.timer = setTimeout(this.unSubscribe, 250);
        } else {
          this.timer = null;
        }
      });
    }
  },
  beforeDestroy() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.unSubscribe();
  }
};
</script>
