<template>
  <div>
    <q-table
      title="Users"
      :data="Users"
      :columns="columns"
      :pagination.sync="pagination"
      no-data-label="Data loading"
      row-key="name"
    >
      <template v-slot:top></template>
      <template v-slot:body-cell-Chat="props">
        <q-td :props="props">
          <q-btn-toggle
            size="sm"
            padding="xs"
            glossy
            :disable="lockedChatAuth"
            :value="props.row.chatAuth"
            :toggle-color="props.row.chatAuth ? 'positive' : 'negative'"
            :options="[
              { slot: 'chatAuth', value: true },
              { slot: 'chatNotAuth', value: false }
            ]"
            @input="toggleChatUser(props.row)"
          >
            <template v-slot:chatAuth>
              <q-icon name="chat" size="sm" />
            </template>
            <template v-slot:chatNotAuth>
              <q-icon name="speaker_notes_off" size="sm" />
            </template>
          </q-btn-toggle>
        </q-td>
      </template>
      <template v-slot:body-cell-Farms="props">
        <q-td :props="props">
          <q-btn
            color="primary"
            v-if="
              props.row.user_role === 'installer' && props.row.farms.length > 0
            "
            @click="showUserFarmsClick(props.row)"
          >
            {{ props.row.farms.length }}
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-Edit="props">
        <q-td :props="props">
          <q-btn
            v-if="props.row.user_role === 'installer'"
            class="btn-table-primary"
            outline
            icon="edit"
            size="md"
            padding="xs"
            @click="editUser(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <!-- Lock sticky-->
    <template v-if="!lockedChatAuth">
      <q-page-sticky position="top-right" :offset="[28, 28]">
        <q-btn
          fab
          icon="lock_open"
          color="negative"
          @click="lockedChatAuth = true"
        />
      </q-page-sticky>
    </template>
    <template v-if="lockedChatAuth">
      <q-page-sticky position="top-right" :offset="[28, 28]">
        <q-btn fab icon="lock" color="primary" @click="unlockChatUpdates" />
      </q-page-sticky>
    </template>
    <!-- note display-->
    <q-dialog v-model="showUserFarms">
      <q-card style="min-width: 300px">
        <q-card-section>
          <div class="text-h6">{{ userName(shownUser.user_id) }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div v-for="(farm, index) in userFarmList()" :key="index">
            {{ decoder(farm.farm_name) }}
          </div>
        </q-card-section>
        <q-separator />
        <q-separator />
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import store from '@/store';

export default {
  name: 'Users',
  data() {
    return {
      showUserFarms: false,
      shownUser: {
        user_id: -1,
        farms: []
      },
      userSearch: '',
      lockedChatAuth: true,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          required: true,
          sortable: true,
          name: 'Name',
          label: 'Name',
          align: 'left',
          field: (row) => decoder(row.display_name)
        },
        {
          required: true,
          sortable: true,
          name: 'Level',
          label: 'Level',
          align: 'left',
          field: (row) => decoder(row.user_role)
        },
        {
          required: true,
          sortable: true,
          name: 'Chat',
          label: 'Chat',
          align: 'left',
          field: (row) => true,
          sort: (a, b, rowA, rowB) => {
            return rowA.chatAuth === rowB.chatAuth ? 0 : rowA.chatAuth ? -1 : 1;
          }
        },
        {
          required: true,
          sortable: true,
          name: 'Farms',
          label: 'Farms',
          align: 'left',
          field: (row) => (row.farms ? row.farms.length : 0)
        },
        {
          required: true,
          name: 'Edit',
          label: '',
          align: 'center',
          classes: 'max-width'
        }
      ]
    };
  },
  methods: {
    dispatchChatUpdate(userId, isUpdate) {
      store
        .dispatch('updateChatUser', {
          userId,
          isUpdate
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchChatUpdate(userId, isUpdate);
            }
          });
        });
    },
    toggleChatUser(user) {
      const isUpdate = user.chatAuth;
      this.dispatchChatUpdate(user.user_id, isUpdate);
    },
    showUserFarmsClick(user) {
      this.showUserFarms = true;
      this.shownUser = user;
    },
    editUser(user) {
      this.$router.push(`users/${user.user_id}/`);
    },
    farmName(id) {
      if (id === null) {
        return '--';
      }
      const farm = this.$store.state.farmList.find(
        (element) => element.id === id
      );
      return farm ? farm.farm_name : '---';
    },
    userName(id) {
      if (id === null) {
        return '--';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '- - - -';
    },
    unlockChatUpdates() {
      this.$q
        .dialog({
          title: 'Confirm Unlock',
          message: 'Enable chat updates?',
          cancel: true,
          persistent: true
        })
        .onOk(() => {
          this.lockedChatAuth = false;
        });
    },
    parseTimeStamp(value) {
      return parseTimeStamp(value, 'short');
    },
    decoder(val) {
      return decoder(val);
    },
    userFarmList() {
      const list = [];
      for (let i = 0; i < this.shownUser.farms.length; i++) {
        const farm = this.$store.state.farmList.find(
          (e) => e.id === this.shownUser.farms[i]
        );
        if (farm) {
          list.push(JSON.parse(JSON.stringify(farm)));
        }
      }
      list.sort(function (a, b) {
        return a.farm_name.localeCompare(b.farm_name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return list;
    }
  },
  computed: {
    Users() {
      const list = JSON.parse(JSON.stringify(this.$store.state.userList));
      list.sort(function (a, b) {
        return a.display_name.localeCompare(b.display_name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      return list;
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      list.push({
        label: '---',
        value: null
      });
      return list;
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 750px) {
  .spaced {
    margin-left: 2px;
    margin-right: 2px;
  }

  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }
}

.max-width {
  max-width: 100px;
}
</style>
