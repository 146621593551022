const formatDate = function (date, format) {
  // 2014-12-30 04:00:00
  let value = ('0' + (date.getMonth() + 1)).slice(-2) + '/';
  value += ('0' + date.getDate()).slice(-2) + '/';
  if (format === 'shortExcel') {
    return value + date.getFullYear();
  }
  value += (' ' + date.getFullYear()).slice(-2) + ' ';
  if (format === 'short') {
    return value;
  }
  value += ('0' + date.getHours()).slice(-2) + ':';
  value += ('0' + date.getMinutes()).slice(-2);
  return value;
};

const parseTimeStamp = function (value, optFormat) {
  return formatDate(new Date(value * 1000), optFormat);
};

module.exports = {
  parseTimeStamp
};
