<template>
  <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
    <q-table
      title="Computers"
      :data="Computers"
      :columns="columns"
      :pagination.sync="pagination"
      row-key="id"
    >
      <template v-slot:body-cell-Name="props">
        <q-td :props="props">
          <q-tooltip anchor="center middle" self="center middle" :delay="1000">
            Computer ID:
            <br />
            {{ props.row.id }}
          </q-tooltip>
          <div class="q-ml-sm">
            {{ props.row.name }}
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Gps="props">
        <q-td :props="props">
          <q-icon
            v-if="validateGps(props.row.gps)"
            class="check-color"
            name="done"
            size="sm"
          ></q-icon>
        </q-td>
      </template>

      <template v-slot:body-cell-Key="props">
        <q-td :props="props">
          <template v-if="props.row.awKey !== ''">
            <q-btn flat padding="xs">
              <q-icon
                @click="ToClipboard(props.row.awKey)"
                style="margin-left: 2px"
                color="primary"
                name="content_copy"
                size="sm"
              >
              </q-icon>
            </q-btn>
            <a :href="generateLink(props.row.id)">
              <q-icon color="secondary" name="file_copy" size="sm" />
            </a>
          </template>
        </q-td>
      </template>

      <template v-slot:body-cell-Edit="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            icon="edit"
            size="md"
            padding="xs"
            @click="editComputer(props.row)"
          >
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Delete="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-negative"
            outline
            icon="delete"
            size="md"
            padding="xs"
            @click="deleteComputer(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
    </q-table>

    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addComputer" />
    </q-page-sticky>

    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="">
      <q-card square bordered class="q-pa-lg card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="computer" size="sm" class="q-mr-sm" />
                {{ ModalHeader }}
              </q-toolbar-title>
            </q-toolbar>
            <q-input
              filled
              v-model="editCopy.name"
              label="Name"
              maxlength="32"
              outlined
              square
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Required']"
            />
            <q-input
              filled
              v-model="editCopy.radio_channel"
              label="Hopping channel"
              type="number"
              min="0"
              max="9"
              outlined
              square
            />
            <q-input
              filled
              v-model="editCopy.vid"
              label="VID"
              outlined
              square
            />
            <q-select
              filled
              v-model="editCopy.gps"
              label="Location"
              maxlength="64"
              outlined
              square
            >
              <q-btn v-if="gpsAvailable" color="primary" @click="fillGps"
                >Use position</q-btn
              >
              <q-btn v-if="!gpsAvailable" disabled color="negative"
                >Not available</q-btn
              >
            </q-select>
            <q-separator />
            <q-input
              filled
              v-model="AerwareExpiration"
              type="date"
              label="Aerware expiration"
              outlined
              square
            />
            <template
              v-if="
                editCopy.id === 'new' && UnassignedAerwareOptions.length > 0
              "
            >
              <q-select
                v-model="filteredAerwareValue"
                square
                filled
                :options="UnassignedAerwareOptions"
                emit-value
                map-options
                label="Unassigned Aerware PC's"
              >
              </q-select>
            </template>
            <q-input
              filled
              v-model="editCopy.awId"
              maxlength="19"
              label="Aerware ID"
              mask="XXXX-XXXX-XXXX-XXXX"
              outlined
              square
            />
            <q-select
              v-model="editCopy.awType"
              square
              filled
              :options="['Bought', 'Leased', 'Evaluation']"
              emit-value
              map-options
              label="Category"
            />
            <q-toggle
              color="primary"
              v-model="editCopy.awIvr"
              label="IVR"
            ></q-toggle>
            <q-input
              filled
              v-model="editCopy.awKey"
              maxlength="47"
              label="Aerware License"
              mask="XXXXX-XXXXX-XXXXX-XXXXX-XXXXX-XXXXX-XXXXX-XXXXX"
              outlined
              square
            />
            <div style="margin-left: 8px">
              <q-btn
                outline
                class="full-width"
                @click="generateKey"
                color="primary"
                >Generate Key
              </q-btn>
            </div>
            <div align="right">
              <div class="row full-width">
                <div class="col spaced-l">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced-r">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
  </div>
</template>

<script>
import { parseTimeStamp } from '@/lib/date-utils';
import { decoder, SplitGps } from '@/lib/helpers';
import { date, copyToClipboard } from 'quasar';
import store from '@/store';

export default {
  name: 'Computers',
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      gpsAvailable: !!navigator.geolocation,
      showEditModal: false,
      editCopy: {
        name: '',
        awId: ''
      },
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          sortable: true,
          name: 'Name',
          label: 'Name',
          align: 'left',
          classes: 'table-highlight-column',
          field: (row) => decoder(row.name)
        },
        {
          sortable: true,
          name: 'RF Channel',
          label: 'RF Channel',
          align: 'center',
          field: (row) => row.radio_channel
        },
        {
          sortable: true,
          name: 'VID',
          label: 'VID',
          align: 'center',
          field: (row) => row.vid
        },
        {
          sortable: true,
          name: 'Aerware',
          label: 'Aerware',
          align: 'left',
          field: (row) => parseTimeStamp(row.awExp, 'short')
        },
        {
          sortable: true,
          name: 'Gps',
          label: 'Gps',
          align: 'center'
        },
        {
          name: 'Key',
          label: 'Key',
          align: 'center'
        },
        {
          name: 'Edit',
          label: '',
          align: 'center',
          classes: 'max-width'
        },
        {
          name: 'Delete',
          label: '',
          align: 'center',
          classes: 'max-width'
        }
      ]
    };
  },
  methods: {
    generateLink(id) {
      return `${window.location.origin}/api/aerware-key/${id}`;
    },
    ToClipboard(text) {
      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: 'License key copied to clipboard',
            color: 'primary'
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'License key copy error',
            color: 'negative'
          });
        });
    },
    validateGps(value) {
      return SplitGps(value) !== null;
    },
    generateKey() {
      if (this.editCopy.awId.length !== 19) {
        this.$q.notify({
          message: 'Invalid Aerware ID',
          color: 'negative'
        });
        return;
      }
      const farmId =
        this.$store.state.user.aerworx_level === 'super-admin'
          ? 'none'
          : this.farmId;
      store
        .dispatch('generateAerwareKey', {
          computer: this.editCopy,
          farmId
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'fail') {
              // No action needed
            } else if (response === 'retry') {
              this.generateKey(this.editCopy);
            } else {
              this.editCopy.awKey = results.data.awKey;
            }
          });
        });
    },
    onSubmit() {
      let dispatch;
      if (this.editCopy.id === 'new') {
        dispatch = 'createComputer';
      } else {
        dispatch = 'updateComputer';
      }
      store
        .dispatch(dispatch, {
          farmId: this.farmId,
          computer: this.editCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit();
            } else {
              this.showEditModal = false;
            }
          });
        });
    },
    fillGps() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.editCopy.gps =
              position.coords.latitude + ',' + position.coords.longitude;
          },
          () => {
            this.gpsAvailable = false;
          }
        );
      } else {
        this.gpsAvailable = false;
      }
    },
    editComputer(computer) {
      this.editCopy = JSON.parse(JSON.stringify(computer));
      this.showEditModal = true;
    },
    deleteComputer(computer) {
      this.$deleteResult
        .getResultsAsync('computer', computer.name, 'Delete computer?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(computer);
          }
        });
    },
    dispatchDelete(computer) {
      store
        .dispatch('deleteComputer', {
          farmId: this.farmId,
          computer
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(computer);
            }
          });
        });
    },
    addComputer() {
      this.editCopy = {
        farm_id: this.farmId,
        gps: '',
        id: 'new',
        awId: '',
        awExp: Date.now() / 1000,
        awIvr: true,
        name: '',
        radio_channel: 0,
        vid: null,
        awType: 'Leased',
        awKey: ''
      };
      this.showEditModal = true;
    },
    cancelEdit() {
      this.showEditModal = false;
    }
  },
  computed: {
    Computers() {
      return this.$store.state.farm.computers;
    },
    ModalHeader() {
      return this.editCopy.id === 'new' ? 'New Computer' : 'Edit Computer';
    },
    AerwareExpiration: {
      get() {
        return date.formatDate(
          new Date(this.editCopy.awExp * 1000),
          'YYYY-MM-DD'
        );
      },
      set(value) {
        const d = new Date(value);
        this.editCopy.awExp =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    },
    filteredAerwareValue: {
      get() {
        if (
          this.$store.state.aerwareUnassignedPC.find(
            (e) => e.license_id === this.editCopy.awId
          )
        ) {
          return this.editCopy.awId;
        } else {
          return '';
        }
      },
      set(value) {
        this.editCopy.awId = value;
      }
    },
    UnassignedAerwareOptions() {
      const list = [];
      for (let i = 0; i < this.$store.state.aerwareUnassignedPC.length; i++) {
        const unassigned = this.$store.state.aerwareUnassignedPC[i];
        list.push({
          label: unassigned.nickname + ' - ' + unassigned.license_id,
          value: unassigned.license_id
        });
      }
      return list;
    }
  }
};
</script>

<style scoped>
.spaced-l {
  margin-right: 2px;
}
.spaced-r {
  margin-left: 2px;
}

@media only screen and (min-width: 750px) {
  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }
}

.max-width {
  max-width: 100px;
}
</style>
