import axios from 'axios';

export default {
  getStatus() {
    return axios.get('/api/status').then((response) => {
      return {
        data: response.data,
        version: response.headers['x-web_version']
      };
    });
    // handle errors elsewhere
  }
};
