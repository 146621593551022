export default class CookieUtils {
  static redirectToAccounts() {
    window.location.href =
      `${process.env.VUE_APP_ACCOUNTS_LOGIN}?redirect_url=` +
      `${encodeURIComponent(window.location.href)}`;
  }

  static deleteLocalCookie() {
    document.cookie =
      process.env.VUE_APP_ERASABLE_COOKIE_NAME +
      `=; Max-Age=-99999999; Path=/; Domain=${process.env.VUE_APP_COOKIE_DOMAIN};`;
  }
}
