import { render, staticRenderFns } from "./Tags.vue?vue&type=template&id=9eee5236&scoped=true"
import script from "./Tags.vue?vue&type=script&lang=js"
export * from "./Tags.vue?vue&type=script&lang=js"
import style0 from "./Tags.vue?vue&type=style&index=0&id=9eee5236&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9eee5236",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QCard,QToggle,QSpace,QBtnToggle,QScrollArea,QDialog,QBtn,QSeparator,QIcon,QInput,QRadio,QChip,QOptionGroup,QSelect,QPageSticky});
