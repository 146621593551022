<template>
  <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
    <q-table
      ref="farmTable"
      dense
      title="Farms"
      :data="Farms"
      :columns="columns"
      :loading="!Farms.length"
      color="primary"
      :pagination.sync="pagination"
      row-key="name"
      :visible-columns="VisibleColumns"
      class="sticky-header-table"
      style="height: calc(100vh - 53px)"
    >
      <template v-slot:top>
        <q-input
          v-model="search"
          placeholder="Farm Search"
          outlined
          color="primary"
          dense
          bg-color="grey-1"
          type="search"
          @input="setFarms"
        >
          <template v-slot:append>
            <q-icon
              v-if="search !== ''"
              name="cancel"
              color="primary"
              @click="search = ''"
              clickable
            />
            <q-icon v-else name="search" />
          </template>
        </q-input>
        <q-space />
        <q-btn
          outline
          color="primary"
          icon="filter_list"
          size="sm"
          class="q-mx-sm"
          padding="sm sm"
          @click="dialogFilterColumns = true"
        />
        <q-btn
          v-if="isSuperAdmin"
          outline
          :label="$q.screen.xs ? '' : 'General Search'"
          color="primary"
          icon="search"
          size="sm"
          padding="sm sm"
          @click="generalSearch"
        />
      </template>

      <template v-slot:header-cell="props">
        <q-th :props="props" style="padding: 0; text-align: center">
          <div>
            {{ props.col.label }}
          </div>
          <div>
            {{ props.col.label2 }}
          </div>
        </q-th>
      </template>

      <template v-slot:body-cell-Name="props">
        <q-td
          :props="props"
          style="padding: 5px; height: 40.5px"
          class="cursor-pointer"
          @click="goToFarm(props.row)"
        >
          <div id="title-element" style="position: relative">
            <div
              :id="props.row.id"
              style="position: absolute; top: -66px; left: 0"
            ></div>
          </div>
          <div class="q-ml-sm">{{ decoder(props.row.farm_name) }}</div>
        </q-td>
      </template>

      <template v-slot:body-cell-ToDo="props">
        <q-td :props="props">
          <div class="row justify-center">
            <div
              v-if="props.row.todoLevel"
              class="rounded-borders"
              :class="'bg-' + levelToColor(props.row.todoLevel)"
              style="width: 28px; height: 20px"
            >
              {{ props.row.numTodos }}
            </div>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-ASM="props">
        <q-td :props="props">
          <div
            v-if="props.row.priority_service"
            class="row items-center justify-center no-wrap cursor-pointer"
            @click="displayASM(props.row)"
          >
            <div style="min-width: 60px" class="q-pr-sm">
              <span v-if="props.row.serviceDate > 0" class="q-pr-sm">
                {{ parseDate(props.row.serviceDate) }}
              </span>
            </div>

            <div
              v-if="props.row.asmLevel === 0 && !props.row.isSnoozed"
              style="min-width: 20px"
            ></div>
            <div v-if="props.row.asmLevel === 0 && props.row.isSnoozed">
              <q-icon
                color="primary"
                name="alarm"
                size="20px"
                class="cursor-pointer"
              />
            </div>
            <q-icon
              v-if="props.row.asmLevel > 0"
              :color="
                props.row.asmLevel === 1
                  ? 'yellow-7'
                  : props.row.asmLevel === 2
                  ? 'orange-14'
                  : 'red-14'
              "
              :name="props.row.asmLevel === 4 ? 'warning' : 'error'"
              size="20px"
            />
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Web-Eval="props">
        <q-td :props="props" @click="displayWebEval(props.row)">
          <div class="row items-center justify-center no-wrap cursor-pointer">
            <q-icon
              v-if="props.row.webEval.canEval"
              :color="props.row.webEval.evalColor"
              name="language"
              size="20px"
            />
            <q-tooltip
              content-class="bg-white"
              content-style="margin: 0; padding: 0; border: 1px solid black"
            >
              <div>
                <q-card flat class="bg-white text-black text-body2 q-pa-sm">
                  <div class="text-center">
                    {{ props.row.webEval.lastEvalDate || '---' }}
                  </div>
                  <q-separator
                    v-if="props.row.webEval.evalNote"
                    color="black"
                  />
                  <div
                    v-if="props.row.webEval.evalNote"
                    style="max-width: 300px"
                  >
                    <span> Note: </span>
                    {{ props.row.webEval.evalNote }}
                  </div>
                </q-card>
              </div>
            </q-tooltip>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-PC-Eval="props">
        <q-td :props="props" @click="displayPCEval(props.row)">
          <div class="row items-center justify-center no-wrap cursor-pointer">
            <q-icon
              v-if="props.row.PCEval.canEval"
              :color="props.row.PCEval.evalColor"
              name="computer"
              size="20px"
            />
            <q-tooltip
              content-class="bg-white"
              content-style="margin: 0; padding: 0; border: 1px solid black"
            >
              <div>
                <q-card flat class="bg-white text-black text-body2 q-pa-sm">
                  <div class="text-center">
                    {{ props.row.PCEval.lastEvalDate || '---' }}
                  </div>
                  <q-separator v-if="props.row.PCEval.evalNote" color="black" />
                  <div
                    v-if="props.row.PCEval.evalNote"
                    style="max-width: 300px"
                  >
                    <span> Note: </span>
                    {{ props.row.PCEval.evalNote }}
                  </div>
                </q-card>
              </div>
            </q-tooltip>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Win-Eval="props">
        <q-td :props="props" @click="displayWinEval(props.row)">
          <div class="row items-center justify-center no-wrap cursor-pointer">
            <q-icon
              v-if="props.row.winEval.canEval"
              :color="props.row.winEval.evalColor"
              name="autorenew"
              size="20px"
            />
            <q-tooltip
              content-class="bg-white"
              content-style="margin: 0; padding: 0; border: 1px solid black"
            >
              <div>
                <q-card flat class="bg-white text-black text-body2 q-pa-sm">
                  <div class="text-center">
                    {{ props.row.winEval.lastEvalDate || '---' }}
                  </div>
                  <q-separator
                    v-if="props.row.winEval.evalNote"
                    color="black"
                  />
                  <div
                    v-if="props.row.winEval.evalNote"
                    style="max-width: 300px"
                  >
                    <span> Note: </span>
                    {{ props.row.winEval.evalNote }}
                  </div>
                </q-card>
              </div>
            </q-tooltip>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Priority="props">
        <q-td :props="props" style="padding: 5px">
          <q-icon
            :color="props.row.priority_service ? 'primary' : 'grey-4'"
            size="18px"
            name="build"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-RTB="props">
        <q-td style="padding: 10px" :props="props">
          <template>
            <div
              v-if="props.row.billing.rtb_count > 0"
              style="width: 28px; height: 20px"
              class="rounded-borders"
              :class="props.row.billing.rtb_count > 0 ? 'bg-green' : ''"
            >
              {{
                props.row.billing.rtb_count > 0
                  ? props.row.billing.rtb_count
                  : ''
              }}
            </div>
          </template>
        </q-td>
      </template>

      <template v-if="columnUnbilled" v-slot:body-cell-Un-billed="props">
        <q-td :props="props" style="padding: 0">
          <template v-if="props.row.billing.count > 0">
            <div>
              <div>
                {{
                  props.row.billing.count +
                  ' : ' +
                  parseDate(props.row.billing.first) +
                  ' -'
                }}
              </div>
              <div>
                {{ parseDate(props.row.billing.last) }}
              </div>
            </div>
          </template>
        </q-td>
      </template>

      <template v-if="columnContact" v-slot:body-cell-Contact="props">
        <q-td :props="props">
          <div class="text-center">
            {{ decoder(props.row.contact_name) }}
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Ponds="props">
        <q-td :props="props">
          <template v-if="props.row.pond_count > 0">
            {{ props.row.pond_count }}</template
          >
        </q-td>
      </template>
      <template v-slot:body-cell-HB20="props">
        <q-td :props="props">
          <template v-if="props.row.pond_hb20 > 0">
            {{ props.row.pond_hb20 }}</template
          >
        </q-td>
      </template>
    </q-table>

    <!-- ASM Dialog -->
    <q-dialog position="top" full-height v-model="dialogASM">
      <ASMDetail :farm="selectedFarm" @setASM="(val) => (setASM = val)" />
    </q-dialog>

    <!-- Web Eval Dialog -->
    <q-dialog position="top" full-height v-model="dialogWebEval">
      <WebEval :farm="selectedFarm" @goToFarm="goToFarm" />
    </q-dialog>

    <!-- PC Eval Dialog -->
    <q-dialog position="top" full-height v-model="dialogPCEval">
      <PCEval :farm="selectedFarm" @goToFarm="goToFarm" />
    </q-dialog>

    <!-- Web Eval Dialog -->
    <q-dialog position="top" full-height v-model="dialogWinEval">
      <WinEval :farm="selectedFarm" @goToFarm="goToFarm" />
    </q-dialog>

    <!--Filter Columns Dialog-->
    <q-dialog position="top" v-model="dialogFilterColumns">
      <q-card style="width: 400px">
        <div
          class="row items-center justify-between text-subtitle1 q-py-md q-mx-md"
        >
          <div style="width: 56px"></div>
          <div class="col text-center text-h6 ellipsis">Filter Columns</div>
          <q-btn unelevated icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator />
        <div class="q-pa-md">
          <div
            v-for="column in columnsFilter"
            :key="column.label + 'columnFilter'"
            class="q-pb-sm"
          >
            <q-checkbox
              v-model="column.value"
              :label="column.label"
              size="lg"
              dense
              color="primary"
              @input="setColumns"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!--General Search Dialog-->
    <q-dialog position="top" v-model="generalSearchDialog">
      <q-card style="width: 600px">
        <div
          class="row items-center justify-between text-subtitle1 text-primary q-py-md q-mx-md"
        >
          <div style="width: 56px"></div>
          <div class="col text-center text-h6 ellipsis">General Search</div>
          <q-btn unelevated icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator />

        <div class="row items-center q-pa-md">
          <div class="col q-pr-md">
            <q-input
              v-model="generalSearchModel"
              outlined
              dense
              type="search"
              label="Search"
              @keyup.enter="generalSearchSubmit"
            >
              <template v-slot:append>
                <q-icon
                  v-if="generalSearchModel !== ''"
                  name="cancel"
                  clickable
                  @click="generalSearchModel = ''"
                />
                <q-icon v-else name="search" />
              </template>
            </q-input>
          </div>
          <div>
            <q-btn
              label="Search"
              size="md"
              color="primary"
              @click="generalSearchSubmit"
            />
          </div>
        </div>
        <q-separator />

        <div v-if="searching" class="q-py-sm q-px-md">
          <q-linear-progress
            indeterminate
            rounded
            color="primary"
            size="10px"
          />
        </div>
        <q-separator v-if="searching" />

        <div
          v-if="generalSearchEmpty"
          class="text-center text-h6 text-grey-6 q-pt-md"
        >
          No Results
        </div>

        <div
          class="scroll q-px-md q-pb-md"
          style="max-height: calc(100vh - 250px)"
        >
          <div
            v-for="(item, i) in generalSearchList"
            :key="item.date + item.type + i"
          >
            <div class="row items-center q-py-xs">
              <div class="q-mr-sm">
                <q-btn
                  outline
                  label="Go"
                  color="primary"
                  size="sm"
                  padding="xs md"
                  @click="goToSearchUrl(item.url, item.id)"
                />
              </div>
              <span class="text-subtitle1 text-primary q-pr-xs">
                {{ parseDate(item.date) }}
              </span>
              -
              <span class="text-subtitle1 text-primary q-px-xs">
                {{ item.type }}
              </span>
              <span v-if="item.farm">-</span>
              <span class="text-subtitle1 text-accent q-px-xs">
                {{ farmName(item.farm) }}
              </span>
              -
              {{ item.data }}
            </div>
            <q-separator class="full-width" />
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import ASMDetail from '@/components/ASMDetail.vue';
import WebEval from '@/components/WebEval.vue';
import PCEval from '@/components/PCEval.vue';
import WinEval from '@/components/WinEval.vue';

import store from '@/store';
import { parseTimeStamp } from '@/lib/date-utils';
import { cloneObj, smartCompare, checkPondNotInUse } from '@/lib/helpers';
import { date } from 'quasar';
import { searchHelper } from '@/lib/searchHelper';
import { getFarmEval, getWebFolders } from '@/lib/farmHelpers';

export default {
  name: 'Farms',
  components: {
    ASMDetail,
    WebEval,
    PCEval,
    WinEval
  },
  data() {
    return {
      columnsFilter: [],
      columnsAll: [
        'Name',
        'ToDo',
        'Web-Eval',
        'PC-Eval',
        'Win-Eval',
        'ASM',
        'Priority',
        'RTB',
        'Un-billed',
        'Contact',
        'Aerware',
        'PC',
        'Ponds',
        'HB20'
      ],
      columnAerware: true,
      columnASM: true,
      columnContact: true,
      columnHB20: true,
      columnPCs: true,
      columnPonds: true,
      columnPriority: true,
      columnRTB: true,
      columnToDo: true,
      columnUnbilled: true,
      date,
      dialogASM: false,
      dialogWebEval: false,
      dialogPCEval: false,
      dialogWinEval: false,
      dialogFilterColumns: false,
      search: '',
      searching: false,
      selectedFarm: {
        farm_name: '',
        asmLevel: 0,
        serviceDate: 0,
        farmASM: []
      },
      setASM: false,
      timer: null,
      generalSearchDialog: false,
      generalSearchModel: '',
      generalSearchList: [],
      generalSearchEmpty: false,
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 300
      },
      Farms: []
    };
  },
  mounted() {
    this.setFarms();
    this.scrollTo();
    this.setColumnsFilter();

    // setFarms every 5 minutes to keep evals
    // up to date (hour expiration)
    this.timer = setInterval(() => {
      this.setFarms();
    }, 300000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    displayWebEval(row) {
      const farm = cloneObj(row);

      if (!farm.web_eval) {
        return;
      }

      this.dialogWebEval = true;
      this.selectedFarm = farm;
    },
    displayPCEval(row) {
      const farm = cloneObj(row);

      if (!farm.pc_eval) {
        return;
      }

      this.dialogPCEval = true;
      this.selectedFarm = farm;
    },
    displayWinEval(row) {
      const farm = cloneObj(row);

      if (!farm.win_eval) {
        return;
      }

      this.dialogWinEval = true;
      this.selectedFarm = farm;
    },
    displayASM(row) {
      const farm = cloneObj(row);
      this.setASM = false;
      this.dialogASM = true;

      // create timestamps for ASM level
      const now = +new Date();
      const sixWeeksPast = +new Date() - 3628800000;
      const threeWeeksUpcoming = +new Date() + 1814400000;
      const sixWeeksUpcoming = +new Date() + 3628800000;
      const nowASM = parseInt(+new Date() / 1000);

      for (const pond of farm.farmASM) {
        const serviceDateAdjusted = +date.addToDate(
          new Date(pond.latestService * 1000),
          {
            year: 1
          }
        );

        const isSnoozed = pond.asm_snooze !== null && pond.asm_snooze > nowASM;
        pond.asmSnoozed = isSnoozed;

        pond.asmLevel =
          checkPondNotInUse(pond.name) || isSnoozed
            ? 0
            : serviceDateAdjusted < sixWeeksPast
            ? 4
            : serviceDateAdjusted < now
            ? 3
            : serviceDateAdjusted < threeWeeksUpcoming
            ? 2
            : serviceDateAdjusted < sixWeeksUpcoming
            ? 1
            : 0;
      }

      farm.farmASM.sort((a, b) => smartCompare(a.name, b.name));

      this.selectedFarm = farm;
      this.dialogASM = true;
    },
    generalSearch() {
      this.generalSearchDialog = true;
      this.generalSearchModel = '';
      this.generalSearchList.splice(0);
      this.generalSearchEmpty = false;
    },
    async generalSearchSubmit() {
      this.generalSearchEmpty = false;

      const searchStr = this.generalSearchModel.toLowerCase();
      if (searchStr === '') {
        this.generalSearchEmpty = true;
        return;
      }

      this.searching = true;
      // Need this to display loader
      await new Promise((resolve) => setTimeout(resolve, 200));

      this.generalSearchList.splice(0);

      for (let i = 0; i < this.Todos.length; i++) {
        const todo = this.Todos[i];
        if (todo.note.toLowerCase().includes(searchStr)) {
          this.generalSearchList.push({
            type: 'ToDo Note',
            date: todo.date,
            farm: todo.farm_id,
            data: todo.note,
            id: todo.id,
            url: `farm/${todo.farm_id}/todos`
          });
        }

        for (let j = 0; j < todo.comments.length; j++) {
          const comment = todo.comments[j];
          if (comment.comment.toLowerCase().includes(searchStr)) {
            this.generalSearchList.push({
              type: 'ToDo Comment',
              date: todo.date,
              farm: todo.farm_id,
              data: comment.comment,
              id: todo.id,
              url: `farm/${todo.farm_id}/todos`
            });
          }
        }

        for (let j = 0; j < todo.chats.length; j++) {
          const chat = todo.chats[j];
          if (chat.comment.toLowerCase().includes(searchStr)) {
            this.generalSearchList.push({
              type: 'ToDo Chat',
              date: todo.date,
              farm: todo.farm_id,
              data: chat.comment,
              id: todo.id,
              url: `farm/${todo.farm_id}/todos`
            });
          }
        }
      }

      const getWorklogs = (fromTS, toTS) => {
        const to = toTS / 1000;
        const from = fromTS / 1000;

        store
          .dispatch('customWorkLogs', {
            dates: { to, from }
          })
          .then((results) => {
            results.disableCheck = true;
            this.$finishResult.handleResultsAsync(results).then((response) => {
              // mutates this.generalSearchList
              searchHelper(
                searchStr,
                store.state.workLogsCustom,
                this.generalSearchList
              );

              if (store.state.workLogsCustom.length !== 0) {
                // move dates back 6 months and search again
                getWorklogs(
                  +date.subtractFromDate(new Date(fromTS), { day: 180 }),
                  +date.subtractFromDate(new Date(toTS), { day: 180 })
                );
              } else {
                this.searching = false;

                if (this.generalSearchList.length === 0) {
                  this.generalSearchEmpty = true;
                }
              }
            });
          });
      };

      const to = +date.addToDate(new Date(), { day: 20 });
      const from = +date.subtractFromDate(new Date(), { day: 160 });

      getWorklogs(from, to);

      const boards = store.state.boards;
      for (const board of boards) {
        if (board.note.toLowerCase().includes(searchStr)) {
          this.generalSearchList.push({
            type: 'Board',
            date: +new Date(board.created) / 1000,
            data: board.id + ' - ' + board.type + ' - ' + board.note,
            id: board.id,
            url: `boards`
          });
        }

        if (board.id === parseInt(searchStr)) {
          this.generalSearchList.push({
            type: 'Board',
            date: +new Date(board.created) / 1000,
            data: board.id + ' - ' + board.type + ' - ' + board.note,
            id: board.id,
            url: `boards`
          });
        }
      }

      this.generalSearchList.sort((a, b) => b.date - a.date);
    },
    goToSearchUrl(url, id) {
      this.$store.dispatch('setWorkLogScrollTo', id);
      this.$router.push(url);
    },
    decoder(str, shorter) {
      const textArea = document.createElement('textarea');
      const truncLength = shorter ? 15 : 20;
      textArea.innerHTML =
        this.$q.screen.gt.md || str.length < truncLength + 1
          ? str
          : str.substring(0, truncLength) + '...';
      return textArea.value;
    },
    farmName(farmId) {
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(farmId)
      );
      return farm ? this.decoder(farm.farm_name) : '---';
    },
    parseDate(value) {
      return parseTimeStamp(value, 'short');
    },
    parseTime(value) {
      return parseTimeStamp(value, 'short');
    },
    goToFarm(farm) {
      this.$store.dispatch('setFarmScrollTo', {
        id: farm.id,
        goStatus: 'active'
      });

      this.$router.push(`farm/${farm.id}/work`);
    },
    scrollTo() {
      const scrollObj = this.$store.state.farmScrollTo;
      if (scrollObj.goStatus === 'go') {
        this.$nextTick(() => {
          const id = document.getElementById(scrollObj.id);
          document.getElementById(scrollObj.id).scrollIntoView({
            behavior: 'smooth'
          });
        });

        scrollObj.goStatus = 'inactive';
        this.$store.dispatch('setEmailScrollTo', scrollObj);
      }
    },
    pcCount() {
      let count = 0;
      const list = this.$store.getters.filteredFarms;
      for (let i = 0; i < list.length; i++) {
        count += list[i].gps.computers.length;
      }
      return count;
    },
    pondCount() {
      let count = 0;
      const list = this.$store.getters.filteredFarms;
      for (let i = 0; i < list.length; i++) {
        count += parseInt(list[i].pond_count);
      }
      return count;
    },
    hb20Count() {
      let count = 0;
      const list = this.$store.getters.filteredFarms;
      for (let i = 0; i < list.length; i++) {
        count += parseInt(list[i].pond_hb20);
      }
      return count;
    },
    levelToColor(level) {
      let color = 'white';
      switch (level) {
        case 1:
          color = 'amber-6';
          break;
        case 2:
          color = 'orange-10';
          break;
        case 3:
          color = 'red-10';
          break;
      }

      return color;
    },
    setColumns() {
      const hideColumns = this.columnsFilter
        .filter((x) => !x.value)
        .map((x) => x.label);

      localStorage.setItem('hideColumns', JSON.stringify(hideColumns));
    },
    setColumnsFilter() {
      const hideColumns = JSON.parse(localStorage.getItem('hideColumns'));

      for (const column of this.columnsAll) {
        this.columnsFilter.push({
          label: column,
          value: hideColumns ? !hideColumns.includes(column) : true
        });
      }
    },
    setFarms() {
      let farmList = cloneObj(this.$store.getters.filteredFarms);

      if (this.search !== '') {
        const search = this.search.toLowerCase();
        farmList = farmList.filter((e) => {
          return (
            e.farm_name.toLowerCase().includes(search) ||
            e.contact_name.toLowerCase().includes(search)
          );
        });
      }

      const levelToNum = (level) => {
        let num = 0;
        switch (level) {
          case 'None' || 'Low':
            num = 0;
            break;
          case 'Medium':
            num = 1;
            break;
          case 'High':
            num = 2;
            break;
          case 'Critical':
            num = 3;
            break;
        }

        return num;
      };

      // create timestamps for ASM level
      const now = +new Date();
      const sixWeeksPast = +new Date() - 3628800000;
      const threeWeeksUpcoming = +new Date() + 1814400000;
      const sixWeeksUpcoming = +new Date() + 3628800000;
      const eightWeeksUpcoming = +new Date() + 4838400000;
      const nowASM = parseInt(+new Date() / 1000);

      for (const farm of farmList) {
        // Set Todo level
        const farmTodos = this.Todos.filter((todo) => {
          return (
            todo.farm_id === farm.id &&
            todo.status !== 'Complete' &&
            todo.priority !== 'None' &&
            todo.priority !== 'Low'
          );
        }).map((filteredTodo) => levelToNum(filteredTodo.priority));

        const farmWLTodos = this.Logs.filter(
          (x) =>
            x.farm_id === farm.id &&
            x.todo_flag !== 0 &&
            x.todo_flag !== 1 &&
            x.todo_flag !== 2
        ).map((y) =>
          y.todo_flag === 10
            ? 3
            : y.todo_flag === 9
            ? 2
            : y.todo_flag === 8
            ? 1
            : 0
        );

        farm.todoLevel =
          farmTodos.length + farmWLTodos.length
            ? Math.max(...farmTodos, ...farmWLTodos)
            : 0;
        farm.numTodos = farmTodos.length + farmWLTodos.length;

        let serviceDate = 0;
        let asmLevel = 0;

        farm.isSnoozed = false;
        if (farm.priority_service) {
          serviceDate = farm.farmASM.reduce((oldest, pond) => {
            const isSnoozed =
              pond.asm_snooze !== null && pond.asm_snooze > nowASM;

            if (isSnoozed) {
              farm.isSnoozed = true;
            }

            return isSnoozed
              ? oldest
              : checkPondNotInUse(pond.name)
              ? oldest
              : oldest === 'none' || pond.latestService < oldest
              ? pond.latestService
              : oldest;
          }, 'none');

          const serviceDateAdjusted = +date.addToDate(
            new Date(serviceDate * 1000),
            { year: 1 }
          );

          asmLevel =
            serviceDateAdjusted < sixWeeksPast
              ? 4
              : serviceDateAdjusted < now
              ? 3
              : serviceDateAdjusted < threeWeeksUpcoming
              ? 2
              : serviceDateAdjusted < sixWeeksUpcoming
              ? 1
              : 0;
        }

        farm.serviceDate = serviceDate;
        farm.asmLevel = asmLevel;

        let minAwexp = 999999999999999;
        for (const computer of farm.gps.computers) {
          if (computer.awexp < minAwexp) {
            minAwexp = parseInt(computer.awexp);
          }
        }

        farm.awexp = minAwexp;

        const webEevalWorkItems = [135];
        farm.webEval = getFarmEval(farm, 'web_eval', webEevalWorkItems);

        const PCEevalWorkItems = [136];
        farm.PCEval = getFarmEval(farm, 'pc_eval', PCEevalWorkItems);

        const winEvalWorkItems = [138];
        farm.winEval = getFarmEval(farm, 'win_eval', winEvalWorkItems);

        farm.numLocations = farm.webfolders.length;
        farm.webAxessUrls = getWebFolders(farm);
      }

      this.Farms = farmList.slice();
    }
  },
  computed: {
    Logs() {
      return this.$store.state.workLogs;
    },
    Todos() {
      return this.$store.state.todos;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
    settings() {
      return this.$store.state.settings;
    },
    columns() {
      return [
        {
          sortable: true,
          name: 'Name',
          label: 'Name',
          align: 'left',
          style: 'max-width: 165px',
          headerStyle: 'max-width: 165px',
          classes: 'table-highlight-column',
          field: (row) => this.decoder(row.farm_name, false)
        },
        {
          sortable: true,
          name: 'ToDo',
          label: 'ToDo',
          align: 'center',
          style: 'max-width: 40px',
          sort: (a, b, rowA, rowB) => rowB.todoLevel - rowA.todoLevel,
          field: (row) => row.todoLevel
        },
        {
          sortable: true,
          name: 'ASM',
          label: 'ASM',
          align: 'center',
          style: 'max-width: 96px; min-width: 96px;',
          sort: (a, b, rowA, rowB) => {
            // bring snoozed up
            if (
              rowA.priority_service &&
              rowA.asmLevel === 0 &&
              rowB.priority_service &&
              rowB.asmLevel === 0
            ) {
              if (rowA.isSnoozed && !rowB.isSnoozed) {
                return -1;
              }

              if (!rowA.isSnoozed && rowB.isSnoozed) {
                return 1;
              }
            }

            if (rowA.priority_service && rowB.priority_service) {
              return rowB.asmLevel - rowA.asmLevel;
            }

            if (rowA.priority_service && !rowB.priority_service) {
              return -1;
            }

            if (!rowA.priority_service && rowB.priority_service) {
              return 1;
            }
          },
          field: (row) => row.serviceDate
        },
        {
          sortable: true,
          name: 'Web-Eval',
          label: 'Charts',
          align: 'center',
          style: 'width: 50px',
          sort: (a, b, rowA, rowB) => {
            if (!rowA.webEval.canEval || !rowB.webEval.canEval) {
              return rowA.webEval.canEval ? -1 : 1;
            }

            if (
              rowA.webEval.notifyOnExpiration &&
              !rowA.webEval.evalUpToDate &&
              rowA.webEval.lastEvalDate
            ) {
              return -1;
            }

            if (
              rowB.webEval.notifyOnExpiration &&
              !rowB.webEval.evalUpToDate &&
              rowB.webEval.lastEvalDate
            ) {
              return 1;
            }

            if (!rowA.webEval.evalUpToDate || !rowB.webEval.evalUpToDate) {
              return rowB.webEval.evalUpToDate ? -1 : 1;
            }

            return rowA.webEval.nextEvalDate - rowB.webEval.nextEvalDate;
          },
          field: (row) => row.webEval
        },
        {
          sortable: true,
          name: 'PC-Eval',
          label: 'PC',
          align: 'center',
          style: 'width: 50px',
          sort: (a, b, rowA, rowB) => {
            if (!rowA.PCEval.canEval || !rowB.PCEval.canEval) {
              return rowA.PCEval.canEval ? -1 : 1;
            }

            if (
              rowA.PCEval.notifyOnExpiration &&
              !rowA.PCEval.evalUpToDate &&
              rowA.PCEval.lastEvalDate
            ) {
              return -1;
            }

            if (
              rowB.PCEval.notifyOnExpiration &&
              !rowB.PCEval.evalUpToDate &&
              rowB.PCEval.lastEvalDate
            ) {
              return 1;
            }

            if (!rowA.PCEval.evalUpToDate || !rowB.PCEval.evalUpToDate) {
              return rowB.PCEval.evalUpToDate ? -1 : 1;
            }

            return rowA.PCEval.nextEvalDate - rowB.PCEval.nextEvalDate;
          },
          field: (row) => row.PCEval
        },
        {
          sortable: true,
          name: 'Win-Eval',
          label: 'Win-Up',
          align: 'center',
          style: 'width: 50px',
          sort: (a, b, rowA, rowB) => {
            if (!rowA.winEval.canEval || !rowB.winEval.canEval) {
              return rowA.winEval.canEval ? -1 : 1;
            }

            if (
              rowA.winEval.notifyOnExpiration &&
              !rowA.winEval.evalUpToDate &&
              rowA.winEval.lastEvalDate
            ) {
              return -1;
            }

            if (
              rowB.winEval.notifyOnExpiration &&
              !rowB.winEval.evalUpToDate &&
              rowB.winEval.lastEvalDate
            ) {
              return 1;
            }

            if (!rowA.winEval.evalUpToDate || !rowB.winEval.evalUpToDate) {
              return rowB.winEval.evalUpToDate ? -1 : 1;
            }

            return rowA.winEval.nextEvalDate - rowB.winEval.nextEvalDate;
          },
          field: (row) => row.winEval
        },
        {
          sortable: true,
          name: 'Priority',
          label: 'P-S',
          align: 'center',
          style: 'max-width: 60px',
          field: (row) => row.priority_service
        },
        {
          sortable: true,
          name: 'RTB',
          label: 'RTB',
          align: 'center',
          style: 'max-width: 35px',
          // Reverse sort
          sort: (a, b, rowA, rowB) =>
            rowB.billing.rtb_count - rowA.billing.rtb_count,
          field: (row) =>
            row.billing.rtb_count > 0 ? row.billing.rtb_count : ''
        },
        {
          sortable: true,
          name: 'Un-billed',
          label: 'Un-billed',
          align: 'center',
          sortOrder: 'da',
          style: 'max-width: 80px',
          // Reverse sort
          sort: (a, b, rowA, rowB) => rowB.billing.count - rowA.billing.count,
          field: (row) =>
            row.billing.count > 0
              ? row.billing.count +
                ' : ' +
                this.parseDate(row.billing.first) +
                ' - ' +
                this.parsDate(row.billing.last)
              : ''
        },
        {
          sortable: true,
          name: 'Contact',
          label: 'Contact',
          align: 'left',
          style: 'max-width: 115px',
          field: (row) => this.decoder(row.contact_name, true)
        },
        {
          sortable: true,
          name: 'Aerware',
          label: 'Aerware',
          align: 'center',
          style: 'max-width: 70px',
          sort: (a, b, rowA, rowB) => rowA.awexp - rowB.awexp,
          field: (row) =>
            row.gps.computers.length > 0
              ? parseTimeStamp(row.awexp, 'short')
              : '---'
        },
        {
          sortable: true,
          name: 'PC',
          label: "PC's",
          label2: '(' + this.pcCount() + ')',
          align: 'center',
          style: 'max-width: 45px',
          field: (row) => row.gps.computers.length
        },
        {
          sortable: true,
          name: 'Ponds',
          label: 'Ponds',
          label2: '(' + this.pondCount() + ')',
          align: 'center',
          style: 'max-width: 45px',
          field: (row) => row.pond_count
        },
        {
          sortable: true,
          name: 'HB20',
          label: 'HB20',
          label2: '(' + this.hb20Count() + ')',
          align: 'center',
          style: 'max-width: 55px',
          field: (row) => row.pond_hb20
        }
      ];
    },
    VisibleColumns() {
      return this.columnsFilter.filter((x) => x.value).map((x) => x.label);
    }
  },
  watch: {
    '$store.getters.filteredFarms'() {
      this.setFarms();

      const farm = this.Farms.find((x) => x.id === this.selectedFarm.id);

      if (this.setASM) {
        this.displayASM(farm);
      }

      if (this.dialogWebEval) {
        this.displayWebEval(farm);
      }

      if (this.dialogPCEval) {
        this.displayPCEval(farm);
      }

      if (this.dialogWinEval) {
        this.displayWinEval(farm);
      }
    },
    '$store.state.todos'() {
      this.setFarms();
    },
    '$store.state.workLogs'() {
      this.setFarms();
    },
    '$store.state.settings'() {
      this.setFarms();
    }
  }
};
</script>

<style scoped>
.check-style {
  font-size: 1.4em;
  text-align: center;
}
</style>
