<template>
  <q-page ref="tagPage" class="q-pa-sm">
    <div class="row full-width">
      <div class="row full-width">
        <!--Mobile version-->
        <div v-if="$q.screen.lt.md" class="col-12">
          <q-card flat bordered class="q-pa-sm">
            <div class="col-12 row items-center q-mb-sm q-mt-sm">
              <q-toggle
                v-if="isSuperAdmin"
                dense
                v-model="showOptions"
                checked-icon="edit"
                unchecked-icon="edit"
                color="orange-14"
                size="xl"
              />
              <div v-if="!isSuperAdmin" style="width: 48px"></div>
              <q-space />
              <div style="margin-bottom: 2px">
                <q-btn-toggle
                  v-model="toggleModel"
                  :color="colorBlueGrey.slice(3)"
                  toggle-color="orange-14"
                  toggle-text-color="black"
                  padding="3px 10px"
                  :options="[
                    { label: 'Tags', value: 'tags' },
                    { label: 'Farms', value: 'farms' },
                    { label: 'Ponds', value: 'ponds' }
                  ]"
                />
              </div>
              <q-space />
              <div style="width: 48px"></div>
            </div>

            <q-scroll-area
              v-if="toggleModel === 'tags'"
              :style="`height: ${cardHeight}px;`"
            >
              <MainTags
                :tagsMaster="tagsMaster"
                :farmList="farmList"
                :showOptions="showOptions"
                @masterTagDelete="masterTagDelete"
                @masterTagEdit="masterTagEdit"
                @farmAddStart="farmAddStart"
              />
            </q-scroll-area>

            <div v-if="toggleModel !== 'tags'">
              <q-card>
                <div class="bg-grey" style="height: 22px"></div>
                <q-scroll-area :style="`height: ${cardHeight - 22}px;`">
                  <FarmTags
                    v-if="toggleModel === 'farms'"
                    :tagsFarm="tagsFarm"
                    :farmList="farmList"
                    :showOptions="showOptions"
                    @farmDeleteStart="farmDeleteStart"
                    @farmAddStart="farmAddStart"
                  />

                  <PondTags
                    v-if="toggleModel === 'ponds'"
                    :tagsPond="tagsPond"
                    :farmList="farmList"
                    :showOptions="showOptions"
                    @farmDeleteStart="farmDeleteStart"
                    @farmAddStart="farmAddStart"
                  />
                </q-scroll-area>
              </q-card>
            </div>
          </q-card>
        </div>

        <!--Tablet version-->
        <div v-if="$q.screen.md" class="col-6 q-pr-sm">
          <q-card flat bordered class="q-pa-sm">
            <div class="col-12 row items-center q-mb-sm q-mt-sm">
              <q-toggle
                v-if="isSuperAdmin"
                dense
                v-model="showOptions"
                checked-icon="edit"
                unchecked-icon="edit"
                color="orange-14"
                size="xl"
              />
              <div v-if="!isSuperAdmin" style="width: 48px"></div>
              <q-space />
              <span class="text-h6 text-orange-10"> Tags </span>
              <q-space />
              <div style="width: 48px"></div>
            </div>

            <q-scroll-area :style="`height: ${cardHeight}px;`">
              <MainTags
                :tagsMaster="tagsMaster"
                :farmList="farmList"
                :showOptions="showOptions"
                @masterTagDelete="masterTagDelete"
                @masterTagEdit="masterTagEdit"
                @farmAddStart="farmAddStart"
              />
            </q-scroll-area>
          </q-card>
        </div>

        <!--Farms Card-->
        <div v-if="$q.screen.md" class="col-6">
          <q-card flat bordered class="col-4 q-pa-sm">
            <div class="text-center" style="margin: 8px 0 10px 0">
              <q-btn-toggle
                v-model="toggleModel"
                :color="colorBlueGrey.slice(3)"
                toggle-color="orange-14"
                toggle-text-color="black"
                padding="3px 12px"
                :options="[
                  { label: 'Farms', value: 'farms' },
                  { label: 'Ponds', value: 'ponds' }
                ]"
              />
            </div>

            <div>
              <q-card>
                <div class="bg-grey" style="height: 22px"></div>
                <q-scroll-area :style="`height: ${cardHeight - 22}px;`">
                  <FarmTags
                    v-if="toggleModel === 'farms'"
                    :tagsFarm="tagsFarm"
                    :farmList="farmList"
                    :showOptions="showOptions"
                    @farmDeleteStart="farmDeleteStart"
                    @farmAddStart="farmAddStart"
                  />

                  <PondTags
                    v-if="toggleModel === 'ponds'"
                    :tagsPond="tagsPond"
                    :farmList="farmList"
                    :showOptions="showOptions"
                    @farmDeleteStart="farmDeleteStart"
                    @farmAddStart="farmAddStart"
                  />
                </q-scroll-area>
              </q-card>
            </div>
          </q-card>
        </div>

        <!--Desktop Version-->
        <div v-if="$q.screen.gt.md" class="col-4 q-pr-sm">
          <!--Tags Card-->
          <q-card flat bordered class="q-pa-sm">
            <div class="col-12 row items-center q-mb-sm q-mt-sm">
              <q-toggle
                v-if="isSuperAdmin"
                dense
                v-model="showOptions"
                checked-icon="edit"
                unchecked-icon="edit"
                color="orange-14"
                size="xl"
              />
              <div v-if="!isSuperAdmin" style="width: 48px"></div>
              <q-space />
              <span class="text-h6 text-orange-10"> Tags </span>
              <q-space />
              <div style="width: 48px"></div>
            </div>

            <q-scroll-area :style="`height: ${cardHeight}px;`">
              <MainTags
                :tagsMaster="tagsMaster"
                :farmList="farmList"
                :showOptions="showOptions"
                @masterTagDelete="masterTagDelete"
                @masterTagEdit="masterTagEdit"
                @farmAddStart="farmAddStart"
              />
            </q-scroll-area>
          </q-card>
        </div>

        <!--Farms Card-->
        <div v-if="$q.screen.gt.md" class="col-4 q-pr-sm">
          <q-card flat bordered class="col-4 q-pa-sm">
            <div class="col-12 text-center text-h6 text-purple q-mb-sm q-mt-sm">
              Farms
            </div>

            <div>
              <q-card>
                <div style="height: 22px" class="bg-grey"></div>
                <q-scroll-area :style="`height: ${cardHeight - 22}px;`">
                  <FarmTags
                    :tagsFarm="tagsFarm"
                    :farmList="farmList"
                    :showOptions="showOptions"
                    @farmDeleteStart="farmDeleteStart"
                    @farmAddStart="farmAddStart"
                  />
                </q-scroll-area>
              </q-card>
            </div>
          </q-card>
        </div>

        <!--Pond Card-->
        <div v-if="$q.screen.gt.md" class="col-4">
          <q-card flat bordered class="col-4 q-pa-sm">
            <div
              class="col-12 text-center text-h6 text-primary q-mb-sm q-mt-sm"
            >
              Ponds
            </div>

            <div>
              <q-card>
                <div style="height: 22px" class="bg-grey"></div>
                <q-scroll-area :style="`height: ${cardHeight - 22}px;`">
                  <PondTags
                    :tagsPond="tagsPond"
                    :farmList="farmList"
                    :showOptions="showOptions"
                    @farmDeleteStart="farmDeleteStart"
                    @farmAddStart="farmAddStart"
                  />
                </q-scroll-area>
              </q-card>
            </div>
          </q-card>
        </div>
      </div>
    </div>

    <!--Show Options Dialog-->
    <q-dialog position="top" v-model="addEditDialog" class="q-mt-xl">
      <q-card class="q-pa-md" style="width: 300px">
        <div align="right" class="q-mb-md">
          <q-btn
            size="sm"
            icon="clear"
            color="grey-7"
            text-color="black"
            @click="addEditDialog = false"
          />
        </div>
        <q-separator color="primary" />
        <div class="column q-gutter-y-sm q-mb-md q-mt-sm">
          <q-toggle
            dense
            v-model="tagFilter.administrative"
            label="Administrative"
            checked-icon="admin_panel_settings"
            unchecked-icon="admin_panel_settings"
            color="red-14"
            size="xl"
            @input="setTagLists"
          />
          <q-toggle
            dense
            v-model="tagFilter.technology"
            label="Technology"
            checked-icon="storage"
            unchecked-icon="storage"
            color="purple"
            size="xl"
            @input="setTagLists"
          />
          <q-toggle
            dense
            v-model="tagFilter.locations"
            label="Locations"
            checked-icon="place"
            unchecked-icon="place"
            color="green-14"
            size="xl"
            @input="setTagLists"
          />
          <q-toggle
            dense
            v-model="tagFilter.miscellaneous"
            label="Miscellaneous"
            checked-icon="dashboard"
            unchecked-icon="dashboard"
            color="orange-14"
            size="xl"
            @input="setTagLists"
          />
          <q-toggle
            dense
            v-model="tagFilter.ponds"
            label="Pond"
            checked-icon="water"
            unchecked-icon="water"
            color="primary"
            size="xl"
            @input="setTagLists"
          />
          <q-toggle
            dense
            v-model="tagFilter.personal"
            label="Personal"
            checked-icon="person"
            unchecked-icon="person"
            color="black"
            size="xl"
            @input="setTagLists"
          />
        </div>
        <q-separator color="primary" />
        <div v-if="isSuperAdmin" class="q-mt-lg">
          <q-btn
            size="sm"
            label="Create Tag"
            color="orange-14"
            text-color="black"
            class="full-width"
            @click="masterTagCreate"
          />
        </div>
      </q-card>
    </q-dialog>

    <!--Master Tags Update Dialog-->
    <q-dialog position="top" v-model="masterTagDialog">
      <q-card class="q-pa-md" style="width: 300px">
        <div
          v-if="createMode"
          class="row justify-center items-center text-h6 text-primary"
        >
          <q-icon name="add" size="sm" />
          Create New Tag
        </div>
        <div
          v-if="!createMode"
          class="row justify-center items-center text-h6 text-orange-14"
        >
          <q-icon name="edit" size="sm" class="q-mr-sm" />
          Edit Tag
        </div>

        <q-separator />
        <div class="q-pt-md">
          <q-input
            outlined
            v-model="masterTagName"
            label="Tag Name"
            @input="masterTagNameInput"
          />
          <div v-if="masterTagEmptyErr" class="text-caption text-red-14">
            Tag Name cannot be blank
          </div>
          <div v-if="masterTagDuplicateErr" class="text-caption text-red-14">
            Tag Already Exists
          </div>
        </div>
        <div class="row q-py-md">
          <div class="col-12 text-subtitle1 text-center text-primary q-mb-md">
            Access
            <q-separator />
          </div>
          <q-radio
            dense
            v-model="masterTagRestriction"
            val="adminOnly_0"
            label="Admin Only"
            class="full-width q-mb-md"
          />
          <q-radio
            dense
            v-model="masterTagRestriction"
            val="public_1"
            label="Public"
            color="red-14"
          />
        </div>
        <div class="row q-py-md">
          <div class="col-12 text-subtitle1 text-center text-primary q-mb-md">
            Tag Category
            <q-separator />
          </div>
          <q-radio
            dense
            v-model="masterTagLevel"
            val="level_1"
            label="Locations"
            class="full-width q-mb-md"
            color="green-14"
          />
          <q-radio
            dense
            v-model="masterTagLevel"
            val="level_2"
            label="Technology"
            class="full-width q-mb-md"
            color="purple"
          />
          <q-radio
            dense
            v-model="masterTagLevel"
            val="level_3"
            label="Administrative"
            class="full-width q-mb-md"
            color="red-14"
          />
          <q-radio
            dense
            v-model="masterTagLevel"
            val="level_4"
            label="Miscellaneous"
            class="full-width q-mb-md"
            color="orange-14"
          />
          <q-radio
            dense
            v-model="masterTagLevel"
            val="level_10"
            label="Pond Tag"
            class="full-width q-mb-md"
            color="primary"
          />
          <q-radio
            dense
            v-model="masterTagLevel"
            val="level_0"
            label="Personal"
            class="full-width q-mb-xs"
            color="black"
          />
        </div>
        <div align="right">
          <q-separator class="q-mb-md" />
          <q-btn
            flat
            size="sm"
            label="Cancel"
            color="primary"
            class="q-mr-sm"
            @click="masterTagDialog = false"
          />
          <q-btn
            v-if="createMode"
            size="sm"
            label="Submit"
            color="primary"
            @click="masterTagSubmit('createMasterTag')"
          />
          <q-btn
            v-if="!createMode"
            size="sm"
            label="Submit"
            color="primary"
            @click="masterTagSubmit('editMasterTag')"
          />
        </div>
      </q-card>
    </q-dialog>

    <!--Farm Add Dialog-->
    <q-dialog position="top" v-model="farmAddDialog" class="q-mt-xl">
      <q-card class="q-pa-md" style="width: 300px">
        <div
          v-if="farmAddSource === 'tags'"
          class="row justify-center items-center text-h6"
          :class="farmAddTag.tag_level !== 10 ? 'text-purple' : 'text-primary'"
        >
          <q-icon name="add" size="sm" class="q-mr-sm" />
          <div v-if="farmAddTag.tag_level !== 10">Add Farm To Tag</div>
          <div v-if="farmAddTag.tag_level === 10">Add Pond To Tag</div>
        </div>
        <div
          v-if="farmAddSource === 'farms'"
          class="row justify-center items-center text-h6 text-orange-14"
        >
          <q-icon name="add" size="sm" class="q-mr-sm" />
          Add Tag to Farm
        </div>
        <div
          v-if="farmAddSource === 'ponds'"
          class="row justify-center items-center text-h6 text-primary"
        >
          <q-icon name="add" size="sm" class="q-mr-sm" />
          Add Tag to Pond
        </div>

        <q-separator />
        <div
          class="row justify-center items-center text-h6 text-orange-14 q-py-md"
        >
          <q-chip v-if="farmAddSource === 'tags'" color="orange-14">
            <q-icon name="sell" size="sm" class="q-mr-sm" />
            <div class="ellipsis">
              {{ farmAddTag.tag_name }}
            </div>
          </q-chip>
          <q-chip
            v-if="farmAddSource === 'farms' || farmAddSource === 'ponds'"
            color="purple"
          >
            <q-icon name="home_work" size="sm" class="q-mr-sm" />
            <div class="ellipsis">
              {{ farmAddTag.farmName }}
            </div>
          </q-chip>
        </div>
        <q-separator />
        <div v-if="farmAddSource === 'tags' && farmAddTag.tag_level !== 10">
          <div v-if="farmAddErr" class="text-caption text-red-14">
            Please Select Farm
          </div>
          <q-scroll-area
            class="q-mb-md q-mt-sm"
            :style="`height: ${cardHeight - 200}px;`"
          >
            <q-option-group
              v-model="farmAddModel"
              :options="farmAddFarmList"
              color="purple"
              type="checkbox"
            />
          </q-scroll-area>
        </div>
        <div
          v-if="farmAddSource === 'tags' && farmAddTag.tag_level === 10"
          class="q-pt-md"
        >
          <q-select
            outlined
            v-model="farmAddModelP"
            label="Select Farm - Type to Filter"
            use-input
            hide-selected
            fill-input
            input-debounce="0"
            :options="farmAddFarmList"
            @filter="farmAddFilterFn"
            color="purple"
          />
          <div v-if="farmAddErr" class="text-caption text-red-14">
            Please Select Farm
          </div>
          <q-select
            outlined
            v-model="farmAddPondModel"
            :options="pondOptions"
            label="Select Pond"
            color="primary"
            class="q-mt-md"
          />
          <div v-if="farmAddErrPond" class="text-caption text-red-14">
            Please Select Pond
          </div>
        </div>

        <div v-if="farmAddSource === 'farms'" class="q-pt-md">
          <q-select
            outlined
            v-model="farmAddModelT"
            :options="farmAddTagList"
            color="orange-14"
            label="Select Tag"
          />
          <div v-if="farmAddErr" class="text-caption text-red-14">
            Please Select Tag
          </div>
        </div>

        <div v-if="farmAddSource === 'ponds'">
          <q-select
            outlined
            v-model="farmAddModelT"
            :options="farmAddTagList"
            color="orange-14"
            class="q-mt-md"
            label="Select Tag"
          />
          <div v-if="farmAddErr" class="text-caption text-red-14">
            Please Select Tag
          </div>
          <q-select
            outlined
            v-model="farmAddPondModel"
            :options="pondOptions"
            label="Select Pond"
            color="primary"
            class="q-mt-md"
          />
          <div v-if="farmAddErrPond" class="text-caption text-red-14">
            Please Select Pond
          </div>
        </div>

        <div align="right" class="q-mt-md">
          <q-separator class="q-mb-md" />
          <q-btn
            flat
            size="sm"
            label="Cancel"
            color="purple"
            class="q-mr-sm"
            @click="farmAddDialog = false"
          />
          <q-btn
            size="sm"
            label="Add Tag"
            color="purple"
            @click="farmAddSubmit"
          />
        </div>
      </q-card>
    </q-dialog>

    <!--Farm/Pond Delete Dialog-->
    <q-dialog position="top" v-model="farmDeleteDialog" class="q-mt-xl">
      <q-card class="q-pa-md" style="width: 300px">
        <div class="row justify-center items-center text-h6 text-red-14">
          <q-icon name="delete" size="sm" class="q-mr-sm" />
          <span v-if="farmDeleteTag.source === 'farms'">
            Delete Tag From Farm
          </span>
          <span v-if="farmDeleteTag.source === 'ponds'"> Delete Pond Tag </span>
        </div>

        <q-separator />
        <div
          class="row justify-center items-center text-h6 text-orange-14 q-py-md"
        >
          <q-chip color="purple">
            <q-icon name="home_work" size="sm" class="q-mr-sm" />
            <div class="ellipsis">
              {{ farmDeleteTag.farmName }}
            </div>
          </q-chip>
        </div>
        <q-separator class="q-mb-md" />

        <div>
          <q-select
            outlined
            v-model="farmDeleteModel"
            :options="farmDeleteTagList"
            color="purple"
            label="Select Tag"
          />
          <div v-if="farmDeleteErrTag" class="text-red-14 text-caption">
            Please Select Tag
          </div>
        </div>
        <div v-if="farmDeleteTag.source === 'ponds'">
          <q-select
            outlined
            v-model="farmDeleteModelPond"
            :options="pondOptionsDelete"
            label="Select Pond"
            class="q-mt-md"
            color="primary"
          />
          <div v-if="farmDeleteErrPond" class="text-red-14 text-caption">
            Please Select Pond
          </div>
        </div>

        <div align="right" class="q-mt-md">
          <q-separator class="q-mb-md" />
          <q-btn
            flat
            size="sm"
            label="Cancel"
            color="purple"
            class="q-mr-sm"
            @click="farmDeleteDialog = false"
          />
          <q-btn
            size="sm"
            label="Delete Tag"
            color="red-14"
            @click="farmDeleteSubmit"
          />
        </div>
      </q-card>
    </q-dialog>

    <q-page-sticky position="top-right" :offset="[18, 25]">
      <q-btn
        icon="menu_open"
        color="orange-14"
        text-color="black"
        size="sm"
        padding="6px 12px"
        @click="addEditDialog = true"
      />
    </q-page-sticky>
  </q-page>
</template>

<script>
import MainTags from '@/components/tags/MainTags.vue';
import FarmTags from '@/components/tags/FarmTags.vue';
import PondTags from '@/components/tags/PondTags.vue';

import store from '@/store';
import { decoder } from '@/lib/helpers';

export default {
  name: 'TagsPage',
  components: {
    MainTags,
    FarmTags,
    PondTags
  },
  data() {
    return {
      addEditDialog: false,
      cardHeight: 500,
      createMode: true,
      farmAddDialog: false,
      farmAddErr: false,
      farmAddErrPond: false,
      farmAddFarmList: [],
      farmAddFarmListAll: [],
      farmAddModel: [],
      farmAddModelT: null,
      farmAddModelP: null,
      farmAddPondList: [],
      farmAddPondModel: null,
      farmAddTagList: [],
      farmAddTagListAll: [],
      farmAddSource: '',
      farmAddTag: {},
      farmDeleteDialog: false,
      farmDeleteErrTag: false,
      farmDeleteErrPond: false,
      farmDeleteModel: null,
      farmDeleteModelPond: null,
      farmDeleteTagList: [],
      farmDeleteTag: {},
      masterTagDialog: false,
      masterTagDuplicateErr: false,
      masterTagEmptyErr: false,
      masterTagLevel: 'level_1',
      masterTagName: '',
      masterTagRestriction: 'adminOnly_0',
      showOptions: false,
      tag: null,
      tagFilter: {
        locations: true,
        technology: true,
        administrative: true,
        miscellaneous: true,
        ponds: true,
        personal: true
      },
      tags: [],
      tagsFarm: [],
      tagsMaster: [],
      tagsPond: [],
      toggleModel: this.$q.screen.xs ? 'tags' : 'farms'
    };
  },
  mounted() {
    this.setTagLists();
    this.setCardHeight();

    window.addEventListener('resize', this.setCardHeight);

    // Fixes browser bar weirdness on mobile
    setTimeout(() => {
      this.setCardHeight();
    }, 100);
  },
  destroyed() {
    window.removeEventListener('resize', this.setCardHeight);
  },
  methods: {
    displayFarmName(tags, tag, index) {
      // This prevents the farm name from showing multiple time if
      // tag is a pond tag & farm has multiple ponds attached to tag

      if (index === 0) {
        return true;
      }

      if (tags[index - 1].farm_id === tag.farm_id) {
        return false;
      }

      return true;
    },
    levelColor(tag) {
      return tag.tag_level === 0
        ? 'black'
        : tag.tag_level === 1
        ? 'green-14'
        : tag.tag_level === 2
        ? 'purple'
        : tag.tag_level === 3
        ? 'red-14'
        : tag.tag_level === 4
        ? 'orange-14'
        : tag.tag_level === 10
        ? 'primary'
        : 'grey';
    },
    masterTagCreate() {
      this.addEditDialog = false;
      this.createMode = true;
      this.masterTagEmptyErr = false;
      this.masterTagDuplicateErr = false;
      this.masterTagName = '';
      this.masterTagRestriction = 'adminOnly_0';
      this.masterTagLevel = 'level_1';
      this.masterTagDialog = true;
    },
    masterTagDelete(i, j) {
      const masterTag = this.tagsMaster[i].tags[j];

      this.$q
        .dialog({
          title: 'Confirm',
          ok: {
            label: 'Delete Tag',
            color: 'red-14',
            unelvated: true,
            icon: 'delete'
          },
          focus: 'none',
          message: `Are you sure you want to delete '${masterTag.tag_name}'
          and all the farm/pond tags associated with it?`,
          cancel: true,
          persistent: true
        })
        .onOk(() => {
          const tag = {
            action: 'deleteMasterTag',
            id: masterTag.id
          };

          this.tagDispatch(tag, 'updateMasterTag');
        });
    },
    masterTagEdit(i, j) {
      const tag = this.tagsMaster[i].tags[j];

      this.tag = tag;
      this.createMode = false;
      this.masterTagEmptyErr = false;
      this.masterTagDuplicateErr = false;
      this.masterTagName = tag.tag_name;
      this.masterTagLevel = 'level_' + tag.tag_level;
      this.masterTagRestriction =
        tag.admin_restricted === 0 ? 'adminOnly_0' : 'public_1';
      this.masterTagDialog = true;
    },
    masterTagNameInput() {
      if (this.masterTagName.trim() === '') {
        this.masterTagEmptyErr = true;
      } else {
        this.masterTagEmptyErr = false;
      }
    },
    masterTagSubmit(action) {
      if (this.masterTagName.trim() === '') {
        this.masterTagEmptyErr = true;
        return;
      }

      this.tagsMaster.forEach((x) => {
        if (this.masterTagName.trim() === x.tag_name) {
          this.masterTagDuplicateErr = true;
          return;
        }
      });

      const tagLevel = +this.masterTagLevel.split('_')[1];
      const adminRestricted = +this.masterTagRestriction.split('_')[1];
      const createdDate = !this.createMode
        ? this.tag.created_date
        : Math.floor(+new Date() / 1000);
      const id = !this.createMode ? this.tag.id : null;

      const tag = {
        action,
        createdBy: this.currUserId,
        tagName: this.masterTagName.trim(),
        tagLevel,
        adminRestricted,
        createdDate,
        id
      };

      this.tagDispatch(tag, 'updateMasterTag');

      this.masterTagDialog = false;
    },
    farmAddFilterFn(val, update) {
      update(() => {
        const lowerVal = val.toLowerCase();
        this.farmAddFarmList = [];
        this.farmAddFarmList = this.farmAddFarmListAll
          .filter((farm) => farm.label.toLowerCase().startsWith(lowerVal))
          .map((farm) => ({ label: farm.label, value: farm.value }));
      });
    },
    farmAddStart(tag, source) {
      this.farmAddModel = [];
      this.farmAddModelT = null;
      this.farmAddModelP = null;
      this.farmAddPondModel = null;
      this.farmAddErr = false;
      this.farmAddErrPond = false;
      this.farmAddSource = source;
      this.farmAddTag = tag;
      this.farmAddFarmList = [];
      this.farmAddPondList = [];
      this.farmAddTagList = [];

      if (source === 'tags') {
        const farmIdsExisting = [];
        tag.farmTags.forEach((x) => farmIdsExisting.push(x.farm_id));

        this.farmList.forEach((farm) => {
          // Prevent Duplicates if not a pond tag
          if (!farmIdsExisting.includes(farm.id) || tag.tag_level === 10) {
            this.farmAddFarmList.push({
              label: decoder(farm.farm_name),
              value: farm.id,
              ponds: farm.gps.ponds
            });
          }
        });
        this.farmAddFarmListAll = this.farmAddFarmList.slice();
      }

      if (source === 'farms') {
        const tagIdsExisting = [];
        tag.tags.forEach((x) => tagIdsExisting.push(x.id));

        this.tagsMaster.forEach((tag) => {
          // Prevent Duplicates
          tag.tags.forEach((y) => {
            if (!tagIdsExisting.includes(y.id) && y.tag_level !== 10) {
              this.farmAddTagList.push({
                label: y.tag_name,
                value: y.id
              });
            }
          });
        });
      }

      if (source === 'ponds') {
        const pondIdsExisting = [];
        tag.tags.forEach((x) => {
          x.ponds.forEach((y) => pondIdsExisting.push(y.pond_id));
        });

        this.tagsMaster.forEach((tag) => {
          // Prevent Duplicates
          tag.tags.forEach((y) => {
            if (y.tag_level === 10) {
              this.farmAddTagList.push({
                label: y.tag_name,
                value: y.id
              });
            }
          });
        });
      }

      this.farmAddDialog = true;
    },
    farmAddSubmit() {
      let tags = [];
      if (this.farmAddSource === 'tags' && this.farmAddTag.tag_level !== 10) {
        if (this.farmAddModel.length < 1) {
          this.farmAddErr = true;
          return;
        }
        this.farmAddModel.forEach((x) => {
          tags.push({
            tagId: this.farmAddTag.id,
            farmId: x,
            pondId: null
          });
        });
      } else if (
        this.farmAddSource === 'tags' &&
        this.farmAddTag.tag_level === 10
      ) {
        if (!this.farmAddModelP) {
          this.farmAddErr = true;
          return;
        } else {
          this.farmAddErr = false;
        }
        if (!this.farmAddPondModel) {
          this.farmAddErrPond = true;
          return;
        }
        tags.push({
          tagId: this.farmAddTag.id,
          farmId: this.farmAddModelP.value,
          pondId: this.farmAddPondModel.value
        });
      } else if (this.farmAddSource === 'farms') {
        if (!this.farmAddModelT) {
          this.farmAddErr = true;
          return;
        }
        tags.push({
          tagId: this.farmAddModelT.value,
          farmId: this.farmAddTag.farmId,
          pondId: null
        });
      } else if (this.farmAddSource === 'ponds') {
        if (!this.farmAddModelT) {
          this.farmAddErr = true;
          return;
        } else {
          this.farmAddErr = false;
        }
        if (!this.farmAddPondModel) {
          this.farmAddErrPond = true;
          return;
        }
        tags.push({
          tagId: this.farmAddModelT.value,
          farmId: this.farmAddTag.farm_id,
          pondId: this.farmAddPondModel.value
        });
      }

      const tag = {
        tags,
        action: 'addTag'
      };

      this.farmAddDialog = false;
      this.tagDispatch(tag, 'updateTag');
    },
    farmDeleteStart(tag, source) {
      this.farmDeleteModel = null;
      this.farmDeleteModelPond = null;
      this.farmDeleteErrTag = false;
      this.farmDeleteErrPond = false;

      if (source === 'ponds') {
        tag.farmName = this.farmName(tag.farm_id);
      }
      tag.source = source;
      this.farmDeleteTag = tag;

      this.farmDeleteTagList = [];
      tag.tags.forEach((tag) => {
        this.farmDeleteTagList.push({
          label: tag.tag_name,
          value: source === 'farms' ? tag.tag_ref_id : tag.tagId
        });
      });

      this.farmDeleteDialog = true;
    },
    farmDeleteSubmit() {
      if (!this.farmDeleteModel) {
        this.farmDeleteErrTag = true;
        return;
      } else {
        this.farmDeleteErrTag = false;
      }

      if (!this.farmDeleteModelPond && this.farmDeleteTag.source === 'ponds') {
        this.farmDeleteErrPond = true;
        return;
      }
      this.farmDeleteDialog = false;

      const tag = {
        action: 'deleteTag',
        tagRefId:
          this.farmDeleteTag.source === 'farms'
            ? this.farmDeleteModel.value
            : this.farmDeleteModelPond.value
      };

      this.tagDispatch(tag, 'updateTag');
    },
    farmName(farmId) {
      const farm = this.farmList.find((x) => x.id === parseInt(farmId));
      return farm ? decoder(farm.farm_name) : '';
    },
    pondName(tag) {
      const farm = this.farmList.find((x) => x.id === parseInt(tag.farm_id));
      const pond = farm.gps.ponds.find(
        (pond) => tag.pond_id === parseInt(pond.id)
      );

      return pond?.name ?? '---';
    },
    setCardHeight() {
      const pageHeight = this.$refs?.tagPage?.$el?.clientHeight ?? null;

      if (!pageHeight) {
        return;
      }

      this.cardHeight = pageHeight - 82;
    },
    setTagLists() {
      const oldTagsMaster = JSON.parse(JSON.stringify(this.tagsMaster));
      const oldTagsFarm = JSON.parse(JSON.stringify(this.tagsFarm));
      const oldTagsPond = JSON.parse(JSON.stringify(this.tagsPond));

      const filterHelper = (tagLevel) => {
        let ret;
        switch (tagLevel) {
          case 0:
            ret = this.tagFilter.personal;
            break;
          case 1:
            ret = this.tagFilter.locations;
            break;
          case 2:
            ret = this.tagFilter.technology;
            break;
          case 3:
            ret = this.tagFilter.administrative;
            break;
          case 4:
            ret = this.tagFilter.miscellaneous;
            break;
          case 10:
            ret = this.tagFilter.ponds;
            break;
          default:
            ret = false;
        }
        return ret;
      };

      const tagsMaster = JSON.parse(
        JSON.stringify(this.$store.state.tagsMaster)
      )
        .filter((tag) => {
          if (tag.tag_level === 0 && tag.created_by !== this.currUserId) {
            return false;
          }

          if (!this.isSuperAdmin && tag.admin_restricted === 0) {
            return false;
          }

          return filterHelper(tag.tag_level);
        })
        .sort((a, b) => a.tag_name.localeCompare(b.tag_name));

      const tags = JSON.parse(JSON.stringify(this.$store.state.tags)).filter(
        (tag) => {
          if (tag.tag_level === 0 && tag.created_by !== this.currUserId) {
            return false;
          }

          if (!this.isSuperAdmin && tag.admin_restricted === 0) {
            return false;
          }

          return filterHelper(tag.tag_level);
        }
      );

      const farmList = this.farmList;

      tagsMaster.forEach((tagM) => {
        // Find farms for this tag and sort by alpha
        tagM.farmTags = tags
          .filter((tag) => {
            return tag.id === tagM.id && tag.pond_id === null;
          })
          .sort((a, b) => {
            return this.farmName(a.farm_id).localeCompare(
              this.farmName(b.farm_id)
            );
          });

        const pondTags = tags
          .filter((tag) => {
            return tag.id === tagM.id && tag.pond_id !== null;
          })
          .sort((a, b) => {
            return (this.farmName(a.farm_id) + this.pondName(a)).localeCompare(
              this.farmName(b.farm_id) + this.pondName(b)
            );
          });

        tagM.pondTags = {
          numPonds: pondTags.length,
          tags: []
        };
        pondTags.forEach((x) => {
          const index = tagM.pondTags.tags.findIndex(
            (y) => y.farm_id === x.farm_id
          );

          if (index >= 0) {
            tagM.pondTags.tags[index].ponds.push({
              farm_id: x.farm_id,
              pond_id: x.pond_id
            });
          } else {
            tagM.pondTags.tags.push({
              tag_name: tagM.tag_name,
              expand: false,
              farm_id: x.farm_id,
              ponds: [
                {
                  farm_id: x.farm_id,
                  pond_id: x.pond_id
                }
              ]
            });
          }
        });

        // If expanded keep expanded
        const expand = oldTagsMaster.some((x) => {
          const match = x.tags.find((y) => y.id === tagM.id);
          return match ? match.expand : false;
        });
        tagM.expand = expand;
      });

      const tagsFarm = [];
      farmList.forEach((farm) => {
        const tagsArr = tags.filter(
          (tag) => tag.farm_id === farm.id && tag.tag_level !== 10
        );
        if (tagsArr.length) {
          // If expanded keep expanded
          const expand =
            oldTagsFarm.find((x) => x.farmId === farm.id)?.expand ?? false;

          const tagsObj = {
            tags: tagsArr,
            farmId: farm.id,
            farmName: decoder(farm.farm_name),
            expand
          };
          tagsFarm.push(tagsObj);
        }
      });

      // Create 3D array to display pond tags correctly
      // Not as complicated as it seems, just 1D arr to 3D arr
      const farms = [];
      farmList.forEach((farm) => {
        const tagsArr = tags.filter(
          (tag) => tag.farm_id === farm.id && tag.tag_level === 10
        );
        if (tagsArr.length) {
          // If expanded keep expanded
          const expand =
            oldTagsPond.find((x) => x.farm_id === farm.id)?.expand ?? false;

          tagsArr.forEach((x) => {
            const farmIndex = farms.findIndex((y) => y.farm_id === x.farm_id);

            if (farmIndex >= 0) {
              const tagIndex = farms[farmIndex].tags.findIndex(
                (y) => y.tag_name === x.tag_name
              );
              if (tagIndex >= 0) {
                farms[farmIndex].tags[tagIndex].ponds.push({
                  farm_id: x.farm_id,
                  pond_id: x.pond_id,
                  tag_ref_id: x.tag_ref_id
                });
              } else {
                farms[farmIndex].tags.push({
                  tag_name: x.tag_name,
                  tagId: x.id,
                  ponds: [
                    {
                      farm_id: x.farm_id,
                      pond_id: x.pond_id,
                      tag_ref_id: x.tag_ref_id
                    }
                  ]
                });
              }
            } else {
              farms.push({
                expand,
                numTags: tagsArr.length,
                farm_id: x.farm_id,
                farmName: this.farmName(x.farm_id),
                tags: [
                  {
                    tag_name: x.tag_name,
                    tagId: x.id,
                    ponds: [
                      {
                        farm_id: x.farm_id,
                        pond_id: x.pond_id,
                        tag_ref_id: x.tag_ref_id
                      }
                    ]
                  }
                ]
              });
            }
          });
        }
      });

      // Sort all levels
      farms.sort((a, b) => {
        return this.farmName(a.farm_id).localeCompare(this.farmName(b.farm_id));
      });

      farms.forEach((x) => {
        x.tags.sort((a, b) => {
          return a.tag_name.localeCompare(b.tag_name);
        });

        x.tags.forEach((y) => {
          y.ponds.sort((a, b) => {
            const aName = this.pondName(a);
            const bName = this.pondName(b);

            return aName.localeCompare(bName);
          });
        });
      });

      const sortHelper = (tagLevel) => {
        return tagLevel === 0
          ? 5
          : tagLevel === 10
          ? 4
          : tagLevel === 4
          ? 3
          : tagLevel === 1
          ? 2
          : tagLevel === 2
          ? 1
          : tagLevel === 3
          ? 0
          : 3;
      };

      // Need to sort master list into categories yet
      const tagsMasterCategories = [
        { category: 'Administrative', tags: [] },
        { category: 'Technology', tags: [] },
        { category: 'Locations', tags: [] },
        { category: 'Miscellaneous', tags: [] },
        { category: 'Ponds', tags: [] },
        { category: 'Personal', tags: [] }
      ];

      tagsMaster.forEach((tag) => {
        tagsMasterCategories[sortHelper(tag.tag_level)].tags.push(tag);
      });

      this.tagsMaster = tagsMasterCategories.slice();
      this.tags = tags.slice();
      this.tagsFarm = tagsFarm.slice();
      this.tagsPond = farms.slice();
    },
    tagDispatch(tag, storeAction) {
      store.dispatch(storeAction, { tag }).then((results) => {
        // COMMENT: following line necessary to disable green checkbox for chats.
        results.disableCheck = true;
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.tagDispatch({ tag });
          }
        });
      });
    }
  },
  computed: {
    colorBlueGrey() {
      return this.$q.dark.isActive ? 'bg-blue-grey-10' : 'bg-blue-grey-4';
    },
    colorGrey() {
      return this.$q.dark.isActive ? 'bg-grey-10' : 'bg-grey-5';
    },
    colorOrange() {
      return this.$q.dark.isActive ? 'orange-14' : 'orange-10';
    },
    currUserId() {
      return this.$store.state.user.user_id;
    },
    farmList() {
      return JSON.parse(JSON.stringify(this.$store.state.farmList));
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    pondOptions() {
      let farm;
      let pondTagIdsExisting = [];

      if (this.farmAddSource === 'tags') {
        farm = this.farmAddFarmListAll.find(
          (x) => x.value === this.farmAddModelP?.value
        );
        this.tags.forEach((x) => {
          if (
            x.id === this.farmAddTag.id &&
            (farm?.value ?? '') === x.farm_id
          ) {
            pondTagIdsExisting.push(x.pond_id);
          }
        });
      }

      if (this.farmAddSource === 'ponds') {
        farm = this.farmList.find((x) => x.id === this.farmAddTag.farm_id);
        farm.ponds = farm.gps.ponds;

        this.tags.forEach((x) => {
          if (
            x.id === (this.farmAddModelT?.value ?? '') &&
            (farm?.id ?? '') === x.farm_id
          ) {
            pondTagIdsExisting.push(x.pond_id);
          }
        });
      }

      const ponds = [];
      if (farm) {
        farm.ponds.forEach((x) => {
          // Prevent Duplicates
          if (!pondTagIdsExisting.includes(x.id)) {
            ponds.push({
              label: x.name,
              value: x.id
            });
          }
        });
      }
      return ponds;
    },
    pondOptionsDelete() {
      const tag = this.farmDeleteTag.tags.find(
        (x) => x.tagId === (this.farmDeleteModel?.value ?? '')
      );

      const ponds = [];
      if (tag) {
        tag.ponds.forEach((x) => {
          ponds.push({
            label: this.pondName(x),
            value: x.tag_ref_id
          });
        });
      }

      return ponds;
    }
  },
  watch: {
    '$store.state.tagsMaster'() {
      this.setTagLists();
    },
    '$store.state.tags'() {
      this.setTagLists();
    }
  }
};
</script>

<style scoped>
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}
</style>
